import React from 'react';
import { Link } from 'react-router-dom';
import * as URLs from '../URLS';

export default class LibrarySubMenu extends React.Component {
    render() {
        return (
            <div className="lightbeigebg py-0 py-lg-2" style={{ width: "100%", display: "flex", justifyContent: "center", flexWrap:"wrap" }}>
                <div className="pagenavitem px-lg-4 py-2">
                    <Link className="text-uppercase" to={URLs.library_home}>
                        All Contents</Link>
                </div>
                <div className="pagenavitem px-lg-4 py-2">
                    <Link className="text-uppercase" to={URLs.library_mycontents}>
                        My Contents</Link>
                </div>
                <div className="pagenavitem px-lg-4 py-2">
                    <Link className="text-uppercase" to={URLs.library_starredcontents}>
                        Starred Contents</Link>
                </div>
                <div className="pagenavitem px-lg-4 py-2">
                    <Link className="text-uppercase" to={URLs.library_createcontent}>
                        Upload Content</Link>
                </div>
            </div>
        )
    }
}