import React from 'react';
import { Translate } from 'react-translated';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { FaRedo } from 'react-icons/fa';

import UserService from './UserService';
import { ContentPage, ContentZone, MessageZone } from '../Common/CommonComponents';
import LanguageConfig from '../Common/LanguageConfig';

export default class UserAdmin extends React.Component {
    constructor() {
        super();
        this.state = {
            error: null,
            isLoaded: false,
            users: null,
        };
        this.updateUserLocally = this.updateUserLocally.bind(this);
        this.forceRefresh = this.foreceRefresh.bind(this);
    }

    foreceRefresh() {
        this.setState({
            lastcall: null,
        });
        UserService.invalidateCacheUsersList();
        this.refreshInfos();
    }

    refreshInfos() {

        const callkey = "users";

        if (callkey === this.state.lastcall)
            return;

        UserService.getUsersList().then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    users: result.records,
                    lastcall: callkey
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    componentDidMount() {
        this.refreshInfos()
    }

    componentDidUpdate() {
        this.refreshInfos()
    }

    updateUserLocally(id, role) {
        const newUsers = JSON.parse(JSON.stringify(this.state.users));

        newUsers.forEach(user => {
            if (user.id === id) {
                user.accountlevel = role;
            }
        });

        this.setState({
            users: newUsers
        })
    }

    render() {

        const { error, isLoaded, users } = this.state;

        const columns = [{
            Header: 'ID',
            accessor: 'id',
            show: false
        }, {
            Header: 'Login',
            accessor: 'login',
            filterMethod: (filter, row) =>
                row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
        }, {
            Header: 'Email',
            accessor: 'email',
            filterMethod: (filter, row) =>
                row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
        }, {
            Header: 'Account Level',
            accessor: 'accountlevel',
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                } else {
                    return row[filter.id] === filter.value;
                }
            },
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="admin">Admin</option>
                    <option value="confirmed">Confirmed</option>
                    <option value="new">New</option>
                </select>
            ),
            Cell: (row) =>
                <UserRoleForm currentrole={row.value} id={row.original.id} updateUserLocally={this.updateUserLocally} />
            ,
        }, {
            Header: 'Last Login',
            accessor: 'lastlogin',
            Cell: row => (
                <span>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(Date.parse(row.value))}</span>
            ),
            filterable: false,
        }, {
            Header: 'Login Count',
            accessor: 'logincount',
            filterable: false,
            sortMethod: (a, b) => {
                return Number(a) > Number(b) ? 1 : -1;
            }
        },
        ]

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else if (!isLoaded) {
            return <MessageZone message={"Loading..."} />
        } else {
            return (
                <ContentPage pageTitle={LanguageConfig.getText("useradmin.title")}>
                    <ContentZone>
                        <div style={{ display: "flex" }}>
                            <span className="millenaire-header">
                                <Translate text="useradmin.title" />
                            </span>
                            <button style={{ marginRight: 0, marginLeft: "auto" }} className="btn btn-primary mb-2" onClick={() => { this.forceRefresh() }}>
                                <FaRedo className="buttonicon" />
                                <Translate text="data.refresh" />
                            </button>
                        </div>
                        <ReactTable className="-striped -highlight"
                            data={users}
                            columns={columns}
                            filterable={true}
                        />
                    </ContentZone>
                </ContentPage>
            )
        }
    }
}

class UserRoleForm extends React.Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(newValue) {
        UserService.updateUserRole(this.props.id, newValue).then(
            this.props.updateUserLocally(this.props.id, newValue)
        );
    }

    render() {
        return (
            <select
                onChange={event => this.onChange(event.target.value)}
                style={{ width: "100%" }}
                value={this.props.currentrole}
            >
                <option value="admin">Admin</option>
                <option value="confirmed">Confirmed</option>
                <option value="new">New</option>
            </select>
        )
    }
}