import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import Pagination from "react-js-pagination";
import ReactTooltip from 'react-tooltip';
import { FaEdit, FaSave } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import "react-toggle/style.css";
import ReactToggle from 'react-toggle';

import { MessageZone, ContentPage } from '../Common/CommonComponents';
import { LanguageContext } from '../Common/LanguageContext';
import TranslationService from './TranslationService';
import AuthService from '../Users/AuthService';
import * as Icons from './TranslationIcons';
import TranslationFileInfo from './TranslationFileInfo';
import * as URLs from '../URLS';

import './translation.css';

export default class TranslationCompare extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            referenceFile: null,
            referenceFileLastcall: null,
            translationFile: null,
            translationFileLastcall: null,
            translationData: null,
            activePage: 1,
            elementsModified: 0,
            hideTranslatedElements: false,
            useFullTextEditor: false
        };
        this.pageSize = 20;
        this.updateText = this.updateText.bind(this);
        this.refreshReferenceFile = this.refreshReferenceFile.bind(this);
        this.refreshTranslationFile = this.refreshTranslationFile.bind(this);
        this.buildTranslationData = this.buildTranslationData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.forceTranslationRefresh = this.forceTranslationRefresh.bind(this);
        this.toggleTranslatedElements = this.toggleTranslatedElements.bind(this);
        this.toggleFullTextEditor = this.toggleFullTextEditor.bind(this);
        this.updateExplanations = this.updateExplanations.bind(this);
        this.updateTranslationText = this.updateTranslationText.bind(this);
    }

    componentDidMount() {
        this.refreshReferenceFile();
        this.refreshTranslationFile();
    }

    componentDidUpdate() {
        this.refreshReferenceFile();
        this.refreshTranslationFile();
    }

    saveChanges() {
        const filekey = this.props.match.params.filekey;
        const { translationFile } = this.state;
        const translationLanguage = this.props.match.params.translationLanguage;
        const translationData = this.state.translationData;
        const content = {};
        content.elements = {};
        content.comments = [];


        content.explanations = translationData.translationExplanations;

        for (var key in translationData.elements) {
            let presentInTranslations = false;
            translationData.elements[key].forEach((line) => {
                if (line.translationText !== undefined) {
                    presentInTranslations = true;
                }
            })

            if (presentInTranslations) {
                const translatedElement = [];
                translationData.elements[key].forEach((line) => {
                    if (line.translationText !== undefined) {
                        translatedElement.push(line.translationText);
                    }
                })
                content.elements[key] = translatedElement;
            }
        }

        TranslationService.postFileSplitContent(translationLanguage, filekey, translationFile.filename, content).then(() => {
            this.forceTranslationRefresh();
        });
    }

    forceTranslationRefresh() {
        this.setState({
            translationFileLastcall: null
        });
        this.refreshTranslationFile();
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
    }

    updateText(elementKey, pos, text) {
        const translationData = this.state.translationData;
        let elementsModified = this.state.elementsModified;

        let element = translationData.elements[elementKey];
        element[pos].translationText = text;

        if (!element[pos].modified) {
            elementsModified++;
        }
        element[pos].modified = true;

        this.setState({
            translationData,
            elementsModified
        })
    }

    updateExplanations(text) {
        const translationData = this.state.translationData;
        let elementsModified = this.state.elementsModified;

        translationData.translationExplanations = text;

        if (!translationData.translationExplanationsModified) {
            elementsModified++;
            translationData.translationExplanationsModified = true;
        }

        this.setState({
            translationData,
            elementsModified
        })
    }

    updateTranslationText(text) {
        const translationFile = this.state.translationFile;

        translationFile.content = text;

        this.setState({
            translationFile
        })
    }

    refreshReferenceFile() {
        const filekey = this.props.match.params.filekey;
        const referenceLanguage = this.props.match.params.referenceLanguage;

        const callkey = "reference/" + filekey + "/" + referenceLanguage;

        if (callkey === this.state.referenceFileLastcall)
            return;

        TranslationService.getLatestFile(referenceLanguage, filekey)
            .then(
                (result) => {
                    this.setState({
                        referenceFile: result,
                        referenceFileLastcall: callkey,
                    });

                    this.buildTranslationData();
                },
                (error) => {
                    this.setState({
                        referenceFileLastcall: callkey,
                        error
                    });
                }
            )
    }

    refreshTranslationFile() {
        const filekey = this.props.match.params.filekey;
        const translationLanguage = this.props.match.params.translationLanguage;

        const callkey = "translation/" + filekey + "/" + translationLanguage;

        if (callkey === this.state.translationFileLastcall)
            return;

        TranslationService.getLatestFile(translationLanguage, filekey)
            .then(
                (result) => {
                    this.setState({
                        translationFile: result,
                        translationFileLastcall: callkey,
                    });

                    this.buildTranslationData();
                },
                (error) => {
                    this.setState({
                        translationFileLastcall: callkey,
                        error
                    });
                }
            )
    }

    /**
     * Builds a translationdata objet that contains a combined list of the reference and translation texts
     */
    buildTranslationData() {

        const { translationLanguage } = this.props;

        const { translationFile, referenceFile } = this.state;

        let translationData = {};
        translationData.elements = [];

        // load all content from the reference file
        if (referenceFile !== null && referenceFile.splitcontent !== undefined) {

            if (referenceFile.splitcontent.explanations !== undefined)
                translationData.referenceExplanations = referenceFile.splitcontent.explanations;

            for (let key in referenceFile.splitcontent.elements) {
                translationData.elements[key] = [];

                for (let i = 0; i < referenceFile.splitcontent.elements[key].length; i++) {
                    translationData.elements[key][i] = {};
                    translationData.elements[key][i].referenceText = referenceFile.splitcontent.elements[key][i];
                }
            }
        }

        if (translationFile !== null && translationFile.splitcontent !== undefined) {

            if (translationFile.splitcontent.explanations !== undefined)
                translationData.translationExplanations = translationFile.splitcontent.explanations;

            for (let key in translationFile.splitcontent.elements) {
                if (translationData.elements[key] === undefined) {
                    translationData.elements[key] = [];
                }

                for (let i = 0; i < translationFile.splitcontent.elements[key].length; i++) {

                    if (translationData.elements[key][i] === undefined)
                        translationData.elements[key][i] = {};

                    translationData.elements[key][i].translationText = translationFile.splitcontent.elements[key][i];
                }
            }
        } else if (referenceFile !== null) {

            let newTranslationFile = {};

            newTranslationFile.content = "";
            newTranslationFile.splitcontent = {};
            newTranslationFile.splitcontent.elements = [];
            newTranslationFile.splitcontent.explanations = "";
            newTranslationFile.language = translationLanguage;
            newTranslationFile.filekey = referenceFile.filekey;

            let filename;
            if (referenceFile.filekey === "lang") {
                //the lang file is annoying, we'd need the country code, we are guessing there
                filename = translationLanguage + "_" + translationLanguage + ".lang";
            } else {
                //use that of the reference language
                filename = referenceFile.filename;
            }
            newTranslationFile.filename = filename;

            this.setState({
                translationFile: newTranslationFile
            });

        }

        this.setState({
            translationData,
            elementsModified: 0
        })

        ReactTooltip.rebuild();
    }

    toggleTranslatedElements() {
        this.setState({
            hideTranslatedElements: !this.state.hideTranslatedElements
        });
    }

    toggleFullTextEditor() {
        this.setState({
            useFullTextEditor: !this.state.useFullTextEditor
        });
    }

    render() {

        const filekey = this.props.match.params.filekey;
        const translationLanguage = this.props.match.params.translationLanguage;
        const referenceLanguage = this.props.match.params.referenceLanguage;

        const { error, translationData, activePage, elementsModified, hideTranslatedElements,
            referenceFile, translationFile, useFullTextEditor } = this.state;

        const canEdit = AuthService.isUserConfirmed();

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else {
            return (
                <ContentPage>
                    <div className="row">
                        <div className="col-12 mb-4 d-block">
                            <div className="bg-faded p-5 rounded">
                                <ReactTooltip place="top" type="light" effect="solid" />
                                <div className="millenaire-header mb-4">
                                    {filekey}
                                </div>
                                {!canEdit &&
                                    <div style={{ width: "100", display: "flex", justifyContent: "center", marginBlockEnd: "2em" }}>
                                        <div className="bg-faded p-3 rounded" style={{ width: "80%", background: "rgba(249, 236, 195, 1)" }}>
                                            <p><b>You need a confirmed user account to be able to edit the translations.</b></p>
                                            <p>You can create an (unconfirmed) account <Link to={URLs.user_createaccount}>here</Link> if you do not have one, and request confirmation by <a href="mailto:kinniken@millenaire.org">sending me an email</a>.</p>
                                        </div>
                                    </div>
                                }

                                <Explanations translationData={translationData} updateExplanations={this.updateExplanations}
                                    referenceLanguage={referenceLanguage} referenceFile={referenceFile} translationLanguage={translationLanguage} translationFile={translationFile} />

                                {(elementsModified > 0) &&
                                    <div style={{ width: "100", display: "flex", justifyContent: "center" }}>
                                        <div className="bg-faded p-3 rounded mb-2" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "80%", background: "rgba(249, 236, 195, 1)" }}>
                                            <b>Unsaved changes: {elementsModified}</b>
                                            <button style={{ float: "right", marginLeft: "10px" }} className="btn btn-primary" onClick={() => { this.saveChanges() }}>
                                                <Icons.Save className="buttonicon" />
                                                Save
        </button>
                                        </div>
                                    </div>
                                }

                                <div className="mb-2" style={{ display: "flex", justifyItems: "left" }} >
                                    <ReactToggle
                                        checked={this.state.hideTranslatedElements}
                                        onChange={this.toggleTranslatedElements}
                                    />
                                    <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                                        Hide already-translated elements
                                    </span>
                                    <ReactToggle
                                        checked={this.state.useFullTextEditor}
                                        onChange={this.toggleFullTextEditor}
                                    />
                                    <span style={{ marginLeft: "5px" }}>
                                        Use full-text editor
                                    </span>
                                </div>

                                {!useFullTextEditor &&
                                    <SplitContent translationData={translationData} hideTranslatedElements={hideTranslatedElements}
                                        activePage={activePage} pageSize={this.pageSize} updateText={this.updateText}
                                        handlePageChange={this.handlePageChange} />
                                }
                                {useFullTextEditor &&
                                    <TextEditors referenceFile={referenceFile} translationFile={translationFile} translationLanguage={translationLanguage}
                                        updateTranslationText={this.updateTranslationText} forceTranslationRefresh={this.forceTranslationRefresh} />
                                }
                            </div>
                        </div>
                    </div>
                </ContentPage >
            );
        }
    }
}

class SplitContent extends React.Component {

    render() {

        const { translationData, hideTranslatedElements, pageSize, activePage, handlePageChange, updateText } = this.props;

        let itemnumber = 0;

        const rows = [];

        if (translationData !== null) {
            let pos = 0;
            for (var key in translationData.elements) {

                let showElement = !hideTranslatedElements;

                if (!showElement) {
                    translationData.elements[key].forEach((line) => {
                        if (line.referenceText === undefined ||
                            line.translationText === undefined ||
                            line.modified) {
                            showElement = true;
                        }
                    })
                }

                if (showElement) {
                    if (pos >= (activePage - 1) * pageSize && pos < (activePage * pageSize)) {
                        rows.push(
                            <TranslationElement key={key} elementKey={key} updateText={updateText}
                                translationElement={translationData.elements[key]} />
                        )
                    }
                    pos++;
                }
            }

            itemnumber = pos;
        }

        return (
            <div>
                <ListPagination itemnumber={itemnumber} activePage={activePage}
                    handlePageChange={handlePageChange} pageSize={pageSize} />
                {rows}
                <ListPagination itemnumber={itemnumber} activePage={activePage}
                    handlePageChange={handlePageChange} pageSize={pageSize} />
            </div>
        )
    }
}

class Explanations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            explanationsEditMode: false
        };
        this.toggleEdit = this.toggleEdit.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.editTextRef = React.createRef();
    }

    toggleEdit(editMode) {
        this.setState({
            explanationsEditMode: editMode
        });
    }

    handleOutsideClick(e) {
        const node = this.editTextRef.current;
        if (node !== null && node.contains(e.target))
            return;
        this.toggleEdit(false);
    }

    componentWillMount() {
        document.addEventListener("mousedown", this.handleOutsideClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleOutsideClick, false);
    }

    render() {

        const { translationData, referenceFile, referenceLanguage, translationFile, translationLanguage } = this.props;
        const canEdit = AuthService.isUserConfirmed();

        const { explanationsEditMode } = this.state;

        let referenceExplanations = null;
        let translationExplanations = null;

        if (translationData !== null) {

            if (translationData.referenceExplanations !== undefined &&
                translationData.referenceExplanations !== "") {
                const explanationsSplit = translationData.referenceExplanations.split('\n').map((item, i) => {
                    return <div key={i}>{item}</div>;
                });
                referenceExplanations = <div>{explanationsSplit}</div>
            }

            if (translationData.translationExplanations !== undefined &&
                translationData.translationExplanations !== "") {
                const explanationsSplit = translationData.translationExplanations.split('\n').map((item, i) => {
                    return <div key={i}>{item}</div>;
                });
                translationExplanations = <div>{explanationsSplit}</div>
            }
        }

        return (
            <div style={{ width: "100%", display: "flex", marginBottom: "1em" }}>
                <div style={{ width: "50%", marginRight: "10px" }}>
                    <TranslationFileInfo className="mb-2" style={{ width: "100%" }} language={referenceLanguage} file={referenceFile} />
                    <div className="bg-faded p-3 rounded mb-2" >
                        {referenceExplanations !== null &&
                            referenceExplanations
                        }
                        {referenceExplanations === null &&
                            <em>No specific explanations exist for this file.</em>
                        }
                    </div>
                </div>
                <div style={{ width: "50%", marginLeft: "10px" }}>
                    <TranslationFileInfo className="mb-2" style={{ width: "100%" }} language={translationLanguage} file={translationFile} />
                    <div className="bg-faded p-3 rounded mb-2" ref={this.editTextRef}>
                        {explanationsEditMode &&
                            <TextareaAutosize
                                className="form-control form-control-sm"
                                name="translationExplanations"
                                type="text"
                                rows={2}
                                onChange={event => this.props.updateExplanations(event.target.value)}
                                value={translationData.translationExplanations}
                            />
                        }
                        {!explanationsEditMode && translationData !== null &&
                            <div onClick={e => canEdit && this.toggleEdit(true)} style={{ width: "100%", height: "100%" }}>
                                {canEdit &&
                                    <div>
                                        <FaEdit className="activeicon" style={{ float: "right" }} data-tip="Edit Explanations" />
                                    </div>
                                }
                                {translationData.translationExplanationsModified &&
                                    <div>
                                        <FaSave data-tip="Unsaved Changes" className="activeicon mr-1" style={{ float: "right", color: "red" }} />
                                    </div>
                                }
                                {translationExplanations !== null &&
                                    translationExplanations
                                }
                                {translationExplanations === null &&
                                    <em>No specific explanations exist for this file.</em>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

}

class TranslationElement extends React.Component {
    render() {
        const { elementKey, translationElement, updateText } = this.props;

        const rows = [];

        for (let i = 0; i < translationElement.length; i++) {

            rows.push(
                <TranslationTextEdit key={elementKey + "_" + i} elementKey={elementKey} pos={i} updateText={updateText}
                    translationLine={translationElement[i]} />
            )

        }

        return (
            <div>
                <b style={{ wordWrap: "break-word" }}>{elementKey}</b>
                {rows}
            </div>
        );
    }
}

class TranslationTextEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editMode: false
        };
        this.toggleEdit = this.toggleEdit.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.editTextRef = React.createRef();
    }

    toggleEdit(editMode) {
        this.setState({
            editMode: editMode
        })
        ReactTooltip.rebuild();
    }

    handleOutsideClick(e) {
        const node = this.editTextRef.current;

        if (node.contains(e.target))
            return;
        this.toggleEdit(false);
    }

    componentWillMount() {
        document.addEventListener("mousedown", this.handleOutsideClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleOutsideClick, false);
    }

    render() {
        const { translationLine, elementKey, pos, updateText } = this.props;
        const editMode = this.state.editMode;

        const canEdit = AuthService.isUserConfirmed();

        return (
            <div style={{ width: "100%", display: "flex", marginBottom: "1em" }}>
                <div style={{ width: "50%", marginRight: "10px" }}>
                    {translationLine.referenceText !== undefined ? translationLine.referenceText : <em>Missing</em>}
                </div>

                <div style={{ width: "50%" }} ref={this.editTextRef}>
                    {editMode &&
                        <TextareaAutosize
                            className="form-control form-control-sm"
                            name="translationText"
                            type="text"
                            rows={2}
                            onChange={event => updateText(elementKey, pos, event.target.value)}
                            value={translationLine.translationText}
                        />
                    }
                    {!editMode &&
                        <div onClick={e => canEdit && this.toggleEdit(true)}>
                            {canEdit &&
                                <div>
                                    <FaEdit className="activeicon" style={{ float: "right" }} data-tip="Edit Text" />
                                </div>
                            }
                            {translationLine.modified &&
                                <div>
                                    <FaSave data-tip="Unsaved Changes" className="activeicon mr-1" style={{ float: "right", color: "red" }} />
                                </div>
                            }
                            {translationLine.translationText !== undefined ? translationLine.translationText : <em>Missing</em>}
                        </div>
                    }
                </div>
            </div>
        );
    }
}


class ListPagination extends React.Component {
    render() {
        return (
            <div align="center" style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                <Pagination
                    activePage={this.props.activePage}
                    itemsCountPerPage={this.props.pageSize}
                    totalItemsCount={this.props.itemnumber}
                    pageRangeDisplayed={20}
                    onChange={this.props.handlePageChange}
                />
            </div>
        )
    }
}

class TextEditors extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayReference: true,
            saveRequired: false
        };
        this.toggleDisplayReference = this.toggleDisplayReference.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.updateTranslationText = this.updateTranslationText.bind(this);
    }

    toggleDisplayReference() {
        this.setState({
            displayReference: !this.state.displayReference
        })
    }

    saveChanges() {

        const { translationFile, forceTranslationRefresh, translationLanguage } = this.props;

        TranslationService.postFileFullText(translationLanguage, translationFile.filekey, translationFile.filename, translationFile.content).then(() => {

            this.setState({
                saveRequired: false
            })
            forceTranslationRefresh();
        });
    }

    updateTranslationText(text) {
        this.setState({
            saveRequired: true
        });
        this.props.updateTranslationText(text);
    }

    render() {
        const { referenceFile, translationFile } = this.props;
        const { displayReference, saveRequired } = this.state;

        return (
            <div>
                <div style={{ height: "50px" }}>
                    {saveRequired &&
                        <button style={{ float: "right" }} className="btn btn-primary" onClick={() => { this.saveChanges() }}>
                            <Icons.Save className="buttonicon" />
                            Save
        </button>
                    }
                    <div className="mb-2" style={{ display: "flex", justifyItems: "left" }} >
                        <div><ReactToggle
                            checked={this.state.displayReference}
                            onChange={this.toggleDisplayReference}
                        /></div>
                        <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                            Show reference file
                                    </span>
                    </div>
                </div>


                <div style={{ display: "flex", justifyItems: "center" }} >
                    {displayReference &&
                        <TextareaAutosize
                            style={{ width: "50%" }}
                            className="form-control form-control-sm"
                            name="translationText"
                            type="text"
                            rows={2}
                            value={referenceFile.content}
                            disabled={true}
                        />
                    }
                    <TextareaAutosize
                        style={{ width: displayReference ? "50%" : "100%" }}
                        onChange={event => this.updateTranslationText(event.target.value)}
                        className="form-control form-control-sm"
                        name="translationText"
                        type="text"
                        rows={2}
                        value={translationFile.content}
                        disabled={!AuthService.isUserConfirmed()}
                    />
                </div>

            </div>
        )
    }
}