import decode from 'jwt-decode';
import { NotificationManager } from 'react-notifications';

import Translations from '../translation';
import LanguageConfig from '../Common/LanguageConfig';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

class AuthService {
    // Initializing important variables
    constructor() {
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    login(username, password) {
        // Get a token from api server using the fetch api
        return this.fetch(process.env.REACT_APP_API_ROOT + 'users/loginuser', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Accept: 'application/json',
              },
            body: JSON.stringify({
                username,
                password
            })
            //body: "username="+username+"&password="+password
        }).then(res => {
            this.setToken(res.token) // Setting the token in localStorage
            NotificationManager.success(Translations['notification.login'][LanguageConfig.language]);
            return Promise.resolve(res);
        })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('mill_id_token', idToken)
        // Copy in a cookie for authent on linked apps (wiki...)
        cookies.set('mill_id_token', idToken, { path: process.env.REACT_APP_COOKIE_ROOT });
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('mill_id_token')
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('mill_id_token');
        cookies.set('mill_id_token', "", { path: process.env.REACT_APP_COOKIE_ROOT });
        NotificationManager.success(Translations['notification.logout'][LanguageConfig.language]);
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }

    getLogin() {
        if (!this.loggedIn())
            return null;

        return this.getProfile().login;
    }

    isUserAdmin() {
        if (!this.loggedIn())
            return false;

        return this.getProfile().role === "admin";
    }

    isUserConfirmed() {
        if (!this.loggedIn())
            return false;

        return this.getProfile().role === "admin" || this.getProfile().role === "confirmed";
    }
    
    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        if (options === undefined) {
            options = {};
        }

        if (options.headers !== undefined) {
            const combinedHeaders = {
                ...headers,
                ...options.headers
            }

            options.headers = combinedHeaders;
        } else {
            options.headers = headers;
        }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {

            // Error notifications are handled here, globally
            response.json().then(content => {
                if (content.message !== undefined) {
                    // If it's a registered error we have a translation
                    if (Translations[content.message])
                        NotificationManager.error(Translations[content.message][LanguageConfig.language]);
                    else
                        NotificationManager.error(content.message);
                }
            });

            var error = new Error(response.statusText+":"+response.status)
            error.response = response
            throw error
        }
    }
}

const instance = new AuthService();
Object.freeze(instance);

export default instance;