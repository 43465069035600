import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import LanguageConfig from './LanguageConfig';

const cookies = new Cookies();

const LanguageContext = React.createContext({});

class LanguageProvider extends Component {

    constructor(props) {
        super(props);

        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        let handleLanguageChange = this.handleLanguageChange;

        this.state = {
            language: cookies.get('language') || 'en',
            handleLanguageChange: handleLanguageChange
        }

        LanguageConfig.language = this.state.language;
    }

    handleLanguageChange(language) {
        cookies.set('language', language, { path: process.env.REACT_APP_COOKIE_ROOT });

        this.setState({ language: language });

        // copy the change to the config for use outside react
        LanguageConfig.language = language;
    }

    render() {
        return <LanguageContext.Provider value={this.state}>
            {this.props.children}
        </LanguageContext.Provider>
    }
}

export { LanguageProvider, LanguageContext };