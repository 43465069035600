import React from 'react';
import { Translate, Translator } from 'react-translated';
import { Link } from 'react-router-dom'

import { ContentPage, ContentZone } from '../Common/CommonComponents';
import AuthService from './AuthService';
import LanguageConfig from '../Common/LanguageConfig';

import * as URLs from '../URLS'

export default class LoginScreen extends React.Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentWillMount() {
        if (AuthService.loggedIn())
            this.props.history.replace(URLs.home);
    }

    render() {

        return (
            <ContentPage pageTitle={LanguageConfig.getText("login.title")} maxWidth="500px">
                <ContentZone>
                    <h1><Translate text={"login.title"} /></h1>
                    <p><Translate text={"login.desc"} /></p>
                    <p><Link to={URLs.user_requestnewpassword}>
                        <Translate text={"login.passwordreset"} />
                    </Link></p>

                    <form onSubmit={this.handleFormSubmit}>
                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <Translate text={"login.username"} />
                            <input
                                className="form-control form-control-sm"
                                style={{ width: "200px", marginLeft: 10 }}
                                name="username"
                                type="text"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <Translate text={"login.password"} />
                            <input
                                className="form-control form-control-sm"
                                style={{ width: "200px", marginLeft: 10 }}
                                name="password"
                                type="password"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div style={{ width: "100%", height: "2em", marginTop: "1em" }}>
                            <Translator>
                                {({ translate }) => (
                                    <input
                                        className="btn btn-primary"
                                        style={{ float: "right", width: "150px" }}
                                        value={translate({
                                            text: 'login.login'
                                        })}
                                        type="submit"
                                    />
                                )}
                            </Translator>
                        </div>
                    </form>
                </ContentZone>
            </ContentPage>
        )
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleFormSubmit(e) {
        e.preventDefault();

        AuthService.login(this.state.username, this.state.password)
            .then(res => {
                this.props.history.goBack();
            })
            .catch(err => {
                console.log(err);
            })
    }
}