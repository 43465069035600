import React from 'react';
import { Link } from 'react-router-dom'
import { Translate } from 'react-translated';
import { FaBook } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';
import * as URLs from '../URLS'

export default class EncyclopediaAdminMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
        };
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    toggleCollapse() {
        this.setState({ collapsed: !this.state.collapsed });
    }

    render() {

        let displayMenuClass = "";

        if (!this.state.collapsed)
            displayMenuClass = "show";

        return (
            <div>
                <Dropdown pullRight
                    className={"topheaderbutton rounded"}
                    id={`usermenu`}
                >
                    <Dropdown.Toggle onClick={() => { this.toggleCollapse() }}>
                        <FaBook className="activeicon" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={displayMenuClass}>
                        <li><Link to={URLs.encyclopedia_newcontents} onClick={this.toggleCollapse} >
                            <Translate text="newcontentslist.title" />
                        </Link></li>
                        <li><Link to={URLs.encyclopedia_newimages} onClick={this.toggleCollapse} >
                            <Translate text="newimageslist.title" />
                        </Link></li>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }
}

