import React from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import './library.css'
import { MessageZone } from '../Common/CommonComponents';
import LibraryService from './LibraryService';

import ContentForm from './ContentForm';
import AuthService from '../Users/AuthService';

import * as URLs from '../URLS';
import ContentImageEditForm from './ContentImageEditForm';

export default class ContentEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            content: null,
            lastcall: null,
            editorState: EditorState.createEmpty(),
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.refreshEditor = this.refreshEditor.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
        this.forceRefresh = this.forceRefresh.bind(this);
    }

    refreshEditor() {
        if (this.previousCurrentDesc !== this.state.content.description) {
            const contentBlock = htmlToDraft(this.state.content.description);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({
                    editorState: editorState,
                });
                this.previousCurrentDesc = this.state.content.description;
            }
        }
    }

    handleChange(e) {
        const content = this.state.content;

        //special handling of the confirmed flag which is a string API-side
        if (e.target.name === "confirmed") {
            content[e.target.name] = e.target.checked ? "1" : "0";
        } else {
            content[e.target.name] = e.target.value;
        }

        this.setState(
            {
                content: content
            }
        )
    }

    handleEditorChange(editorState) {
        this.setState({
            editorState,
        });
    }

    handleSelectedFile(event) {
        if (event.target.files[0] !== undefined) {
            this.setState({
                selectedFile: event.target.files[0],
                selectedFileURL: URL.createObjectURL(event.target.files[0]),
                loaded: 0,
            })
        } else {
            this.setState({
                selectedFile: null,
                selectedFileURL: null,
                loaded: 0,
            })
        }
    }

    handleFormSubmit(e) {
        e.preventDefault();

        const content = this.state.content;

        const contentid = this.props.match.params.content;
        const desc = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));

        let confirmed = null;

        if (AuthService.isUserAdmin()) {
            // Only admins update the confirmed status
            confirmed = content.confirmed;
        }

        LibraryService.postContentDetails(contentid, desc, content.title, content.version, content.category,
            content.culture, confirmed).then(() => {
                this.refreshContent();
                this.refreshCategories();
                this.refreshCultures();
            });

        if (this.state.selectedFile !== undefined) {
            LibraryService.postContentFile(contentid, this.state.selectedFile).then(
                (result) => {
                    this.props.history.replace(URLs.library_detail + "/" + contentid);
                }
            );
        }
    }

    componentDidMount() {
        this.refreshContent();
        this.refreshCategories();
        this.refreshCultures();
    }

    componentDidUpdate() {
        this.refreshContent();
        this.refreshCategories();
        this.refreshCultures();
    }

    forceRefresh() {
        this.setState({
            lastcall: null,
        });
        this.refreshContent();
    }

    refreshContent() {
        const contentid = this.props.match.params.content;
        const callkey = "contents/" + contentid;

        if (callkey === this.state.lastcall)
            return;

        this.setState({
            lastcall: callkey,
        });

        LibraryService.getContentDetails(contentid)
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        content: result,
                    });
                    this.refreshEditor();
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        lastcall: callkey,
                        error
                    });
                }
            )
    }

    refreshCategories() {
        const callkey = "categories/";

        if (callkey === this.state.lastcallCategories)
            return;

        this.setState({
            lastcallCategories: callkey,
        });

        LibraryService.getCategoriesList()
            .then(
                (result) => {
                    this.setState({
                        categories: result,
                    });
                }
            )
    }

    refreshCultures() {
        const callkey = "cultures/";

        if (callkey === this.state.lastcallCultures)
            return;

        this.setState({
            lastcallCultures: callkey,
        });

        LibraryService.getCulturesList()
            .then(
                (result) => {
                    this.setState({
                        cultures: result,
                    });
                }
            )
    }

    render() {
        const { error, isLoaded, content } = this.state;

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else if (!isLoaded) {
            return <MessageZone message={"Loading..."} />
        } else {
            if (this.props.pictureEdit === false) {
                return (
                    <ContentForm
                        content={content} categories={this.state.categories} cultures={this.state.cultures}
                        location={this.props.location} handleChange={this.handleChange} handleFormSubmit={this.handleFormSubmit}
                        handleSelectedFile={this.handleSelectedFile}
                        editorState={this.state.editorState} handleEditorChange={this.handleEditorChange} />
                )
            } else {
                return (
                    <ContentImageEditForm content={content} forceRefresh={this.forceRefresh} />
                )
            }
        }
    }
}