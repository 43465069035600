import React from 'react';
import { Translate } from 'react-translated';
import { FaFileDownload } from 'react-icons/fa';
import renderHTML from 'react-render-html';

import LanguageConfig from '../Common/LanguageConfig';
import "./homepage.css"
import toppicture from './img/mln112.png';
import HomepageSlideshow from './HomepageSlideshow';
import HomepageService from './HomepageService';
import { LanguageContext } from '../Common/LanguageContext';
import HomepageSeljuk from './HomepageSeljuk';

export default class Homepage extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            currentVersion: [],
            lastcall: null
        };
    }

    componentDidMount() {
        this.refreshList()
    }

    componentDidUpdate() {
        this.refreshList()
    }

    refreshList() {
        const callkey = "currentversion/" + this.context.language;

        if (callkey === this.state.lastcall)
            return;

        HomepageService.getCurrentVersion(this.context.language)
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        currentVersion: result,
                        lastcall: callkey,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        lastcall: callkey,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <div>
                <HomepageIntro currentVersion={this.state.currentVersion} />
                <HomepageSeljuk />
                <HomepageDL currentVersion={this.state.currentVersion} />
                <HomepageSlideshow />
            </div>
        )
    }
}

class HomepageIntro extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expended: false
        };
        this.toggleAnnoncementExpand = this.toggleAnnoncementExpand.bind(this);
    }

    toggleAnnoncementExpand() {
        this.setState({
            expended: !this.state.expended
        })
    }

    render() {
        const currentVersion = this.props.currentVersion;
        return (
            <div>
                <section className="page-section clearfix">
                    <div className="maincontent">
                        <div className="intro">
                            <img className="intro-img img-fluid mb-3 mb-lg-0 rounded" src={toppicture} alt="" />
                            <div className="intro-text left-0 bg-faded p-5 rounded">
                                <h2 className="section-heading mb-4">
                                    <span className="section-heading-upper"><Translate text="ind_mc112" /></span>
                                    <span className="section-heading-lower"><Translate text="ind_currentversion" /> {currentVersion.version}</span>
                                </h2>
                                <div className="mb-3">{LanguageConfig.getHTML("ind_intro_text")}</div>

                                <div className="mb-3">
                                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" style={{ display: "flex" }}>
                                        <input type="hidden" name="cmd" value="_s-xclick" />
                                        <input type="hidden" name="hosted_button_id" value="SA68VLAT3WDGA" />
                                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit"
                                            alt="PayPal - The safer, easier way to pay online!" style={{ float: "left" }} />
                                        <img alt="" border="0" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" width="1" height="1" />
                                        <span style={{ fontWeight: "bold", margin: "auto" }}><Translate text="ind_donation" /></span>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

class HomepageDL extends React.Component {
    render() {
        const currentVersion = this.props.currentVersion;
        return (
            <section className="page-section cta">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mx-auto">
                            <div className="cta-inner rounded">
                                <h2 className="section-heading mb-4">
                                    <span className="section-heading-lower"><Translate text="ind_mod_download" /></span>
                                </h2>
                                <p className="mb-0"><Translate text="ind_mod_warning" /></p>
                                <div className="row mb-3 mt-3 p-3 rounded border border-dark d-flex justify-content-between">
                                    <div className="col vertical-align">
                                        <p><Translate text="ind_mod_112" /> Millénaire {currentVersion.version}</p>
                                    </div>
                                    <div className=" vertical-align" style={{ textAlign: "center", float: "right" }}>
                                        <a href={currentVersion.url}><FaFileDownload className="iconDL" />
                                            <br />
                                            <Translate text="ind_mod_download" /> (<Translate text="ind_mod_client_server" />)
                                        </a>
                                    </div>
                                </div>
                                <div className="row mb-3 mt-3 p-3 rounded border border-dark d-flex justify-content-between">
                                    <div className="col vertical-align">
                                        <p><Translate text="ind_mod_17" /></p>
                                    </div>
                                    <div className="vertical-align" style={{ textAlign: "center", float: "right" }}>
                                        <div className="mr-2"><a href="/php/dlpage.php?file=Millenaire6.0.2.zip"><FaFileDownload className="iconDL" /><br /><Translate text="ind_mod_client" /></a></div>
                                        <br />
                                        <div><a href="/php/dlpage.php?file=MillenaireServer6.0.2.zip"><FaFileDownload className="iconDL" /><br /><Translate text="ind_mod_server" /></a></div>
                                    </div>
                                </div>
                                <p>{LanguageConfig.getHTML("ind_curseforge")}</p>
                            </div>
                        </div>
                        <div className="col-md-6 mx-auto">
                            <div className="cta-inner rounded">
                                <h2 className="section-heading mb-4">
                                    <span className="section-heading-upper"><Translate text="ind_release_date" /> {currentVersion.date}</span>
                                    <span className="section-heading-lower"><Translate text="ind_release_112" data={{ version: currentVersion.version }} /></span>
                                </h2>
                                <ExpendedDesc currentVersion={currentVersion} />
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        )
    }
}

class ExpendedDesc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expended: false
        };
        this.toggleExpand = this.toggleExpand.bind(this);
    }

    toggleExpand() {
        this.setState({
            expended: !this.state.expended
        })
    }

    render() {
        const expended = !this.state.expended;
        const currentVersion = this.props.currentVersion;

        let shortDesc = currentVersion.description;

        if (shortDesc !== undefined && shortDesc.length > 250) {
            shortDesc = shortDesc.substring(0, 250) + "...";
        }

        return (
            <div className="container">
                <button type="button" className="btn btn-primary" onClick={this.toggleExpand} style={{ float: "left", marginRight: "10px" }}><Translate text={this.state.expended ? "shrink" : "expand"} /></button>
                <div className={"collapse " + (expended && "show")}>
                    {shortDesc !== undefined && renderHTML(shortDesc)}
                </div>
                <div className={"collapse " + (!expended && "show")}>
                    {currentVersion.description !== undefined && renderHTML(currentVersion.description)}
                </div>
            </div>
        )
    }
}