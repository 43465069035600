import React from 'react';
import { Translate } from 'react-translated';
import Autosuggest from 'react-autosuggest';
import { MillenaireIcon } from './EncyclopediaComponents';

import { MessageZone } from '../Common/CommonComponents';
import EncyclopediaService from './EncyclopediaService';
import LanguageConfig from '../Common/LanguageConfig';
import { LanguageContext } from '../Common/LanguageContext';

import './autosuggest.css';

export default class EncyclopediaAutoSuggest extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            contentindex: [],
            lastcall: null,
            suggestions: [],
            value: ''
        };
    }

    componentDidMount() {
        this.input.focus();
    }

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    getSuggestionValue = suggestion => suggestion.label;

    // Use your imagination to render suggestions.
    renderSuggestion(suggestion) {
        return (
            <div>
                <MillenaireIcon iconkey={suggestion.iconkey} />{suggestion.label}
            </div>
        )
    }

    renderSectionTitle(section) {
        return (
            <strong>{section.culturelabel} > <Translate text={"culture." + section.type} />{section.categorylabel !== null ? " > " + section.categorylabel : ""}</strong>
        );
    }

    getSectionSuggestions(section) {
        return section.items;
    }

    // Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions = value => {

        this.refreshList();

        const inputValue = value.trim().toLowerCase();

        if (inputValue.length < 3)
            return [];

        const regex = new RegExp(inputValue, 'i');

        let sections = this.state.contentindex
            .map(section => {
                return {
                    culturelabel: section.culturelabel,
                    culture: section.culture,
                    type: section.type,
                    categorylabel: section.categorylabel,
                    items: section.items.filter(item => regex.test(item.label))
                };
            })
            .filter(section => section.items.length > 0);

        sections.sort((a, b) => {
            if (a.type === "cultures" && b.type !== "cultures")
                return -10;

            if (b.type === "cultures" && a.type !== "cultures")
                return 10;

            return b.type.localeCompare(a.type);
        })

        return sections;
    };

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
    };

    refreshList() {
        const callkey = "contentindex/" + this.context.language;

        if (callkey === this.state.lastcall)
            return;

        EncyclopediaService.getContentIndex(this.context.language)
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        contentindex: result,
                        lastcall: callkey,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        lastcall: callkey,
                        error
                    });
                }
            )
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    storeInputReference = autosuggest => {
        if (autosuggest !== null) {
            this.input = autosuggest.input;
        }
    };

    render() {
        const { error, suggestions, value } = this.state;

        const inputProps = {
            placeholder: LanguageConfig.getText("encyclopedia.search"),
            value,
            onChange: this.onChange
        };

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else {

            return (
                <Autosuggest
                    multiSection={true}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    renderSectionTitle={this.renderSectionTitle}
                    getSectionSuggestions={this.getSectionSuggestions}
                    onSuggestionSelected={this.props.onSuggestionSelected}
                    ref={this.storeInputReference}
                    inputProps={inputProps}
                />
            );
        }
    }
}