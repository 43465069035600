import React from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import { Link } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import { FaImages } from 'react-icons/fa';

import './library.css'
import { ContentPage, ContentZone } from '../Common/CommonComponents';
import ReactTooltip from 'react-tooltip';
import AuthService from '../Users/AuthService';

import * as Icons from './ContentIcons';

import * as URLs from '../URLS';

export default class ContentForm extends React.Component {
    render() {
        const content = this.props.content;

        const categoriesOptions = [];

        if (this.props.categories !== undefined) {
            this.props.categories.forEach((category) => {
                categoriesOptions.push(
                    <option value={category.id} key={category.id}>{category.label}</option>
                )
            })
        }

        const culturesOptions = [];

        if (this.props.cultures !== undefined) {
            this.props.cultures.forEach((culture) => {
                culturesOptions.push(
                    <option value={culture.id} key={culture.id}>{culture.label}</option>
                )
            })
        }

        return (
            <ContentPage pageTitle={content.title}>
                <ContentZone>
                    <div style={{ float: "right", marginTop: "0", marginRight: "0" }}>
                        <Link to={URLs.library_detail + "/" + content.contentid + "/editimages"} >
                            <button type="button" className="btn btn-primary mb-2 mr-2">
                                <FaImages className="buttonicon" />
                                Edit Images
                        </button>
                        </Link>
                    </div>
                    <ReactTooltip place="top" type="light" effect="solid" id="iconsToolTip"
                        html={true} className="changetooltip"
                        getContent={(dataTip) => `${dataTip}`} />
                    <h1>Content Edition</h1>
                    <form onSubmit={this.props.handleFormSubmit}>
                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <div className="libraryformlabel">Title:</div>
                            <input
                                className="form-control form-control-sm"
                                name="title"
                                type="text"
                                value={content.title}
                                onChange={this.props.handleChange}
                            />
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <div className="libraryformlabel"><Icons.Version />Content version:</div>
                            <div style={{ width: "100%" }}>
                                <input
                                    className="form-control form-control-sm"
                                    name="version"
                                    type="text"
                                    style={{ width: "200px" }}
                                    value={content.version}
                                    onChange={this.props.handleChange}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <div className="libraryformlabel"><Icons.Category />Category:</div>
                            <div style={{ width: "100%" }}>
                                <select
                                    style={{ width: "200px" }}
                                    location={this.props.location} handleChange={this.handleChange}
                                    onChange={this.props.handleChange}
                                    name="category"
                                    value={content.category}
                                >
                                    {categoriesOptions}
                                </select>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <div className="libraryformlabel"><Icons.Culture />Culture:</div>
                            <div style={{ width: "100%" }}>
                                <select
                                    style={{ width: "200px" }}
                                    onChange={this.props.handleChange}
                                    name="culture"
                                    value={content.culture}
                                >
                                    {culturesOptions}
                                </select>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <div className="libraryformlabel">Confirmed:</div>
                            <div style={{ width: "100%" }}>
                                <input
                                    className="form-control form-control-sm"
                                    name="confirmed"
                                    type="checkbox"
                                    style={{ width: "200px" }}
                                    checked={content.confirmed === "1"}
                                    disabled={!AuthService.isUserAdmin()}
                                    onChange={this.props.handleChange} />
                            </div>
                        </div>

                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <div className="libraryformlabel">Description:</div>
                            <div style={{ width: "100%" }}>
                                <Editor
                                    editorState={this.props.editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName "
                                    toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
                                        image: {
                                            urlEnabled: false,
                                            uploadEnabled: false,
                                        },
                                    }}

                                    onEditorStateChange={this.props.handleEditorChange}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mt-2">
                            <div className="libraryformlabel">Content archive:</div>
                            <div style={{ width: "100%" }}>
                                <input type="file" onChange={this.props.handleSelectedFile} />
                            </div>
                        </div>

                        <div style={{ width: "100%" }} className="mt-2">
                            <input
                                className="btn btn-primary"
                                style={{ float: "right", width: "150px" }}
                                value="Submit"
                                type="submit"
                            />
                        </div>
                    </form>
                </ContentZone>
            </ContentPage>
        )
    }
}


