import React from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import ReactTooltip from 'react-tooltip';
import draftToHtml from 'draftjs-to-html';

import { FaCheck } from 'react-icons/fa';

import './library.css'
import { ContentPage, ContentZone } from '../Common/CommonComponents';
import LibraryService from './LibraryService';

import * as Icons from './ContentIcons';

import * as URLs from '../URLS';

export default class ContentCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
        this.isFormComplete = this.isFormComplete.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    refreshCategories() {
        const callkey = "categories/";

        if (callkey === this.state.lastcallCategories)
            return;

        this.setState({
            lastcallCategories: callkey,
        });

        LibraryService.getCategoriesList()
            .then(
                (result) => {
                    this.setState({
                        categories: result,
                    });
                }
            )
    }

    refreshCultures() {
        const callkey = "cultures/";

        if (callkey === this.state.lastcallCultures)
            return;

        this.setState({
            lastcallCultures: callkey,
        });

        LibraryService.getCulturesList()
            .then(
                (result) => {
                    this.setState({
                        cultures: result,
                    });
                }
            )
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleEditorChange(editorState) {
        this.setState({
            editorState,
        });
    }

    handleSelectedFile(event) {
        if (event.target.files[0] !== undefined) {
            this.setState({
                selectedFile: event.target.files[0],
                selectedFileURL: URL.createObjectURL(event.target.files[0]),
                loaded: 0,
            })
        } else {
            this.setState({
                selectedFile: null,
                selectedFileURL: null,
                loaded: 0,
            })
        }
    }

    isFormComplete() {
        const { selectedFile, culture, category, title, version, editorState } = this.state;

        if (selectedFile === undefined) {
            return false;
        }

        if (culture === undefined || culture === "select") {
            return false;
        }

        if (category === undefined || category === "select") {
            return false;
        }

        if (version === undefined || version === "") {
            return false;
        }

        if (title === undefined || title === "") {
            return false;
        }

        if (!editorState.getCurrentContent().hasText())
            return false;

        return true;
    }

    handleFormSubmit(e) {
        e.preventDefault();

        const { selectedFile, culture, category, title, version } = this.state;

        const desc = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));


        LibraryService.createContentDetails(desc, title, version, category,
            culture).then(
                (result) => {
                    LibraryService.postContentFile(result.contentid, selectedFile).then(
                        (res2) => {
                            this.props.history.replace(URLs.library_detail + "/" + result.contentid);
                        }
                    );
                }
            );
    }

    componentDidMount() {
        this.refreshCategories();
        this.refreshCultures();
    }

    componentDidUpdate() {
        this.refreshCategories();
        this.refreshCultures();
    }

    render() {
        const { selectedFile, culture, category, title, version } = this.state;

        const categoriesOptions = [];
        categoriesOptions.push(
            <option value="select" key="select">Select</option>
        )
        if (this.state.categories !== undefined) {
            this.state.categories.forEach((category) => {
                categoriesOptions.push(
                    <option value={category.id} key={category.id}>{category.label}</option>
                )
            })
        }

        const culturesOptions = [];
        culturesOptions.push(
            <option value="select" key="select">Select</option>
        )
        if (this.state.cultures !== undefined) {
            this.state.cultures.forEach((culture) => {
                culturesOptions.push(
                    <option value={culture.id} key={culture.id}>{culture.label}</option>
                )
            })
        }

        return (
            <ContentPage pageTitle="New Content">
                <ContentZone>
                    <ReactTooltip place="top" type="light" effect="solid" id="iconsToolTip"
                        html={true} className="changetooltip"
                        getContent={(dataTip) => `${dataTip}`} />
                    <h1>Upload Content</h1>
                    <p><em>You can add pictures after the content has been uploaded.</em></p>
                    <form onSubmit={this.handleFormSubmit}>
                        <div className={"formcheck " + (title !== undefined && title !== "" ? "formcheck_valid" : "")}>
                            <FaCheck />
                        </div>
                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <div className="libraryformlabel">Title:</div>
                            <input
                                className="form-control form-control-sm"
                                name="title"
                                type="text"
                                onChange={this.handleChange}
                            />
                        </div>

                        <div className={"formcheck " + (version !== undefined && version !== "" ? "formcheck_valid" : "")}>
                            <FaCheck />
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <div className="libraryformlabel"><Icons.Version />Content version:</div>
                            <div style={{ width: "100%" }}>
                                <input
                                    className="form-control form-control-sm"
                                    name="version"
                                    type="text"
                                    style={{ width: "200px" }}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className={"formcheck " + (category !== undefined && category !== "select" ? "formcheck_valid" : "")}>
                            <FaCheck />
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <div className="libraryformlabel"><Icons.Category />Category:</div>
                            <div style={{ width: "100%" }}>
                                <select
                                    style={{ width: "200px" }}
                                    onChange={this.handleChange}
                                    name="category"
                                >
                                    {categoriesOptions}
                                </select>
                            </div>
                        </div>
                        <div className={"formcheck " + (culture !== undefined && culture !== "select" ? "formcheck_valid" : "")}>
                            <FaCheck />
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <div className="libraryformlabel"><Icons.Culture />Culture:</div>
                            <div style={{ width: "100%" }}>
                                <select
                                    style={{ width: "200px" }}
                                    onChange={this.handleChange}
                                    name="culture"
                                >
                                    {culturesOptions}
                                </select>
                            </div>
                        </div>
                        <div className={"formcheck " + (this.state.editorState.getCurrentContent().hasText() ? "formcheck_valid" : "")}>
                            <FaCheck />
                        </div>
                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <div className="libraryformlabel">Description:</div>
                            <div style={{ width: "100%" }}>
                                <Editor
                                    editorState={this.state.editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName "
                                    toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
                                        image: {
                                            urlEnabled: false,
                                            uploadEnabled: false,
                                        },
                                    }}

                                    onEditorStateChange={this.handleEditorChange}
                                />
                            </div>
                        </div>
                        <div className={"formcheck " + (selectedFile !== undefined ? "formcheck_valid" : "")}>
                            <FaCheck />
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <div className="libraryformlabel">Content archive:</div>
                            <div style={{ width: "100%" }}>
                                <input type="file" onChange={this.handleSelectedFile} />
                                <div style={{marginTop:"1em"}}><em>The uploaded file must be a zip, rar or 7zip archive.</em></div>
                            </div>
                        </div>                        

                        <div style={{ width: "100%" }} className="mt-2">
                            <input
                                className="btn btn-primary"
                                style={{ float: "right", width: "150px" }}
                                disabled={!this.isFormComplete()}
                                value="Create"
                                type="submit"
                            />
                        </div>
                    </form>
                </ContentZone>
            </ContentPage>
        )
    }
}


