import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';

import './library.css'
import { MessageZone, ContentPage, ContentZone } from '../Common/CommonComponents';
import LibraryService from './LibraryService';
import ReactTooltip from 'react-tooltip'
import { FaDownload, FaEdit, FaStar, FaRegStar, FaTrash } from 'react-icons/fa';
import AuthService from '../Users/AuthService';

import * as Icons from './ContentIcons';

export default class ContentDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            content: null,
            lastcall: null
        };
        this.forceRefresh = this.forceRefresh.bind(this);

        this.actions = {
            addComment: this.addComment.bind(this),
            deleteComment: this.deleteComment.bind(this),
            editComment: this.editComment.bind(this),
            starContent: this.starContent.bind(this),
            unstarContent: this.unstarContent.bind(this),
        };
    }

    deleteComment(commentid) {
        LibraryService.deleteComment(this.state.content.contentid, commentid).then((res) => {
            this.forceRefresh();
        });
    }

    editComment(commentid, text) {
        LibraryService.postEditComment(this.state.content.contentid, commentid, text).then((res) => {
            this.forceRefresh();
        });
    }

    addComment(text) {
        LibraryService.postNewComment(this.state.content.contentid, text).then((res) => {
            this.forceRefresh();
        });
    }

    starContent() {
        LibraryService.postStar(this.state.content.contentid, 1).then((res) => {
            this.forceRefresh();
        });
    }

    unstarContent() {
        LibraryService.postStar(this.state.content.contentid, 0).then((res) => {
            this.forceRefresh();
        });
    }

    forceRefresh() {
        this.setState({ lastcall: null });
        this.refreshContent();
    }

    componentDidMount() {
        this.refreshContent()
    }

    componentDidUpdate() {
        this.refreshContent()
    }

    refreshContent() {

        const contentid = this.props.match.params.content;
        const callkey = "contents/" + contentid + "_" + AuthService.getLogin();;

        if (callkey === this.state.lastcall)
            return;

        this.setState({
            lastcall: callkey,
        });

        LibraryService.getContentDetails(contentid)
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        content: result,
                    });
                    // update the view count
                    LibraryService.postViewUpdate(contentid);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        lastcall: callkey,
                        error
                    });
                }
            )
    }

    render() {
        const { error, isLoaded, content } = this.state;

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else if (!isLoaded) {
            return <MessageZone message={"Loading..."} />
        } else {
            return (
                <ContentDetailsDisplay
                    content={content} location={this.props.location}
                    actions={this.actions} />
            )
        }
    }
}

class ContentDetailsDisplay extends React.Component {
    render() {
        const content = this.props.content;
        const lastUpdated = new Date(content.lastmodified)
        let dlurl = content.url;

        if (dlurl.startsWith("../")) {
            dlurl = dlurl.substring(2);
        }

        let showEdit = false;

        if (AuthService.isUserAdmin() || AuthService.getLogin() === content.author) {
            showEdit = true;
        }

        return (
            <ContentPage pageTitle={content.title}>
                <ContentZone>
                    <div style={{ float: "right", marginTop: "0", marginRight: "0", marginLeft: "1em" }}>
                        <a href={dlurl} onClick={event => LibraryService.postDlUpdate(content.contentid)}>
                            <button className="btn btn-primary mr-2"  >
                                <FaDownload className="buttonicon" />
                                Download
                            </button>
                        </a>
                    </div>
                    {showEdit &&
                        <div style={{ float: "right", marginTop: "0", marginRight: "0", marginLeft: "1em" }}>
                            <Link to={this.props.location.pathname + "/edit"} >
                                <button className="btn btn-primary mr-2"  >
                                    <FaEdit className="buttonicon" />
                                    Edit
                                </button>
                            </Link>
                        </div>
                    }
                    <div style={{ margin: "auto", display: "flex" }}>
                        <span className="millenaire-subheader-responsive vertical-align">
                            {AuthService.loggedIn() && content.starred === "1" &&
                                <FaStar style={{ color: "#ffc107" }} data-for="iconsToolTip" data-tip="You starred this content."
                                    onClick={this.props.actions.unstarContent} />
                            }
                            {AuthService.loggedIn() && content.starred !== "1" &&
                                <FaRegStar style={{ color: "grey" }} data-for="iconsToolTip" data-tip="You can star this content."
                                    onClick={this.props.actions.starContent} />
                            }
                            {content.title}
                        </span>
                    </div>
                    {content.confirmed === "0" &&
                        <div style={{ margin: "auto", display: "flex" }}>
                            <span className="millenaire-subheader-responsive vertical-align">
                                <b>(Unconfirmed)</b>
                            </span>
                        </div>
                    }

                    <ReactTooltip place="top" type="light" effect="solid" id="iconsToolTip"
                        html={true} className="changetooltip"
                        getContent={(dataTip) => `${dataTip}`} />
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <div className="cardattribute"><Icons.Author />{content.author}</div>
                        <div className="cardattribute"><Icons.Version />{content.version}</div>
                        <div className="cardattribute"><Icons.LastUpdated />{lastUpdated.toLocaleDateString()}</div>
                        <div className="cardattribute"><Icons.Category />{content.catlabel}</div>
                        <div className="cardattribute"><Icons.Culture />{content.cultlabel}</div>
                        <div className="cardattribute"><Icons.Views />{content.nbview}</div>
                        <div className="cardattribute"><Icons.Downloads />{content.nbdl}</div>
                        <div className="cardattribute"><Icons.Stars />{content.nbstars}</div>
                    </div>
                    <div style={{ width: "100%", textAlign: "left" }}>
                        {renderHTML(content.description)}
                    </div>
                    {content.images.length > 0 &&
                        <ContentSlideshow images={content.images} />
                    }
                    {content.comments.length > 0 &&
                        <ContentComments comments={content.comments} actions={this.props.actions} />
                    }
                    {AuthService.loggedIn() &&
                        <NewComment addComment={this.props.actions.addComment} />
                    }
                    {!AuthService.loggedIn() &&
                        <em>Please login to add comments.</em>
                    }
                </ContentZone>
            </ContentPage>
        )
    }
}

class ContentComments extends React.Component {
    render() {
        const { comments } = this.props;

        const commentsTags = [];

        if (comments !== undefined)
            comments.forEach((item) => {
                commentsTags.push(
                    <ContentComment comment={item} key={item.id} actions={this.props.actions} />
                )
            })

        return (
            <div>
                {commentsTags}
            </div>
        )
    }
}

class NewComment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            commentText: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleSubmitForm(e) {
        e.preventDefault();
        this.props.addComment(this.state.commentText);
    }

    render() {
        return (
            <div className="lightbeigebg rounded p-4 m-4">
                <form onSubmit={this.handleSubmitForm}>
                    <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                        <div className="libraryformlabel">Add Comment:</div>
                        <textarea
                            className="form-control form-control-sm"
                            name="commentText"
                            type="text"
                            onChange={this.handleChange}
                        />
                    </div>

                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }} className="mt-2">
                        <input
                            className="btn btn-primary"
                            style={{ width: "150px" }}
                            value="Create"
                            type="submit"
                        />
                    </div>
                </form>
            </div>
        )
    }
}

class ContentComment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            editedText: this.props.comment.text,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.toggleMode = this.toggleMode.bind(this);
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    toggleMode() {
        this.setState({
            editMode: !this.state.editMode,
            editedText: this.props.comment.text,
        })
    }

    handleSubmitForm(e) {
        e.preventDefault();
        this.props.actions.editComment(this.props.comment.id, this.state.editedText);
        this.setState({
            editMode: false,
        })
    }

    render() {
        const comment = this.props.comment;
        const dateFormat = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });

        const canEdit = (AuthService.isUserAdmin() || comment.author === AuthService.getLogin());
        const editMode = this.state.editMode;

        const commentText = comment.text.split('\n').map((item, i) => <span key={i}>{item}<br /></span>);

        return (
            <div className="lightbeigebg rounded p-4 m-4">

                {!editMode &&
                    <div>
                        {commentText}
                    </div>
                }
                {editMode &&
                    <div>
                        <form onSubmit={this.handleSubmitForm}>
                            <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                                <textarea
                                    className="form-control form-control-sm"
                                    name="editedText"
                                    type="text"
                                    value={this.state.editedText}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }} className="mt-2">
                                <input
                                    className="btn btn-primary"
                                    style={{ width: "150px" }}
                                    value="Save"
                                    type="submit"
                                />
                            </div>
                        </form>
                    </div>
                }
                <br />
                <div style={{ display: "flex", justifyContent: "space-between" }} >

                    <div>
                        <em>Posted by <b>{comment.author}</b> on {dateFormat.format(Date.parse(comment.postedon))}</em>
                        {comment.modifiedby !== null &&
                            <em><br />Last modified by <b>{comment.modifiedby}</b> on {dateFormat.format(Date.parse(comment.modifiedon))}</em>
                        }
                    </div>
                    {canEdit &&
                        <div style={{ display: "flex" }} >
                            <button className="btn btn-primary mr-2" onClick={this.toggleMode}>
                                <FaEdit className="buttonicon" /> Edit
                            </button>
                            <button className="btn btn-primary btn-delete mr-2" onClick={() => { this.props.actions.deleteComment(comment.id) }}>
                                <FaTrash className="buttonicon" /> Delete
                            </button>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

class ContentSlideshow extends React.Component {
    render() {
        const { images } = this.props;

        const slideshowImages = [];

        if (images !== undefined)
            images.forEach((item) => {
                slideshowImages.push(
                    {
                        original: process.env.REACT_APP_LIBRARY_RES_ROOT + "img/" + item.filename,
                        thumbnail: process.env.REACT_APP_LIBRARY_RES_ROOT + "img/" + item.previewname,
                        description: item.legend,
                    }
                )
            })

        return (
            <div className="container" style={{ marginTop: "2em" }}>
                <div className="slideshow">
                    <div className="slideshow-text left-0 p-5 rounded">
                        <ImageGallery items={slideshowImages} />
                    </div>
                </div>
            </div>
        )
    }
}