import AuthService from "../Users/AuthService";
import { NotificationManager } from 'react-notifications';

class LibraryService {

    constructor() {
        this.cache = {}
    }

    invalidateCache(urlStartsWith) {
        const self = this;
        Object.keys(this.cache).forEach(function (key) {
            if (key.startsWith(urlStartsWith)) {
                delete self.cache[key];
            }
        });
    }

    getCategoriesList() {
        const url = process.env.REACT_APP_API_ROOT + "library/categories/";

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getCulturesList() {
        const url = process.env.REACT_APP_API_ROOT + "library/cultures/";

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getContentsList(withUnconfirmed = false) {
        const url = process.env.REACT_APP_API_ROOT + "library/contents/" + (withUnconfirmed ? "?withunconfirmed=true" : "");

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getUserContentsList() {
        const url = process.env.REACT_APP_API_ROOT + "library/mycontents/";

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getContentDetails(contentid) {
        const url = process.env.REACT_APP_API_ROOT + "library/contents/" + contentid;

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    createContentDetails(description, title, version, category, culture) {
        const url = process.env.REACT_APP_API_ROOT + "library/contents/";

        const body = {};

        body.description = description;
        body.title = title;
        body.version = version;
        body.category = category;
        body.culture = culture;

        return AuthService.fetch(url, {
            method: 'POST',
            body: JSON.stringify(body)
        }).then(res => {
            NotificationManager.success("The content has been created.");
            this.invalidateCache(url);
            this.invalidateContentsList();
            console.log(res.contentid);
            return res;
        }).catch((reason) => {
            console.log(reason);
        });
    }

    postContentDetails(contentid, description, title, version, category, culture, confirmed = null) {
        const url = process.env.REACT_APP_API_ROOT + "library/contents/" + contentid;

        const body = {};

        body.description = description;
        body.title = title;
        body.version = version;
        body.category = category;
        body.culture = culture;

        if (confirmed !== null)
            body.confirmed = confirmed;

        return AuthService.fetch(url, {
            method: 'POST',
            body: JSON.stringify(body)
        }).then(res => {
            NotificationManager.success("The content has been updated.");
            this.invalidateCache(url);
            this.invalidateContentsList();
        }).catch((reason) => {
            console.log(reason);
        });
    }

    postNewComment(contentid, text) {
        const url = process.env.REACT_APP_API_ROOT + "library/contents/" + contentid + "/comments";

        const body = {};

        body.text = text;

        return AuthService.fetch(url, {
            method: 'POST',
            body: JSON.stringify(body)
        }).then(res => {
            NotificationManager.success("Your comment has been added.");
            this.invalidateCache(url);
            this.invalidateContentsList();
        }).catch((reason) => {
            console.log(reason);
        });
    }

    postEditComment(contentid, commentid, text) {
        const url = process.env.REACT_APP_API_ROOT + "library/contents/" + contentid + "/comments/" + commentid;

        const body = {};

        body.text = text;

        return AuthService.fetch(url, {
            method: 'POST',
            body: JSON.stringify(body)
        }).then(res => {
            NotificationManager.success("The comment has been edited.");
            this.invalidateCache(url);
            this.invalidateContentsList();
        }).catch((reason) => {
            console.log(reason);
        });
    }

    deleteComment(contentid, commentid) {
        const url = process.env.REACT_APP_API_ROOT + "library/contents/" + contentid + "/comments/" + commentid + "/delete";

        return AuthService.fetch(url, {
            method: 'POST',
        }).then(res => {
            NotificationManager.success("The comment has been deleted.");
            this.invalidateCache(url);
            this.invalidateContentsList();
        }).catch((reason) => {
            console.log(reason);
        });
    }

    postContentFile(contentid, file) {
        const url = process.env.REACT_APP_API_ROOT + "library/contents/" + contentid + "/uploadfile";

        const formData = new FormData();
        formData.append("contentfile", file, file.name);

        return AuthService.fetch(url, {
            method: 'POST',
            body: formData
        }).then(res => {
            NotificationManager.success("The content's file has been uploaded.");
            this.invalidateCache(process.env.REACT_APP_API_ROOT + "library/contents/" + contentid);
            return res;
        }).catch((reason) => {
            console.log(reason);
        });
    }

    postStar(contentid, enabled) {
        const url = process.env.REACT_APP_API_ROOT + "library/contents/" + contentid + "/star";

        const body = {};
        body.enabled = enabled;

        return AuthService.fetch(url, {
            method: 'POST',
            body: JSON.stringify(body)
        }).then(res => {
            if (enabled === 1)
                NotificationManager.success("You have stared this content.");
            else
                NotificationManager.success("You have unstared this content.");

            this.invalidateCache(process.env.REACT_APP_API_ROOT + "library/contents/");
            this.invalidateCache(process.env.REACT_APP_API_ROOT + "library/mycontents/");
            this.invalidateCache(process.env.REACT_APP_API_ROOT + "library/starredcontents/");
            return res;
        }).catch((reason) => {
            console.log(reason);
        });
    }

    postViewUpdate(contentid) {
        const url = process.env.REACT_APP_API_ROOT + "library/contents/" + contentid + "/addview";

        AuthService.fetch(url, {
            method: 'POST'
        });
    }

    postDlUpdate(contentid) {
        const url = process.env.REACT_APP_API_ROOT + "library/contents/" + contentid + "/adddl";

        AuthService.fetch(url, {
            method: 'POST'
        });
    }

    postImageForItem(contentid, file, legend) {

        const url = process.env.REACT_APP_API_ROOT + "library/contents/" + contentid + "/images";

        const formData = new FormData();
        formData.append("picture1", file, file.name);
        formData.append("legend", legend);

        return AuthService.fetch(url, {
            method: 'POST',
            body: formData
        }).then(res => {
            NotificationManager.success("Image uploaded.");
            this.invalidateCache(process.env.REACT_APP_API_ROOT + "library/contents/" + contentid);

            return res;
        }).catch((reason) => {
            console.log(reason);
        });
    }

    updateImageForItem(contentid, pos, legend) {
        const url = process.env.REACT_APP_API_ROOT + "library/contents/" + contentid + "/images/" + pos;

        const formData = new FormData();
        formData.append("legend", legend);

        return AuthService.fetch(url, {
            method: 'POST',
            body: formData
        }).then(res => {
            NotificationManager.success("Image updated.");
            this.invalidateCache(process.env.REACT_APP_API_ROOT + "library/contents/" + contentid);

            return res;
        }).catch((reason) => {
            console.log(reason);
        });
    }

    deleteImageForItem(contentid, pos) {
        const url = process.env.REACT_APP_API_ROOT + "library/contents/" + contentid + "/images/" + pos + "/delete";
        return AuthService.fetch(url, {
            method: 'POST',
        }).then(res => {
            NotificationManager.success("Image deleted.");
            this.invalidateCache(process.env.REACT_APP_API_ROOT + "library/contents/" + contentid);

            return res;
        }).catch((reason) => {
            console.log(reason);
        });
    }


    invalidateContentsList() {
        this.invalidateCache(process.env.REACT_APP_API_ROOT + "library/contents/");
        this.invalidateCache(process.env.REACT_APP_API_ROOT + "library/mycontents/");
    }
}

const instance = new LibraryService();
Object.freeze(instance);

export default instance;