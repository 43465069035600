import React from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import Modal from 'react-responsive-modal';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import './library.css'
import { ContentPage, ContentZone } from '../Common/CommonComponents';
import LibraryService from './LibraryService';

import * as URLs from '../URLS';

export default class ContentImageEditForm extends React.Component {
    render() {
        const content = this.props.content;

        const imagesJSX = [];

        content.images.forEach((image) => {
            imagesJSX.push(
                <DisplayImageForm image={image} key={image.filename} forceRefresh={this.props.forceRefresh} />
            )
        })

        return (
            <ContentPage pageTitle={content.title}>
                <ContentZone>
                    <div style={{ float: "right", marginTop: "0", marginRight: "0" }}>
                        <Link to={URLs.library_detail + "/" + content.contentid + "/edit"} >
                            <button type="button" className="btn btn-primary mb-2 mr-2">
                                <FaEdit className="buttonicon" />
                                Edit Content
                        </button>
                        </Link>
                    </div>
                    <ReactTooltip place="top" type="light" effect="solid" id="iconsToolTip"
                        html={true} className="changetooltip"
                        getContent={(dataTip) => `${dataTip}`} />
                    <h1>Images Edition</h1>
                    <div className="d-flex flex-wrap justify-content-around">
                        {imagesJSX}
                    </div>
                    <ImageUpload contentid={content.contentid} forceRefresh={this.props.forceRefresh} />
                </ContentZone>
            </ContentPage >
        )
    }
}

class DisplayImageForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            legend: this.props.image.legend,
            openModal: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleUpdate() {
        const { image } = this.props;
        const { legend } = this.state;

        LibraryService.updateImageForItem(image.contentid, image.pos, legend).then((res) => {
            this.props.forceRefresh()
        });;
    }

    handleDelete() {
        const { image } = this.props;

        LibraryService.deleteImageForItem(image.contentid, image.pos).then((res) => {
            this.props.forceRefresh()
        });;
    }

    onOpenModal = () => {
        this.setState({ openModal: true });
    };

    onCloseModal = () => {
        this.setState({ openModal: false });
    };

    render() {

        let modalStyles = {
            modal: {
                maxWidth: "100%"
            }
        };

        const { filename, previewname } = this.props.image;

        let divClassName = "lightbeigebg";

        return (
            <div className={"rounded " + divClassName + " p-2 mx-auto mb-2"} >
                <div className="d-flex">
                    <img alt="" style={{ margin: "auto" }}
                        onClick={this.onOpenModal}
                        src={process.env.REACT_APP_LIBRARY_RES_ROOT + "img/" + previewname} />
                    <Modal open={this.state.openModal} onClose={this.onCloseModal} center styles={modalStyles}>
                        <img alt="" className="img-fluid"
                            src={process.env.REACT_APP_LIBRARY_RES_ROOT + "img/" + filename} />
                        <em><br />{this.state.legend}</em>
                    </Modal>
                </div>
                <div className="d-flex justify-content-between mt-5">
                    Legend:
                    <input
                        className="form-control form-control-sm"
                        style={{ width: "70%" }}
                        name="legend"
                        type="text"
                        value={this.state.legend}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="d-flex flex-row" style={{ marginTop: "auto", marginBottom: 2 }}>
                    <button className="btn btn-delete" style={{ marginLeft: 5, marginRight: "auto" }} onClick={this.handleDelete}>
                        <FaTrashAlt className="deleteicon" />
                    </button>
                    <button className="btn btn-primary" style={{ marginRight: 5, marginLeft: "auto" }} onClick={this.handleUpdate}>
                        Submit
                    </button>
                </div>
            </div>
        )
    }
}

class ImageUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            loaded: 0,
            legend: "",
        };
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSelectedFile(event) {
        if (event.target.files[0] !== undefined) {
            this.setState({
                selectedFile: event.target.files[0],
                selectedFileURL: URL.createObjectURL(event.target.files[0]),
                loaded: 0,
            })
        } else {
            this.setState({
                selectedFile: null,
                selectedFileURL: null,
                loaded: 0,
            })
        }
    }

    handleUpload() {
        const { contentid } = this.props;
        LibraryService.postImageForItem(contentid, this.state.selectedFile, this.state.legend).then((res) => {
            this.props.forceRefresh()
        });
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    render() {
        return (
            <div>
                <h2>Add Image</h2>
                <div className="rounded lightbeigebg p-2 m-2" >
                    <div className="d-flex justify-content-between">
                        <input type="file" onChange={this.handleSelectedFile} />
                        <div style={{ maxWidth: 400, maxHeight: 300 }}>
                            <img className="img-fluid" src={this.state.selectedFileURL} alt="" />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-5">
                        Legend:
                        <input
                            className="form-control form-control-sm"
                            style={{ width: "70%" }}
                            name="legend"
                            type="text"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="d-flex flex-row mt-2">
                        <button disabled={this.state.selectedFile === null} className="btn btn-primary" style={{ marginRight: 5, marginLeft: "auto" }} onClick={this.handleUpload}>
                            Upload
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}