import React from 'react';
import { Translate } from 'react-translated';

import "./homepage.css"
import "./HOF.css"
import imgpantheon from './img/pantheon.png'
import { LanguageContext } from '../Common/LanguageContext';
import LanguageConfig from '../Common/LanguageConfig';
import HomepageService from './HomepageService';


export default class HOF extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            hof: [],
            lastcall: null,
        };
    }

    componentDidMount() {
        this.refreshList();
        document.title = LanguageConfig.getText('site.shorttitle') + " > " + LanguageConfig.getText('menu_hof');
    }

    componentDidUpdate() {
        this.refreshList();
        document.title = LanguageConfig.getText('site.shorttitle') + " > " + LanguageConfig.getText('menu_hof');
    }

    refreshList() {
        const callkey = "hof/" + this.context.language;

        if (callkey === this.state.lastcall)
            return;

        HomepageService.getHofData(this.context.language)
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        hof: result,
                        lastcall: callkey,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        lastcall: callkey,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <HOFDisplay hof={this.state.hof} />
        )
    }
}

class HOFDisplay extends React.Component {

    componentDidMount() {
        document.body.classList.add("bodypantheon");
    }

    componentWillUnmount() {
        document.body.classList.remove("bodypantheon");
    }


    render() {
        const hof = this.props.hof;
        const hofComponents = [];

        hof.forEach(hofitem => {
            hofComponents.push(
                <HofItem hofitem={hofitem} />
            )
        });

        return (
            <div>
                <section className="page-section clearfix">
                    <div className="container">
                        <div className="intro">
                            <img className="intro-img img-fluid mb-3 mb-lg-0 rounded" src={imgpantheon} alt="" />
                            <div className="intro-text left-0 bg-faded p-5 rounded">
                                <h2 className="section-heading mb-4">
                                    <span className="section-heading-upper"></span>
                                    <span className="section-heading-lower"><Translate text="menu_hof" /></span>
                                </h2>
                                <div className="mb-3">{LanguageConfig.getHTML("hof_text")}</div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-section clearfix">
                    <div className="container">
                        <div className="intro">
                            <div className="row" style={{ paddingTop: "20px", marginLeft: "15px", marginRight: "15px" }}>
                                {hofComponents}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

class HofItem extends React.Component {
    render() {
        const hofitem = this.props.hofitem;

        const hofText = [];

        let firstLine = true;

        hofitem.text.forEach(line => {
            if (firstLine) {
                hofText.push(
                    <b>{line}</b>
                )
            } else {
                hofText.push(
                    <br />
                )
                hofText.push(
                    <span>{line}</span>
                )
            }

            firstLine = false;
        });

        return (
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 p-0">
                <div class="bg-faded hofitem rounded">
                    <div class="hofpictdiv">
                        <img src={process.env.REACT_APP_HOME_IMG_ROOT + "avatars/" + hofitem.avatar} alt={"Avatar: "+hofitem.text[0]} class="rounded hofpict" />
                    </div>

                    <p>{hofText}</p>
                </div>
            </div>
        )
    }
}