import React from 'react';

import {
    FaUser, FaCalendarAlt, FaList, FaEdit, FaSave, FaTag
} from 'react-icons/fa';

class Edit extends React.Component {
    render() {
        return (
            <FaEdit data-tip="Edit" className={this.props.className} style={this.props.style} />
        )
    }
}

class Save extends React.Component {
    render() {
        return (
            <FaSave data-tip="Save" className={this.props.className} style={this.props.style} />
        )
    }
}

class Author extends React.Component {
    render() {
        return (
            <FaUser data-tip="Submited by" className={this.props.className} style={this.props.style} />
        )
    }
}

class Version extends React.Component {
    render() {
        return (
            <FaTag data-tip="Released in Millénaire version" className={this.props.className} style={this.props.style} />
        )
    }
}

class Published extends React.Component {
    render() {
        return (
            <FaCalendarAlt data-tip="Submited On" className={this.props.className} style={this.props.style} />
        )
    }
}

class LineNumber extends React.Component {
    render() {
        return (
            <FaList data-tip="Number of Lines" className={this.props.className} style={this.props.style} />
        )
    }
}


export { Edit, Save, Author, Version, Published, LineNumber };