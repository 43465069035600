import { NotificationManager } from 'react-notifications';

import AuthService from "./AuthService";
import Translations from '../translation';
import LanguageConfig from '../Common/LanguageConfig';

class UserService {

    constructor() {
        this.cache = {}
    }

    invalidateCache(urlStartsWith) {
        const self = this;
        Object.keys(this.cache).forEach(function (key) {
            if (key.startsWith(urlStartsWith)) {
                delete self.cache[key];
            }
        });
    }

    postCreateAccount(username, password, email, captcha) {
        const url = process.env.REACT_APP_API_ROOT + "users/createaccount/";

        return AuthService.fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                username,
                password,
                email,
                captcha
            })
        }).then(res => {
            NotificationManager.success(Translations['notification.accountcreated'][LanguageConfig.language]);
            AuthService.setToken(res.token);
            return res
        });
    }

    getUsersList() {
        const url = process.env.REACT_APP_API_ROOT + "users/";

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    invalidateCacheUsersList() {
        this.invalidateCache(process.env.REACT_APP_API_ROOT + "users/");
    }

    updateUserRole(id, accountlevel) {
        const url = process.env.REACT_APP_API_ROOT + "users/" + id;

        return AuthService.fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                accountlevel
            })
        }).then(res => {
            NotificationManager.success(Translations['notification.userupdated'][LanguageConfig.language]);

            return res;
        });
    }

    changePassword(id, password) {
        const url = process.env.REACT_APP_API_ROOT + "users/" + id + "/password";

        return AuthService.fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                password
            })
        }).then(res => {
            NotificationManager.success(Translations['notification.passwordchanged'][LanguageConfig.language]);

            return res;
        });
    }

    resetPassword(email, key, password) {
        const url = process.env.REACT_APP_API_ROOT + "users/resetpassword";

        return AuthService.fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                email, key, password
            })
        }).then(res => {
            NotificationManager.success(Translations['notification.passwordchanged'][LanguageConfig.language]);

            return res;
        });
    }

    requestNewPassword(email) {
        const url = process.env.REACT_APP_API_ROOT + "users/requestnewpassword";
        const language = LanguageConfig.language;

        return AuthService.fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                email, language
            })
        }).then(res => {
            NotificationManager.success(Translations['notification.passwordrequestsent'][LanguageConfig.language]);

            return res;
        });
    }
}



const instance = new UserService();
Object.freeze(instance);

export default instance;