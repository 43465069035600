import UserTranslations from './Users/translations_users'
import HomeTranslations from './Home/translations_home'

export default {
  ...UserTranslations,
  ...HomeTranslations,
  'site.title': {
    en: 'Millénaire, the historical Minecraft Village Mod',
    fr: 'Millénaire, le mod Minecraft de villages historiques',
  },
  'site.shorttitle': {
    en: 'Millénaire',
    fr: 'Millénaire',
  },
  'encyclopedia.desc': {
    en: 'The Millénaire Encyclopedia describes Millénaire\'s cultures, and specifically all of their buildings, villagers, village types and traded goods. It contains the information from the in-game "Travel Book" and added wiki-style descriptions and images. You can contribute to it by registering a free account.',
    fr: 'L\'Encyclopédie Millénaire décrit les cultures de Millénaire, et notamment tous leurs bâtiments, villageois, villages et marchandises. Elle contient toutes les informations du "carnet de voyage" ainsi que des descriptions et images ajoutées. Vous pouvez y contribuer en créant un compte gratuit.',
  },
  'encyclopedia.worldmap': {
    en: 'The Millénaire world',
    fr: 'Le monde de Millénaire',
  },
  'culture.villagelist': {
    en: 'Villages',
    fr: 'Villages',
  },
  'culture.villagerlist': {
    en: 'Villagers',
    fr: 'Villageois',
  },
  'culture.buildinglist': {
    en: 'Buildings',
    fr: 'Bâtiments',
  },
  'culture.tradegoodlist': {
    en: 'Trade Goods',
    fr: 'Marchandises',
  },

  'culture.villages': {
    en: 'Villages',
    fr: 'Villages',
  },
  'culture.villagers': {
    en: 'Villagers',
    fr: 'Villageois',
  },
  'culture.buildings': {
    en: 'Buildings',
    fr: 'Bâtiments',
  },
  'culture.tradegoods': {
    en: 'Trade Goods',
    fr: 'Marchandises',
  },
  'culture.cultures': {
    en: 'Culture',
    fr: 'Culture',
  },

  'button.return': {
    en: 'Back',
    fr: 'Retour',
  },
  'button.home': {
    en: 'Home',
    fr: 'Accueil',
  },
  'encyclopedia.search': {
    en: 'Search the Encyclopedia',
    fr: 'Chercher dans l\'encyclopédie',
  },
  'button.encyclopedia_home': {
    en: 'Encyclopedia Home',
    fr: 'Accueil de L\'encylcopédie',
  },
  'button.library_home': {
    en: 'Library Home',
    fr: 'Accueil de la librairie',
  },
  'culture.view': {
    en: 'View Culture',
    fr: 'Voir la culture',
  },
  'content.editedby': {
    en: 'Edited by: ',
    fr: 'Edité par : '
  },
  'travelbook.quote': {
    en: 'The Travel Book',
    fr: 'Le carnet de voyage'
  },
  'content.edit': {
    en: 'Edit',
    fr: 'Editer'
  },
  'contentedit.title': {
    en: 'Edit the extended description',
    fr: 'Modifier la description étendue'
  },
  'contentedit.desc': {
    en: 'If left empty, the travel book description will be used instead.',
    fr: 'Si elle est laissée vide, la description du carnet de voyage sera utilisée à la place.'
  },
  'contentedit.removedesc': {
    en: 'Remove the description',
    fr: 'Supprimer la description'
  },
  'contentedit.submit': {
    en: 'Save',
    fr: 'Enregistrer'
  },
  'contentedit.delete': {
    en: 'Delete',
    fr: 'Effacer'
  },
  'contentedit.history': {
    en: 'History',
    fr: 'Historique'
  },
  'contentedit.restore': {
    en: 'Restore',
    fr: 'Restorer'
  },
  'contentedit.nocontent': {
    en: 'No content present.',
    fr: 'Pas de contenu présent.'
  },
  'contentedit.show': {
    en: 'Show',
    fr: 'Afficher'
  },
  'contentedit.hide': {
    en: 'Hide',
    fr: 'Cacher'
  },
  'contentedit.images': {
    en: 'Images',
    fr: 'Images'
  },
  'contentedit.encyclopedialink': {
    en: 'Link to Encyclopedia content',
    fr: 'Lien vers une page de l\'encyclopédie'
  },
  'contentedit.addimage': {
    en: 'Upload a new image',
    fr: 'Charger une nouvelle image'
  },
  'contentedit.legend_en': {
    en: 'Caption in English:',
    fr: 'Légende en anglais :'
  },
  'contentedit.legend_fr': {
    en: 'Caption in French:',
    fr: 'Légende en français :'
  },
  'contentedit.enabled': {
    en: 'Enabled',
    fr: 'Activée'
  },
  'contentedit.upload': {
    en: 'Upload',
    fr: 'Charger'
  },
  'menu_submenu': {
    en: 'Millénaire Menu',
    fr: 'Menu Millénaire'
  },
  'header.homepage': {
    en: 'Homepage & downloads',
    fr: 'Page d\'accueil & téléchargements'
  },
  'header.wiki': {
    en: 'Player-made Wiki',
    fr: 'Wiki collaborative'
  },
  'header.forum': {
    en: 'Millénaire forum',
    fr: 'Forum Millénaire'
  },
  'header.library': {
    en: 'Millénaire Library',
    fr: 'Librairie Millénaire'
  },
  'header.encyclopedia': {
    en: 'Millénaire Encyclopedia',
    fr: 'Encyclopédie Millénaire'
  },
  'header.translationcentre': {
    en: 'Translation Centre',
    fr: 'Centre de traduction'
  },
  'header.discord': {
    en: 'Millénaire Discord',
    fr: 'Discord Millénaire'
  },
  'newcontentslist.title': {
    'en': 'Recent Content Changes',
    'fr': 'Changements de contenus récents'
  },
  'newimageslist.title': {
    'en': 'New Images',
    'fr': 'Nouvelles Images'
  },
  'data.refresh': {
    'en': 'Refresh',
    'fr': 'Rafraichir'
  },
  'encyclopedia.addcontentdesc': {
    'en': 'This page has no user-written description. You can add one!',
    'fr': 'Cette page n\' a pas de description. Vous pouvez en ajouter une !'
  },
  'encyclopedia.createaccountdesc': {
    'en': 'This page has no user-written description. You can create an account and add one!',
    'fr': 'Cette page n\' a pas de description. Vous pouvez vous créer un compte et en ajouter une !'
  },
  'button.createaccount': {
    en: 'Create Account',
    fr: 'Créer un compte',
  },
  'button.editcontent': {
    en: 'Edit Content',
    fr: 'Editer le contenu',
  },
  'notification.logout': {
    en: 'You have been logged out.',
    fr: 'Vous avez été déconnecté.'
  },
  'notification.login': {
    en: 'You are now logged in.',
    fr: 'Vous êtes maintenant connecté.'
  },
  'notification.accountcreated': {
    en: 'Your account has been created.',
    fr: 'Votre compte a été créé.'
  },
  'notification.contentmodified': {
    en: 'Content updated.',
    fr: 'Modifications enregistrées.'
  },
  'notification.imageadded': {
    en: 'Image added.',
    fr: 'Image ajoutée.'
  },
  'notification.imageupdated': {
    en: 'Image updated.',
    fr: 'Image mise à jour.'
  },
  'notification.imagedeleted': {
    en: 'Image deleted.',
    fr: 'Image supprimée.'
  },
  'UNKNOWN_ERROR': {
    en: 'An unknown error has occured.',
    fr: 'Une erreur inconnue a eu lieu.'
  },
  'UNAUTHORIZED': {
    en: 'You are not authorized to do this.',
    fr: 'Vous n\'êtes pas authorisé à réaliser cette action.'
  },
}