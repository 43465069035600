import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Cookies from 'universal-cookie';

import "react-toggle/style.css";
import ReactToggle from 'react-toggle';

import { MessageZone, ContentPage } from '../Common/CommonComponents';
import { LanguageContext } from '../Common/LanguageContext';
import TranslationService from './TranslationService';
import TranslationFileInfo from './TranslationFileInfo';

import * as URLs from "../URLS";
import { FaFileDownload, FaExclamationTriangle, FaCheck } from 'react-icons/fa';

const cookies = new Cookies();

export default class TranslationHome extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            referenceFiles: [],
            referenceFilesLastcall: null,
            referenceLanguage: cookies.get('reference_language') || 'en',
            translationFiles: [],
            translationLastcall: null,
            translationLanguage: cookies.get('translation_language') || 'fr',
            languagues: [],
            languaguesLastcall: null,
            hideCompleteFiles: false
        };
        this.toggleHideCompleteFiles = this.toggleHideCompleteFiles.bind(this);
    }

    componentDidMount() {
        this.refreshListReference();
        this.refreshListTranslation();
        this.refreshListLanguages();
    }

    componentDidUpdate() {
        this.refreshListReference();
        this.refreshListTranslation();
        this.refreshListLanguages();
    }

    refreshListLanguages() {
        const callkey = "languages/" + this.context.language;

        if (callkey === this.state.languaguesLastcall)
            return;

        TranslationService.getTranslationList(this.state.languaguesLastcall)
            .then(
                (result) => {
                    this.setState({
                        languagues: result,
                        languaguesLastcall: callkey,
                    });
                },
                (error) => {
                    this.setState({
                        languagues: callkey,
                        error
                    });
                }
            )
    }

    refreshListReference() {
        const callkey = "reference/" + this.state.referenceLanguage;

        if (callkey === this.state.referenceFilesLastcall)
            return;

        TranslationService.getLatestFilesList(this.state.referenceLanguage)
            .then(
                (result) => {
                    this.setState({
                        referenceFiles: result,
                        referenceFilesLastcall: callkey,
                    });
                    ReactTooltip.rebuild();
                },
                (error) => {
                    this.setState({
                        referenceFilesLastcall: callkey,
                        error
                    });
                }
            )
    }

    refreshListTranslation() {
        const callkey = "translation/" + this.state.translationLanguage;

        if (callkey === this.state.translationFilesLastcall)
            return;

        TranslationService.getLatestFilesList(this.state.translationLanguage)
            .then(
                (result) => {
                    this.setState({
                        translationFiles: result,
                        translationFilesLastcall: callkey,
                    });
                    ReactTooltip.rebuild();
                },
                (error) => {
                    this.setState({
                        translationFilesLastcall: callkey,
                        error
                    });
                }
            )
    }

    updateReferenceLanguage(language) {
        this.setState({
            referenceLanguage: language,
        })
        cookies.set('reference_language', language, { path: process.env.REACT_APP_COOKIE_ROOT });
    }

    updateTranslationLanguage(language) {
        this.setState({
            translationLanguage: language,
        })
        cookies.set('translation_language', language, { path: process.env.REACT_APP_COOKIE_ROOT });
    }

    toggleHideCompleteFiles() {
        this.setState({
            hideCompleteFiles: !this.state.hideCompleteFiles,
        })
    }

    render() {

        const { error, referenceFiles, translationFiles, referenceLanguage, translationLanguage, languagues, hideCompleteFiles } = this.state;

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else {

            const languageOptions = [];
            languagues.forEach((language) => {
                languageOptions.push(
                    <option value={language.lang} key={language.lang}>{language.name_native + " (" + language.name + ")"}</option>
                )
            })

            const rows = [];

            let referenceElementsCount = 0;
            let translationElementsCount = 0;

            referenceFiles.forEach((referenceFile) => {

                referenceElementsCount += referenceFile.elementcount;

                let translationFile = null;
                translationFiles.forEach((aTranslationFile) => {
                    if (aTranslationFile.filekey === referenceFile.filekey) {
                        translationFile = aTranslationFile;
                    }
                })

                if (translationFile !== null) {
                    translationElementsCount += translationFile.elementcount;
                }

                let showCard = !hideCompleteFiles;

                if (!showCard) {
                    if (translationFile == null || translationFile.elementcount !== referenceFile.elementcount) {
                        showCard = true;
                    }
                }

                if (showCard) {
                    rows.push(
                        <TranslationFileCard referenceLanguage={referenceLanguage} translationLanguage={translationLanguage}
                            referenceFile={referenceFile} translationFile={translationFile}
                            key={referenceFile.filekey} />
                    )
                }
            });

            let percent = 0;

            if (referenceElementsCount > 0)
                percent = Math.round(translationElementsCount / referenceElementsCount * 100);

            let percentColour = "#c5451c";

            if (percent > 99)
                percentColour = "#28a745";
            else if (percent > 50)
                percentColour = "#2819dc";

            return (
                <ContentPage>
                    <div className="row">
                        <div className="col-12 mb-4 d-block">
                            <div className="bg-faded p-5 rounded">
                                <ReactTooltip place="top" type="light" effect="solid" />
                                <div className="millenaire-header mb-4">
                                    Translation Centre
                                </div>
                                <p>The following site can be used to translate Millénaire to new languages! You need to create an account and ask for it be confirmed by sending an email to <a href="mailto:kinniken@millenaire.org">me</a> or to <a href="mailto:Orange1861.65@gmail.com">Orange1861.65@gmail.com</a> to help. Files on this site can be updated either when a new Millénaire version is released with changes (in which case they have a "version" tag) or by edition them on the site. Orange1861 is in charge of coordinating translations and can answer your questions on this topic by mail or on the Discord channel.</p>
                                <div className="millenaire-subheader-responsive mb-2 mt-2">
                                    See recent changes
                                    </div>
                                <div>Recent changes to the files can be view <Link to={URLs.translationcentre_recentchanges}>on the recent changes page</Link>.</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-4 d-block">
                            <div className="bg-faded p-3 rounded" style={{ minHeight: "50px" }}>
                                <div style={{ float: "right", color: percentColour, fontSize: "40px", marginTop: "-15px" }} data-tip="Translation Completion">
                                    {percent}%
                                    </div>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div className="mr-2">Reference language: <select
                                        style={{ width: "200px" }}
                                        value={referenceLanguage}
                                        onChange={event => this.updateReferenceLanguage(event.target.value)}
                                    >
                                        {languageOptions}
                                    </select>
                                    </div>
                                    <div>Translation language: <select
                                        style={{ width: "200px" }}
                                        value={translationLanguage}
                                        onChange={event => this.updateTranslationLanguage(event.target.value)}
                                    >
                                        {languageOptions}
                                    </select>
                                    </div>
                                    <div>
                                        <div style={{ display: "flex" }}>
                                            <div className="ml-2">
                                                <ReactToggle
                                                    checked={this.state.hideCompleteFiles}
                                                    onChange={this.toggleHideCompleteFiles}
                                                />
                                            </div>
                                            <span style={{ marginLeft: "5px" }}>
                                                Only display incomplete files
                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {rows}
                    </div>

                    <DownloadButtons referenceLanguage={referenceLanguage} translationLanguage={translationLanguage} />
                </ContentPage>
            );
        }
    }
}

class TranslationFileCard extends React.Component {
    render() {
        const { referenceFile, translationFile, translationLanguage, referenceLanguage } = this.props;

        let unfinished = false;
        let missing = false;
        let percent = 0;

        if (translationFile == null) {
            missing = true;
        } else if (translationFile.elementcount !== referenceFile.elementcount) {
            unfinished = true;
            percent = Math.round(translationFile.elementcount / referenceFile.elementcount * 100);
        }

        return (
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                <div className="bg-faded p-3 rounded" align="center">
                    <div className="mb-4" style={{ width: "100%" }}>
                        {missing &&
                            <div style={{ float: "right" }}>
                                <FaExclamationTriangle style={{ color: "#c5451c", fontSize: "40px" }} data-tip="This file does not exist yet in the selected language" />
                            </div>
                        }
                        {unfinished &&
                            <div style={{ float: "right" }}>
                                <span style={{ color: "#2819dc", fontSize: "40px" }} data-tip="This file is in progress in the selected language">{percent}%</span>
                            </div>
                        }
                        {!unfinished && !missing &&
                            <div style={{ float: "right" }}>
                                <FaCheck style={{ color: "#28a745", fontSize: "40px" }} data-tip="This file is complete in the selected language" />
                            </div>
                        }
                        <span className="millenaire-subheader-responsive vertical-align">
                            <Link to={URLs.translationcentre_files + "/" + referenceFile.filekey + "/" + referenceLanguage + "/" + translationLanguage}>
                                {referenceFile.filekey}
                            </Link>
                        </span>

                    </div>
                    <TranslationFileInfo className="m-2" file={referenceFile} language={referenceLanguage} />
                    <TranslationFileInfo className="m-2" file={translationFile} language={translationLanguage} />
                </div>
            </div>
        );
    }
}

class DownloadButtons extends React.Component {
    render() {
        const { translationLanguage, referenceLanguage } = this.props;

        return (
            <div className="row">
                <div className="col-12 mb-4 d-block">
                    <div className="bg-faded p-3 rounded">
                        <div className="millenaire-subheader-responsive mb-2">
                            Download the current language files
                                    </div>
                        <p>You can use the downloaded zip in your Millénaire install by placing them in millenaire/languages/ for testing purposes.</p>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <button className="btn btn-primary"
                                onClick={() => { window.location = process.env.REACT_APP_API_ROOT + "/translationsapp/archives/" + referenceLanguage; }}>
                                <FaFileDownload className="buttonicon" />
                                Download {referenceLanguage} files
                                        </button>
                            <button className="btn btn-primary"
                                onClick={() => { window.location = process.env.REACT_APP_API_ROOT + "/translationsapp/archives/" + translationLanguage; }}>
                                <FaFileDownload className="buttonicon" />
                                Download {translationLanguage} files
                                        </button>
                            <button className="btn btn-primary"
                                onClick={() => { window.location = process.env.REACT_APP_API_ROOT + "/translationsapp/archives/"; }}>
                                <FaFileDownload className="buttonicon" />
                                Download all files
                                        </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}