import React from 'react';
import { Translate } from 'react-translated';
import { Redirect } from 'react-router-dom'

import * as URLs from '../URLS';

import "./homepage.css"
import "./advancements.css"
import imgadvancement from './img/advancements.png'
import { LanguageContext } from '../Common/LanguageContext';
import LanguageConfig from '../Common/LanguageConfig';
import HomepageService from './HomepageService';

export default class Advancements extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isLoadedPlayerStats: false,
            advancements: [],
            playerstats: null,
            lastcall: null,
            lastcallPlayerStats: null
        };
    }

    refreshPage() {
        this.refreshList()
        this.refreshPlayerStats()
        const player = this.props.match.params.player;

        if (player === undefined)
            document.title = LanguageConfig.getText('site.shorttitle') + " > " + LanguageConfig.getText('menu_advancements');
        else
            document.title = LanguageConfig.getText('site.shorttitle') + " > " + LanguageConfig.getText('menu_advancements')
                + " > " + player;
    }

    componentDidMount() {
        this.refreshPage();
    }

    componentDidUpdate() {
        this.refreshPage();
    }

    refreshList() {
        const callkey = "advancements/" + this.context.language;

        if (callkey === this.state.lastcall)
            return;

        HomepageService.getAdvancementsList(this.context.language)
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        advancements: result,
                        lastcall: callkey,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        lastcall: callkey,
                        error
                    });
                }
            )
    }

    refreshPlayerStats() {
        const player = this.props.match.params.player;

        const callkey = "advancements/" + player + "/" + this.context.language;

        if (callkey === this.state.lastcallPlayerStats)
            return;

        if (player === undefined) {
            this.setState({
                isLoadedPlayerStats: true,
                playerstats: [],
                lastcallPlayerStats: callkey,
            });
            return;
        }

        HomepageService.getPlayerAdvancementsStat(player)
            .then(
                (result) => {
                    this.setState({
                        isLoadedPlayerStats: true,
                        playerstats: result,
                        lastcallPlayerStats: callkey,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoadedPlayerStats: true,
                        lastcallPlayerStats: callkey,
                        error
                    });
                }
            )
    }

    render() {
        const player = this.props.match.params.player;

        return (
            <AdvancementsDisplay location={this.props.location}
                advancements={this.state.advancements} playerstats={this.state.playerstats} player={player} />
        )
    }
}

class AdvancementsDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showall: false,
            loginTyped: "",
            redirectLogin: null
        };
        this.enableShowAll = this.enableShowAll.bind(this);
        this.disableShowAll = this.disableShowAll.bind(this);
        this.changeLoginViewed = this.changeLoginViewed.bind(this);
        this.changeLoginTyped = this.changeLoginTyped.bind(this);
    }

    changeLoginViewed() {
        this.setState({
            redirectLogin: this.state.loginTyped
        })
    }

    changeLoginTyped(e) {
        this.setState({
            loginTyped: e.target.value
        })
    }

    enableShowAll() {
        this.setState({
            showall: true
        })
    }

    disableShowAll() {
        this.setState({
            showall: false
        })
    }

    render() {

        if (this.state.redirectLogin !== null) {
            const targetURL = URLs.home_advancements + "/" + this.state.redirectLogin;

            if (this.props.location.pathname !== targetURL)
                return <Redirect push to={targetURL} />;
        }

        const player = this.props.player;
        const playerstats = this.props.playerstats;

        const advancementsComponents = [];
        let lastUpdate = null;

        this.props.advancements.forEach(advancement => {

            let playerCompleteSurvival = false;
            let playerCompleteAll = false;

            if (playerstats !== null && playerstats[advancement.advancement_key] !== undefined) {
                playerCompleteSurvival = (playerstats[advancement.advancement_key].survival === '1');
                playerCompleteAll = (playerstats[advancement.advancement_key].creative === '1');
            }

            advancementsComponents.push(
                <Advancement advancement={advancement} showall={this.state.showall} key={advancement.advancement_key}
                    playerCompleteSurvival={playerCompleteSurvival} playerCompleteAll={playerCompleteAll} />
            )

            // Take any advancement update date as the one for the page
            lastUpdate = advancement.last_count_updated;
        });

        let playerstatscount = 0;
        if (playerstats !== null)
            playerstatscount = Object.keys(playerstats).length;

        return (
            <div>
                <section className="page-section clearfix">

                    <div className="container" id="advancementintro">
                        <div className="intro">
                            <img className="intro-img img-fluid mb-3 mb-lg-0 rounded" src={imgadvancement} alt="" />
                            <div className="intro-text left-0 bg-faded p-5 rounded">
                                <h2 className="section-heading mb-4">
                                    <span className="section-heading-upper"></span>
                                    <span className="section-heading-lower"><Translate text="menu_advancements" /></span>
                                </h2>
                                <div className="mb-3">{LanguageConfig.getHTML("mainadvancements_text")}
                                </div>
                                {player !== undefined && playerstats !== null &&
                                    <div className="mb-3">
                                        {playerstatscount > 0 && LanguageConfig.getHTML("advancements_loginfound", [{ key: "*login*", value: player }])}
                                        {playerstatscount === 0 && LanguageConfig.getHTML("advancements_loginnotfound", [{ key: "*login*", value: player }])}
                                    </div>
                                }
                                <p>
                                    <Translate text="advancementsenterlogin_text" />
                                    <input value={this.state.loginTyped} onChange={this.changeLoginTyped} style={{ marginLeft: "5px" }} />
                                    <button onClick={this.changeLoginViewed}>
                                        <Translate text="advancementsenterloginbutton_text" />
                                    </button>
                                </p>
                                <form action="">
                                    <input type="radio" checked={!this.state.showall}
                                        onChange={this.disableShowAll}
                                    />
                                    <Translate text="advancements_survival" />
                                    <br />
                                    <input type="radio" checked={this.state.showall}
                                        onChange={this.enableShowAll}
                                    />
                                    <Translate text="advancements_all" />
                                </form>
                                {LanguageConfig.getHTML("advancementsupdated_text", [{ key: "*advancements_date*", value: lastUpdate }])}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="page-section clearfix">
                    <div className="container">
                        <div className="intro">
                            <div className="row" style={{ paddingTop: "20px", marginLeft: "15px", marginRight: "15px" }}>
                                {advancementsComponents}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

class Advancement extends React.Component {
    render() {
        const { advancement, showall, playerCompleteSurvival, playerCompleteAll } = this.props;

        const showcomplete = playerCompleteSurvival || (showall && playerCompleteAll);

        let percent_survival = advancement.done_survival * 100 / advancement.total_survival;

        if (percent_survival > 10) {
            percent_survival = Math.round(percent_survival * 10) / 10;
        } else if (percent_survival > 0.01) {
            percent_survival = Math.round(percent_survival * 100) / 100;
        } else if (percent_survival > 0) {
            percent_survival = 0.01;
        }

        let percent_all = advancement.done_all * 100 / advancement.total_all;

        if (percent_all > 10) {
            percent_all = Math.round(percent_all * 10) / 10;
        } else if (percent_all > 0.01) {
            percent_all = Math.round(percent_all * 100) / 100;
        } else if (percent_all > 0) {
            percent_all = 0.01;
        }

        return (
            <div className="col-12 p-0">
                <div className={"advancement rounded" + (showcomplete ? " advancementcomplete" : "")}>
                    <div className="advancementpictdiv">
                        <img src={process.env.REACT_APP_HOME_IMG_ROOT + "advancements/" + advancement.advancement_key + '.png'}
                            className="rounded advancementpict" width="64" height="64" alt=""
                            title={LanguageConfig.getText("advancements_addedin") + " " + advancement.version} />
                    </div>
                    <p style={{ marginBottom: 0 }}><b>{advancement.title}</b>
                        <br />{advancement.description}
                    </p>

                    <div className="advancementpercent advancementsurvival">{percent_survival}%
                            <span className="advancementall" style={{ display: showall ? "" : "none", color: "#676363" }}><em> / {percent_all}%</em></span>
                    </div>

                    <div className="rounded advancementprogress advancementsurvival" style={{ width: percent_survival + "%" }}></div>
                    <div className="rounded advancementprogressall advancementall" style={{ display: showall ? "" : "none", width: percent_all + "%" }}></div>
                </div>
            </div>
        )
    }
}