import React from 'react';
import { Link } from 'react-router-dom'
import { Translate } from 'react-translated';
import { FaUser } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';

import AuthService from './AuthService';

import * as URLs from '../URLS'

export default class UserMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
        };
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    toggleCollapse() {
        this.setState({ collapsed: !this.state.collapsed });
    }

    render() {

        let displayMenuClass = "";

        if (!this.state.collapsed)
            displayMenuClass = "show";

        return (
            <div>
                <Dropdown pullRight
                    className={"topheaderbutton rounded"}
                    id={`usermenu`}
                >
                    <Dropdown.Toggle onClick={() => { this.toggleCollapse() }}>
                        <FaUser className="activeicon" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={displayMenuClass}>
                        {!AuthService.loggedIn() &&
                            <li><Link to={URLs.user_login} onClick={this.toggleCollapse} >
                                <Translate text="login.login" />
                            </Link>
                            </li>
                        }
                        {!AuthService.loggedIn() &&
                            <li>
                                <Link to={URLs.user_createaccount} onClick={this.toggleCollapse} >
                                    <Translate text="createaccount.title" />
                                </Link>
                            </li>
                        }
                        {AuthService.loggedIn() &&
                            <li>
                                {AuthService.getProfile().login + " (" + AuthService.getProfile().role + ")"}
                            </li>
                        }
                        {AuthService.loggedIn() &&
                            <li><Link to={URLs.user_logout} onClick={this.toggleCollapse} >
                                <Translate text="logout.logout" />
                            </Link></li>
                        }
                        {AuthService.loggedIn() &&
                            <li>
                                <Link to={URLs.user_passwordchange} onClick={this.toggleCollapse}>
                                    <Translate text="passwordchange.title" />
                                </Link>
                            </li>
                        }
                        {AuthService.isUserAdmin() &&
                            <li><Link to={URLs.user_admin} onClick={this.toggleCollapse} >
                                <b><Translate text="useradmin.title" /></b>
                            </Link>
                            </li>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }
}

