import React from 'react';

import './library.css';
import './pagination.css';
import * as Icons from './ContentIcons';

export default class ContentFilterAndSorts extends React.Component {
    render() {
        const categoriesOptions = [];
        this.props.categories.forEach((category) => {
            categoriesOptions.push(
                <option value={category.id} key={category.id}>{category.label}</option>
            )
        })

        const culturesOptions = [];
        this.props.cultures.forEach((culture) => {
            culturesOptions.push(
                <option value={culture.id} key={culture.id}>{culture.label}</option>
            )
        })

        return (
            <div className="col-12 mb-4">
                <div className="bg-faded p-3 rounded" align="center">
                    <div style={{ margin: "auto", display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                        <div style={{ marginRight: "5px" }}>
                            <Icons.Category />
                            <select
                                style={{ width: "200px" }}
                                onChange={event => this.props.listActions.updateSelectedCategory(event.target.value)}
                                value={this.props.selectedCategory ? this.props.selectedCategory : "all"}
                            >
                                <option value="all">Show All</option>
                                {categoriesOptions}
                            </select>
                        </div>
                        <div style={{ marginRight: "5px" }}>
                            <Icons.Culture />
                            <select
                                style={{ width: "200px" }}
                                onChange={event => this.props.listActions.updateSelectedCulture(event.target.value)}
                                value={this.props.selectedCulture ? this.props.selectedCulture : "all"}
                            >
                                <option value="all">Show All</option>
                                {culturesOptions}
                            </select>
                        </div>
                        <div style={{ marginRight: "5px" }}>
                            <Icons.Sort />
                            <select
                                style={{ width: "200px" }}
                                onChange={event => this.props.listActions.updateSortBy(event.target.value)}
                                value={this.props.sortBy ? this.props.sortBy : "nbdl"}
                            >
                                <option value="nbdl">Most Downloaded</option>
                                <option value="nbview">Most Viewed</option>
                                <option value="recent">Most Recent</option>
                                <option value="nbstars">Most Stars</option>
                                <option value="oldest">Oldest</option>
                            </select>
                        </div>
                        <div style={{ marginRight: "5px" }}>
                            <Icons.Search />
                            <input value={this.props.searchFor}
                                type="text"
                                onChange={event => this.props.listActions.updateSearchFor(event.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}