import React from 'react';

import './library.css'
import { FaUser, FaFolderOpen, FaLandmark, FaTag, 
    FaEye, FaDownload, FaCalendarAlt, FaSortAmountDown, FaSearch, FaStar, FaComments } from 'react-icons/fa';


class Author extends React.Component {
    render() {
        return (
            <FaUser className="cardicon" data-for="iconsToolTip" data-tip="Author" />
        )
    }
}

class Category extends React.Component {
    render() {
        return (
            <FaFolderOpen className="cardicon" data-for="iconsToolTip" data-tip="Category" />
        )
    }
}

class Culture extends React.Component {
    render() {
        return (
            <FaLandmark className="cardicon" data-for="iconsToolTip" data-tip="Culture" />
        )
    }
}

class Version extends React.Component {
    render() {
        return (
            <FaTag className="cardicon" data-for="iconsToolTip" data-tip="Version" />
        )
    }
}

class Views extends React.Component {
    render() {
        return (
            <FaEye className="cardicon" data-for="iconsToolTip" data-tip="Views" />
        )
    }
}

class Downloads extends React.Component {
    render() {
        return (
            <FaDownload className="cardicon" data-for="iconsToolTip" data-tip="Downloads" />
        )
    }
}

class Stars extends React.Component {
    render() {
        return (
            <FaStar className="cardicon" data-for="iconsToolTip" data-tip="Stars" />
        )
    }
}

class Comments extends React.Component {
    render() {
        return (
            <FaComments className="cardicon" data-for="iconsToolTip" data-tip="Comments" />
        )
    }
}

class LastUpdated extends React.Component {
    render() {
        return (
            <FaCalendarAlt className="cardicon" data-for="iconsToolTip" data-tip="Last Updated On" />
        )
    }
}

class Sort extends React.Component {
    render() {
        return (
            <FaSortAmountDown className="cardicon" data-for="iconsToolTip" data-tip="Sort By" />
        )
    }
}

class Search extends React.Component {
    render() {
        return (
            <FaSearch className="cardicon" data-for="iconsToolTip" data-tip="Search" />
        )
    }
}

export { Author, Category, Culture, Version, Views, Downloads, LastUpdated, Sort, Search, Stars, Comments };