import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Cookies from 'universal-cookie';

import { MessageZone, ContentPage } from '../Common/CommonComponents';
import { LanguageContext } from '../Common/LanguageContext';
import TranslationService from './TranslationService';

import * as URLs from "../URLS";

const cookies = new Cookies();

export default class TranslationRecentChanges extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            recentChanges: [],
            lastCall: null
        };
    }

    componentDidMount() {
        this.refreshRecentChanges();
    }

    componentDidUpdate() {
        this.refreshRecentChanges();
    }

    refreshRecentChanges() {
        const callkey = "recentChanges/";

        if (callkey === this.state.lastCall)
            return;

        TranslationService.getRecentChanges()
            .then(
                (result) => {
                    this.setState({
                        recentChanges: result,
                        lastCall: callkey,
                    });
                    ReactTooltip.rebuild();
                },
                (error) => {
                    this.setState({
                        lastCall: callkey,
                        error
                    });
                }
            )
    }

    render() {

        const { error, recentChanges } = this.state;

        const referenceLanguage = cookies.get('reference_language') || "en";

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else {

            const languages = [...new Set(recentChanges.map(item => item.language))].sort();
            const languagesHeader = [];
            languages.forEach(language => (
                languagesHeader.push(
                    <option value={language}>{language}</option>
                )
            ))

            const files = [...new Set(recentChanges.map(item => item.filekey))].sort();
            const filesHeader = [];
            files.forEach(item => (
                filesHeader.push(
                    <option value={item}>{item}</option>
                )
            ))

            const versions = [...new Set(recentChanges.map(item => item.version))].sort();
            const versionsHeader = [];
            versions.forEach(item => {
                if (item !== null)
                    versionsHeader.push(
                        <option value={item}>{item}</option>
                    )
            })

            const authors = [...new Set(recentChanges.map(item => item.author))].sort();
            const authorsHeader = [];
            authors.forEach((item) => {
                if (item !== null)
                    authorsHeader.push(
                        <option value={item}>{item}</option>
                    )
            })

            const columns = [{
                Header: 'Language',
                accessor: 'language',
                Filter: ({ filter, onChange }) =>
                    (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                        >
                            <option value="all">Show All</option>
                            {languagesHeader}
                        </select>
                    ),
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    } else {
                        return row[filter.id] === filter.value;
                    }
                },
            }, {
                Header: 'File',
                accessor: 'filekey',
                Filter: ({ filter, onChange }) =>
                    (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                        >
                            <option value="all">Show All</option>
                            {filesHeader}
                        </select>
                    ),
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    } else {
                        return row[filter.id] === filter.value;
                    }
                },
                Cell: row => (
                    <span><Link to={URLs.translationcentre_files + "/" + row.value + "/" + referenceLanguage + "/" + row.original.language}>{row.value}</Link></span>
                ),
            }, {
                Header: 'Millénaire Version',
                accessor: 'version',
                Filter: ({ filter, onChange }) =>
                    (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                        >
                            <option value="all">Show All</option>
                            <option value="none">No version</option>
                            {versionsHeader}
                        </select>
                    ),
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    } else if (filter.value === "none") {
                        return row[filter.id] === null;
                    } else {
                        return row[filter.id] === filter.value;
                    }
                },
            }, {
                Header: 'Author',
                accessor: 'author',
                Filter: ({ filter, onChange }) =>
                    (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                        >
                            <option value="all">Show All</option>
                            <option value="none">No author</option>
                            {authorsHeader}
                        </select>
                    ),
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    } else if (filter.value === "none") {
                        return row[filter.id] === null;
                    } else {
                        return row[filter.id] === filter.value;
                    }
                },
            }, {
                Header: 'Elements Nb',
                accessor: 'elementcount',
                filterable: false,
            }, {
                Header: 'Operation',
                id: 'operation',
                accessor: d => d.initialimport ? "Creation" : "Modification",
                Filter: ({ filter, onChange }) =>
                    (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                        >
                            <option value="all">All</option>
                            <option value="Creation">Creation</option>
                            <option value="Modification">Modification</option>
                        </select>
                    ),
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    } else {
                        return row[filter.id] === filter.value;
                    }
                },
            }, {
                Header: 'Created On',
                accessor: 'createdon',
                Cell: row => (
                    <span>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(Date.parse(row.value))}</span>
                ),
                filterable: false,
            }
            ]

            return (
                <ContentPage>
                    <div className="row">
                        <div className="col-12 mb-4 d-block">
                            <div className="bg-faded p-5 rounded">
                                <ReactTooltip place="top" type="light" effect="solid" />
                                <div className="millenaire-header mb-4">
                                    Recent Changes (last 500)
                                </div>
                                <ReactTable className="-striped -highlight"
                                    data={recentChanges}
                                    columns={columns}
                                    filterable={true}
                                />
                            </div>
                        </div>
                    </div>
                </ContentPage>
            );
        }
    }
}