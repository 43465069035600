import React from 'react';

import './library.css';
import './pagination.css';
import ReactTooltip from 'react-tooltip';

import AuthService from '../Users/AuthService';
import { MessageZone, ContentPage } from '../Common/CommonComponents';
import LibraryService from './LibraryService';
import ContentList from './ContentList';
import ContentFilterAndSorts from './ContentFilterAndSorts';

export default class ContentSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            contents: [],
            cultures: [],
            categories: [],
            selectedCulture: null,
            selectedCategory: null,
            sortBy: "recent",
            searchFor: "",
            lastcall: null
        };
        this.listActions = {
            updateSelectedCategory: this.updateSelectedCategory.bind(this),
            updateSelectedCulture: this.updateSelectedCulture.bind(this),
            updateSortBy: this.updateSortBy.bind(this),
            updateSearchFor: this.updateSearchFor.bind(this),
            starContent: this.starContent.bind(this),
            unstarContent: this.unstarContent.bind(this),
        };
    }

    starContent(contentid) {
        LibraryService.postStar(contentid, 1).then((res) => {
            this.forceRefresh();
        });
    }

    unstarContent(contentid) {
        LibraryService.postStar(contentid, 0).then((res) => {
            this.forceRefresh();
        });
    }

    forceRefresh() {
        this.setState({ lastcall: null });
        this.refreshList();
    }

    updateSelectedCategory(category) {
        this.setState({
            selectedCategory: category,
        })
    }

    updateSelectedCulture(culture) {
        this.setState({
            selectedCulture: culture,
        })
    }

    updateSortBy(sortBy) {
        this.setState({
            sortBy: sortBy,
        })
    }

    updateSearchFor(searchFor) {
        this.setState({
            searchFor: searchFor,
        })
    }

    componentDidMount() {
        this.refreshList()
    }

    componentDidUpdate() {
        this.refreshList()
    }

    refreshList() {
        const callkey = "contents" + this.props.pageType + "_" + AuthService.getLogin();

        if (callkey === this.state.lastcall)
            return;

        if (this.props.pageType === "usercontents") {
            LibraryService.getUserContentsList()
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            contents: result.contents,
                            cultures: result.cultures,
                            categories: result.categories,
                            lastcall: callkey,
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            lastcall: callkey,
                            error
                        });
                    }
                )
        } else {
            LibraryService.getContentsList(AuthService.isUserAdmin())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            contents: result.contents,
                            cultures: result.cultures,
                            categories: result.categories,
                            lastcall: callkey,
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            lastcall: callkey,
                            error
                        });
                    }
                )
        }
    }

    render() {
        const { error, isLoaded, selectedCulture, selectedCategory, cultures, categories, contents, sortBy, searchFor } = this.state;

        let filteredContents = contents;

        if (this.props.pageType === "starred") {
            filteredContents = filteredContents.filter((content) => content.starred === "1");
        }

        if (selectedCategory !== null && selectedCategory !== "all") {
            filteredContents = filteredContents.filter((content) => content.category === selectedCategory);
        }

        if (selectedCulture !== null && selectedCulture !== "all") {
            filteredContents = filteredContents.filter((content) => content.culture === selectedCulture);
        }

        if (searchFor !== "") {
            const searchForLC = searchFor.toLowerCase();

            filteredContents = filteredContents.filter((content) =>
                content.title.toLowerCase().includes(searchForLC) ||
                content.description.toLowerCase().includes(searchForLC) ||
                content.author.toLowerCase().includes(searchForLC)
            );
        }

        if (sortBy === "nbdl") {
            filteredContents = filteredContents.sort((a, b) => b.nbdl - a.nbdl);
        } else if (sortBy === "nbview") {
            filteredContents = filteredContents.sort((a, b) => b.nbview - a.nbview);
        } else if (sortBy === "nbstars") {
            filteredContents = filteredContents.sort((a, b) => b.nbstars - a.nbstars);
        } else if (sortBy === "oldest") {
            filteredContents = filteredContents.sort((a, b) => a.lastmodified < b.lastmodified ? -1 : 1);
        } else if (sortBy === "recent") {
            filteredContents = filteredContents.sort((a, b) => a.lastmodified > b.lastmodified ? -1 : 1);
        }

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else if (!isLoaded) {
            return <MessageZone message={"Loading..."} />
        } else {
            return (
                <ContentSearchDisplay pageType={this.props.pageType} location={this.props.location}
                    selectedCulture={selectedCulture} selectedCategory={selectedCategory} sortBy={sortBy} searchFor={searchFor}
                    cultures={cultures} categories={categories} contents={filteredContents}
                    listActions={this.listActions} />
            )
        }
    }
}

class ContentSearchDisplay extends React.Component {
    render() {

        let pageTitle = null;

        if (this.props.pageType === "usercontents")
            pageTitle = "My Contents";
        else if (this.props.pageType === "starred")
            pageTitle = "Starred Contents";

        return (
            <ContentPage pageTitle={pageTitle}>
                <div className="row">
                    <ReactTooltip place="top" type="light" effect="solid" id="iconsToolTip"
                        html={true} className="changetooltip"
                        getContent={(dataTip) => `${dataTip}`} />
                    <ContentFilterAndSorts selectedCategory={this.props.selectedCategory} selectedCulture={this.props.selectedCulture}
                        sortBy={this.props.sortBy} searchFor={this.props.searchFor}
                        categories={this.props.categories} cultures={this.props.cultures}
                        listActions={this.props.listActions}
                    />
                </div>
                <ContentList contents={this.props.contents} listActions={this.props.listActions} />
            </ContentPage>
        )
    }
}



