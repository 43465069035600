import AuthService from "../Users/AuthService";
import { NotificationManager } from 'react-notifications';

import Translations from '../translation';
import LanguageConfig from '../Common/LanguageConfig';

class TranslationService {

    constructor() {
        this.cache = {}
    }

    invalidateCache(urlStartsWith) {
        const self = this;
        Object.keys(this.cache).forEach(function (key) {
            if (key.startsWith(urlStartsWith)) {
                delete self.cache[key];
            }
        });
    }

    getLatestFilesList(language) {
        const url = process.env.REACT_APP_API_ROOT + "translationsapp/latestfiles/" + language;

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getRecentChanges() {
        const url = process.env.REACT_APP_API_ROOT + "translationsapp/recentchanges";

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getLatestFile(language, filekey) {
        const url = process.env.REACT_APP_API_ROOT + "translationsapp/latestfiles/" + language + "/" + filekey;

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getFileHistory(language, filekey) {
        const url = process.env.REACT_APP_API_ROOT + "translationsapp/history/" + language + "/" + filekey;

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }


    postFileSplitContent(language, filekey, filename, splitcontent) {

        const url = process.env.REACT_APP_API_ROOT + "translationsapp/latestfiles/" + language + "/" + filekey;

        return AuthService.fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                splitcontent,
                filename
            })
        }).then(res => {
            NotificationManager.success(Translations['notification.contentmodified'][LanguageConfig.language]);
            this.invalidateCache(process.env.REACT_APP_API_ROOT + "translationsapp/latestfiles/" + language);

        }).catch((reason) => {
            console.log(reason);
        });
    }

    postFileFullText(language, filekey, filename, content) {

        const url = process.env.REACT_APP_API_ROOT + "translationsapp/latestfiles/" + language + "/" + filekey;

        return AuthService.fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                content,
                filename
            })
        }).then(res => {
            NotificationManager.success(Translations['notification.contentmodified'][LanguageConfig.language]);
            this.invalidateCache(process.env.REACT_APP_API_ROOT + "translationsapp/latestfiles/" + language);

        }).catch((reason) => {
            console.log(reason);
        });
    }

    getTranslationList(language) {
        const url = process.env.REACT_APP_API_ROOT + "translations/?language=" + language;

        if (this.cache[url])
            return Promise.resolve(this.cache[url]);

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }
}

const instance = new TranslationService();
Object.freeze(instance);

export default instance;