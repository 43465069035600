import { NotificationManager } from 'react-notifications';

import Translations from '../translation';
import LanguageConfig from '../Common/LanguageConfig';
import AuthService from "../Users/AuthService";

class EncyclopediaService {

    constructor() {
        this.cache = {}
    }

    invalidateCache(urlStartsWith) {
        const self = this;
        Object.keys(this.cache).forEach(function (key) {
            if (key.startsWith(urlStartsWith)) {
                delete self.cache[key];
            }
        });
    }

    getItemDetail(culture, type, itemkey, language) {
        const url = process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture + "/" + type + "/" + itemkey + "?language=" + language;

        if (this.cache[url])
            return Promise.resolve(this.cache[url]);

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getCultureDetail(culture, language) {
        const url = process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture + "?language=" + language;

        if (this.cache[url])
            return Promise.resolve(this.cache[url]);

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getItemHistory(culture, type, itemkey, language, skipCache = false) {

        let url;

        if (type !== undefined) {
            url = process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture + "/" + type + "/" + itemkey + "/history?language=" + language;
        } else {
            url = process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture + "/history?language=" + language;
        }

        if (!skipCache && this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }


    postItemDetail(culture, type, itemkey, language, desc) {

        const url = process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture + "/" + type + "/" + itemkey + "?language=" + language;

        return AuthService.fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                desc
            })
        }).then(res => {
            NotificationManager.success(Translations['notification.contentmodified'][LanguageConfig.language]);
            if (type !== "cultures") {
                this.invalidateCache(process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture + "/" + type + "/" + itemkey);
            } else {
                this.invalidateCache(process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture);
            }
            this.invalidateCacheChangesList();
        }).catch((reason) => {
            console.log(reason);
        });
    }

    getList(culture, type, language) {
        const url = process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture + "/" + type + "?language=" + language;

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getCultureList(language) {
        const url = process.env.REACT_APP_API_ROOT + "encyclopedia/?language=" + language;

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    postImageForItem(culture, type, itemkey, legend_en, legend_fr, file) {

        const url = process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture + "/" + type + "/" + itemkey + "/images";

        const formData = new FormData();
        formData.append("picture1", file, file.name);
        formData.append("legend_en", legend_en);
        formData.append("legend_fr", legend_fr);

        return AuthService.fetch(url, {
            method: 'POST',
            body: formData
        }).then(res => {
            NotificationManager.success(Translations['notification.imageadded'][LanguageConfig.language]);
            if (type !== "cultures") {
                this.invalidateCache(process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture + "/" + type + "/" + itemkey);
            } else {
                this.invalidateCache(process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture);
            }
            this.invalidateCacheImagesList();

            return res;
        }).catch((reason) => {
            console.log(reason);
        });
    }

    updateImageForItem(culture, type, itemkey, pos, enabled, legend_en, legend_fr) {
        const url = process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture + "/" + type + "/" + itemkey + "/images/" + pos;

        const formData = new FormData();
        formData.append("legend_en", legend_en);
        formData.append("legend_fr", legend_fr);
        formData.append("enabled", enabled);

        return AuthService.fetch(url, {
            method: 'POST',
            body: formData
        }).then(res => {
            NotificationManager.success(Translations['notification.imageupdated'][LanguageConfig.language]);
            if (type !== "cultures") {
                this.invalidateCache(process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture + "/" + type + "/" + itemkey);
            } else {
                this.invalidateCache(process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture);
            }
            this.invalidateCacheImagesList();

            return res;
        }).catch((reason) => {
            console.log(reason);
        });
    }

    deleteImageForItem(culture, type, itemkey, pos) {
        const url = process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture + "/" + type + "/" + itemkey + "/images/" + pos + "/delete";

        return AuthService.fetch(url, {
            method: 'POST',
        }).then(res => {
            NotificationManager.success(Translations['notification.imagedeleted'][LanguageConfig.language]);
            if (type !== "cultures") {
                this.invalidateCache(process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture + "/" + type + "/" + itemkey);
            } else {
                this.invalidateCache(process.env.REACT_APP_API_ROOT + "encyclopedia/" + culture);
            }
            this.invalidateCacheImagesList();

            return res;
        }).catch((reason) => {
            console.log(reason);
        });
    }

    getChangesList(language) {
        const url = process.env.REACT_APP_API_ROOT + "encyclopedia/changeslist/?language=" + language;

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getContentIndex(language) {
        const url = process.env.REACT_APP_API_ROOT + "encyclopedia/contentindex/?language=" + language;

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {

            // Need to add reference to the category for each item
            res.forEach(category => {
                category.items.forEach(item => {
                    item.category = category;
                })
            })

            this.cache[url] = res;
            return res
        });
    }

    invalidateCacheChangesList() {
        this.invalidateCache(process.env.REACT_APP_API_ROOT + "encyclopedia/changeslist");
    }

    getImagesList() {
        const url = process.env.REACT_APP_API_ROOT + "encyclopedia/imageslist";

        if (this.cache[url]) {
            return Promise.resolve(this.cache[url]);
        }

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    invalidateCacheImagesList() {
        this.invalidateCache(process.env.REACT_APP_API_ROOT + "encyclopedia/imageslist");
    }
}

const instance = new EncyclopediaService();
Object.freeze(instance);

export default instance;