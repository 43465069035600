import React from 'react';
import { Translate } from 'react-translated';

import "./homepage.css"
import LanguageConfig from '../Common/LanguageConfig';

import giftcake from './img/giftcake.png'

export default class Contributing extends React.Component {

    componentDidMount() {
        document.title = LanguageConfig.getText('site.shorttitle') + " > " + LanguageConfig.getText('menu_resources');
    }

    componentDidUpdate() {
        document.title = LanguageConfig.getText('site.shorttitle') + " > " + LanguageConfig.getText('menu_resources');
    }

    render() {
        return (
            <div>
                <section className="page-section clearfix">
                    <div className="container">
                        <div className="intro">
                            <img className="intro-img img-fluid mb-3 mb-lg-0 rounded" src={giftcake} alt="" />
                            <div className="intro-text left-0 bg-faded p-5 rounded">
                                <h2 className="section-heading mb-4">
                                    <span className="section-heading-upper"></span>
                                    <span className="section-heading-lower"><Translate text="bonus_title" /></span>
                                </h2>
                                <p className="mb-3">
                                    {LanguageConfig.getHTML("bonus_text")}
                                </p>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="page-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 bg-faded p-5 rounded">
                                <div className="cta-inner rounded">
                                    <h2 className="section-heading mb-4">
                                        <span className="section-heading-lower"><Translate text="license_title" /></span>
                                    </h2>

                                    <p className="mb-0">{LanguageConfig.getHTML("license_text")}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="page-section cta">
                    <div className="container">
                        <div className="row">
                            <div className="col mx-auto">
                                <div className="cta-inner rounded">
                                    <img style={{ float: "right" }} src={process.env.REACT_APP_HOME_IMG_ROOT+"dynamicsig.png"} alt="" />
                                    <h2 className="section-heading mb-4">
                                        <span className="section-heading-lower"><Translate text="sig_title" /></span>
                                    </h2>

                                    <p className="mb-0">{LanguageConfig.getHTML("sig_text")}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}