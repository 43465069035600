import React from 'react';

import './header.css';

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="footer text-faded text-center py-1">
                <div className="container">
                    <p className="m-0 small">Copyright &copy; Kinniken 2019</p>
                </div>
            </footer>
        )
    }
}