import React from 'react';
import AuthService from '../../Users/AuthService';
import { Translate } from 'react-translated';
import renderHTML from 'react-render-html';

import { LanguageContext } from '../../Common/LanguageContext';
import EncyclopediaService from '../EncyclopediaService';


export default class ExtraDataDisplay extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            contentindex: [],
            lastcall: null,
        };
        this.addIcons = this.addIcons.bind(this);
    }

    componentDidMount() {
        this.refreshList()
    }

    componentDidUpdate() {
        this.refreshList()
    }


    /**
     * Adds icons to the links to other Encyclopedia pages
     */
    addIcons(htmlContent) {
        let adjustedContent = htmlContent;

        // First case, links to anything else than a culture, of type /encyclopedia/culture/norman/tradegoods/calva
        let regexString = "(<a href=\"/encyclopedia/culture/(\\w*)/(\\w*)/(\\w*)\"[^>]*>[^<]*)</a>";
        let regex = new RegExp(regexString, "gi");

        let linksFound = regex.exec(adjustedContent);
        while (linksFound != null) {
            let culture = linksFound[2];
            let type = linksFound[3];
            let itemkey = linksFound[4];
            let iconkey = null;

            // For each link, search the index to find the icon
            this.state.contentindex.forEach(category => {
                if (category.culture === culture && category.type === type) {
                    category.items.forEach(item => {
                        if (item.itemkey === itemkey) {
                            iconkey = item.iconkey;
                        }
                    })
                }
            })

            if (iconkey !== null) {
                // Regexp specific to links to this content to add the icon
                let replaceRegexString = "(<a href=\"/encyclopedia/culture/" + culture
                    + "/" + type + "/" + itemkey + "\"[^>]*>[^<]*)</a>";

                let replaceRegex = new RegExp(replaceRegexString, "gi");
                adjustedContent = adjustedContent.replace(replaceRegex, "$1<img src=\"" + (process.env.REACT_APP_ENCYLOPEDIA_IMG_ROOT) + "icons/" + iconkey + ".png\" class=\"encyclopedialinkicon\" /></a>");
            }

            linksFound = regex.exec(adjustedContent);
        }

        // Same logic but for links to culture pages which look like /encyclopedia/culture/norman
        regexString = "(<a href=\"/encyclopedia/culture/(\\w*)\"[^>]*>[^<]*)</a>";
        regex = new RegExp(regexString, "gi");

        linksFound = regex.exec(adjustedContent);
        while (linksFound != null) {
            let culture = linksFound[2];
            let iconkey = null;

            this.state.contentindex.forEach(category => {
                if (category.culture === culture && category.type === "cultures") {
                    category.items.forEach(item => {
                        if (item.itemkey === culture) {
                            iconkey = item.iconkey;
                        }
                    })
                }
            })

            if (iconkey !== null) {
                let replaceRegexString = "(<a href=\"/encyclopedia/culture/" + culture
                    + "\"[^>]*>[^<]*)</a>";

                let replaceRegex = new RegExp(replaceRegexString, "gi");
                adjustedContent = adjustedContent.replace(replaceRegex, "$1<img src=\"" + (process.env.REACT_APP_ENCYLOPEDIA_IMG_ROOT) + "icons/" + iconkey + ".png\" class=\"encyclopedialinkicon\" /></a>");
            }

            linksFound = regex.exec(adjustedContent);
        }

        return adjustedContent;
    }

    refreshList() {
        const callkey = "contentindex/" + this.context.language;

        if (callkey === this.state.lastcall)
            return;

        EncyclopediaService.getContentIndex(this.context.language)
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        contentindex: result,
                        lastcall: callkey,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        lastcall: callkey,
                        error
                    });
                }
            )
    }

    render() {
        const { extradata } = this.props;

        let text;
        let author = "";

        let adjustedHTML = this.addIcons(extradata.desc);

        text = renderHTML(adjustedHTML);

        if (AuthService.loggedIn()) {
            author = extradata.author + " (" + extradata.createdon + ")";
        }

        return (
            <div className="m-2 pb-1">
                {text}
                {author !== "" &&
                    <span><br /><em><Translate text="content.editedby" /> {author}</em></span>
                }
            </div>
        );
    }
}