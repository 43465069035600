export default {

    'ind_page_title': {
        en: 'Millénaire - a Minecraft village mod',
        fr: 'un mod villageois pour Minecraft',
    },

    'ind_subtitle': {
        en: 'A Minecraft village mod',
        fr: 'Un mod villageois pour Minecraft',
    },

    'ind_wiki': {
        en: 'Player-made Wiki',
        fr: 'Wiki collaborative',
    },

    'ind_library': {
        en: 'User-created additional content',
        fr: 'Contenu additionnel créé par des utilisateurs',
    },

    'ind_forum': {
        en: 'Millénaire forum',
        fr: 'Forum Millénaire',
    },

    'ind_discord': {
        en: 'Millénaire Discord',
        fr: 'Discord Millénaire',
    },

    'ind_encyclopedia': {
        en: 'Millénaire Encyclopedia',
        fr: 'Encyclopédie Millénaire',
    },


    'menu_home': {
        en: 'Home',
        fr: 'Accueil',
    },

    'menu_current': {
        en: '(current)',
        fr: '(actuel)',
    },

    'menu_versionhistory': {
        en: 'Version History',
        fr: 'Historique des versions',
    },

    'menu_translations': {
        en: 'Translations',
        fr: 'Traductions',
    },

    'menu_resources': {
        en: 'Resources & contributing',
        fr: 'Resources & contributions',
    },

    'menu_hof': {
        en: 'Hall of Fame',
        fr: 'Panthéon',
    },

    'menu_advancements': {
        en: 'Advancements',
        fr: 'Progrès',
    },

    'menu_beta': {
        en: 'Open Beta',
        fr: 'Bêta publique',
    },

    'ind_mc112': {
        en: 'Now compatible with Minecraft 1.12.2!',
        fr: 'Maintenant compatible avec Minecraft 1.12.2 !',
    },

    'ind_currentversion': {
        en: 'Current version:',
        fr: 'Version actuelle :',
    },


    'ind_intro_text': {
        en: `<p>Millénaire is a mod for <a href="www.minecraft.net">Minecraft</a>. It aims to fill the &quot;emptiness&quot; of Minecraft worlds by adding NPC villages to it, with loose 11th-century Norman, Indian, Japanese, Mayan, Byzantine, Inuit and Seljuk Turk themes and additional cultures planned.</p>
		<p>Villages are populated with men, women and children of various kinds, who perform tasks such as trading with the player, expending current buildings or improving existing ones, cultivating crops such as wheat in Norman villages and rice in Indian ones, crafting tools and powerful amulets, etc. As the village expends, the number of villagers increases as couples have children who grow up into new adults.</p>
		<p>Help villages grow by trading with them and be rewarded with unique items such as Norman and Indian food or statues and tapestries to decorate your house with. And if they start liking you enough, they might even build you a house of your own.</p>
		<p><b>New in Millénaire 8.1: the Seljuk Turks culture!</b></p>
		<p><em>Millénaire is available in the following languages: French, English, German, Russian, Polish, Spanish, Czech, Swedish, Dutch, Portuguese, Slovenian, Chinese and Arabic (more coming!)</em></p>`,

        fr: `<p>
							Millénaire est un mod pour <a href="www.minecraft.net">Minecraft</a>. Son but est de combler le "vide"
							des mondes en ajoutant des villages de NPC, avec comme thème
							différentes cultures du XIème siècle (Norman, indien, maya,
							japonais, byzantins, inuits et turks seljuks pour l'instant).
						</p>
						<p>Les villages sont peuplés d'hommes, femmes et enfants de
							différents types, qui commercent avec le joueur, construisent des
							nouveaux bâtiments ou améliorent des bâtiments existants,
							cultivent des plantes comme le blé pour les normands ou le riz
							pour les indiens, fabriquent des outils et des armes uniques, etc.
							Au fur et à mesure que le village s'étend, les couples de
							villageois ont des enfants qui grandissent pour devenir des
							adultes à leurs tours.</p>
						<p>Aidez les villageois à développer leurs villages et vous
							serez récompensé par des objets uniques, comme de la nourriture
							des différentes cultures, des nouveaux types de blocs et des armes
							puissantes. Et si les villageois vous aiment beaucoup, ils vous
							construiront même une maison!</p>
						<p><b>Nouveau dans Millénaire 8.1 : les turks seljuks !</b></p>
		
						<p>
							<em>Millénaire est disponible dans les langues suivantes:
								français, anglais, allemand, russe, polonais, espagnol, tchèque,
								suédois, néerlandais, portugais, slovène, chinois et arabe (pour
								l'instant!)</em>
                        </p>`
    },

    'ind_donation': {
        en: 'Are you enjoying Millénaire? Feeling like paying me a cider?',
        fr: `Vous aimez Millénaire? Vous voulez me payer un cidre ?`
    },

    'ind_mod_warning': {
        en: `Before installing, make sure your saves are backed-up. I make no guarantee Millénaire won't damage your saves. Also, future releases might not be compatible with current villages.`,
        fr: `Avant d'installer Millénaire, faites une sauvegarde de vos
							mondes. Je ne garantis pas que Millénaire ne les affectera pas.
							Par ailleurs, les versions futures de Millénaire peuvent être
							incompatibles avec les villages existants.`,
    },

    'ind_mod_112': {
        en: 'Most recent release, for Minecraft 1.12.2:',
        fr: 'Version la plus récente, pour Minecraft 1.12.2 :',
    },

    'ind_mod_download': {
        en: 'Download',
        fr: 'Téléchargement',
    },

    'ind_mod_client': {
        en: 'Client',
        fr: 'Client',
    },

    'ind_mod_server': {
        en: 'Server',
        fr: 'Serveur',
    },
    'ind_mod_client_server': {
        en: 'Client & Server',
        fr: 'Client & serveur',
    },
    'ind_mod_17': {
        en: 'Release 6.0.2, for Minecraft 1.7.10:',
        fr: 'Version 6.0.2, pour Minecraft 1.7.10 :',
    },

    'ind_curseforge': {
        en: 'Millénaire is also available on <a href="https://minecraft.curseforge.com/projects/millenaire">CurseForge</a>.',
        fr: 'Millénaire est également disponible sur <a href="https://minecraft.curseforge.com/projects/millenaire">CurseForge</a>.',
    },

    'ind_release_date': {
        en: 'Release date:',
        fr: 'Date de sortie :',
    },

    'ind_release_112': {
        en: 'Version {version} for Minecraft 1.12',
        fr: 'Version {version} pour Minecraft 1.12',
    },

    'slideshow_title': {
        en: 'Millénaire in pictures.',
        fr: 'Images de Millénaire',
    },

    'slideshow_1': {
        en: 'Come across small villages while exploring, such as this Norman settlement...',
        fr: `Tombez sur des petits villages
									en explorant, comme ce hameau normand...`,
    },

    'slideshow_2': {
        en: '... or this Japanese one, centered  on a rice paddy.',
        fr: `... ou ce hameau japonais,
									centré sur une rizière.`,
    },

    'slideshow_3': {
        en: 'Or discover a mysterious Mayan pyramid, abandoned  in the jungle...',
        fr: `Ou découvrez une mystérieuse
									pyramide maya, perdue dans la jungle...`,
    },

    'slideshow_4': {
        en: '...before heading to icy plains to meet igloo-dwelling Inuits.',
        fr: '...avant de rencontrer des Inuits installés dans une plaine enneigée.',
    },


    'slideshow_5': {
        en: 'Listen to the villagers discussing their job, life or simply the weather.',
        fr: `Écoutez les villageois discuter de leur travail,
									leur vie ou simplement du temps.`,
    },

    'slideshow_6': {
        en: `See what they are doing and what they need in the villages' centres.`,
        fr: `Vérifiez ce qu'ils font et ce dont ils ont
									besoin dans les bâtiments centraux des villages.`,
    },

    'slideshow_7': {
        en: 'Trade with them, to help their villages grow and gain unique blocks, foods and items.',
        fr: `Commercez avec eux, pour les aider à se
									développer ou pour obtenir des blocs, objets ou aliments
									uniques.`,
    },

    'slideshow_8': {
        en: 'See their villages develop in prosperous communities...',
        fr: `Observez leurs villages se
									développant en communautés dynamiques...`,
    },

    'slideshow_9': {
        en: '...or massive fortresses surrounded  by desert.',
        fr: `...ou en forteresses massives
									entourées par le désert.`,
    },

    'slideshow_10': {
        en: 'But be wary  in your travels, for hostile bandits and soldiers can be waiting deep in the woods!',
        fr: `Mais soyez sur vos gardes dans vos voyages, des
									bandits hostiles peuvent vous attendre loin dans les bois!`,
    },

    'slideshow_11': {
        en: 'Encourage villages to trade with one another, or push them to war and see their men battling while the women and children take shelter!',
        fr: `Encouragez les villages à commercer entre eux,
									ou semez la zizanie et regardez leurs hommes se battre pendant
									que les enfants et les femmes s'abritent!`,
    },

    'slideshow_12': {
        en: 'And when the pull of adventure becomes too strong, seek out the mysterious Sadhu in the forest to start a great quest on the origins of the world.',
        fr: `Et quand vous sentez l'appel de l'aventure,
									allez chercher le mystérieux Sadhu dans la forêt pour
									commencer une grande quête sur les origines du monde.`,
    },

    'slideshow_13': {
        en: 'Who knows, you might even come across the mysterious Panthéon, the massive structure where the contributors and donors to Millénaire are immortalized.',
        fr: `Qui sait, vous tomberez peut-être sur le
									mythique Panthéon, le bâtiment grandiose où les donateurs et les
									contributeurs à Millénaire sont immortalisés.`,
    },

    'slideshow_14': {
        en: 'And it might inspire you into helping out with an even more massive construction project!',
        fr: `Et ça vous inspirera peut être à contribuer à la réalisation d'un project encore plus gigantesque !`,
    },

    'seljuk_title': {
        en:'22/05/2020: Release of the Seljuk culture!',
        fr:'22/05/2020 : Sortie de la culture Seljuk !'
    },

    'seljuk_desc': {
        en: `<p>Millénaire heads back to the Middle-East to present talhanation' Seljuk Turks! This mighty medieval Empire stretching from Anatolia to Afghanistan will stun you with their elaborate architecture.</p>
        `,
        fr: `<p> Millénaire retourne au Moyen-Orient pour présenter les Turcs seldjoukides de talhanation ! Ce puissant empire médiéval s'étendant de l'Anatolie à l'Afghanistan vous étonnera par son architecture élaborée. </p>`,
    }, 

    'seljuk_details': {
        en: `
        <p>Millénaire heads back to the Middle-East to present talhanation' Seljuk Turks! This mighty medieval Empire stretching from Anatolia to Afghanistan will stun you with their elaborate architecture.</p>
            <ul>
            <li>Full new culture will multiple village types, city walls and lone buildings</li>
            <li>New culture-specific variations on the mud bricks already used by the Indians</li>
            <li>Elaborate wall carpets</li>
            <li>A new pistachio tree used to produce unique food items</li>
            <li>Unique tools and armours</li> </ul>
        `,
        fr: `<p> Millénaire retourne au Moyen-Orient pour présenter les Turcs seldjoukides de talhanation! Ce puissant empire médiéval s'étendant de l'Anatolie à l'Afghanistan vous étonnera par son architecture élaborée. </p>
                     <ul>
                     <li> Une nouvelle culture complète avec plusieurs types de villages, des murailles et des bâtiments isolés </li>
                     <li> De nouvelles variations spécifiques des briques de boue déjà utilisées par les Indiens </li>
                     <li> Des tapis muraux élaborés </li>
                     <li> Un nouveau pistachier utilisé pour produire des aliments uniques </li>
                     <li> Des outils et armures uniques </li> </ul>
           `,
    },


    'slideshow_seljuk_1': {
        en: 'Come across the new Seljuk villages in the wilderness...',
        fr: `Découvrez les nouveaux villages seldjoukides en pleine nature ...`,
    },

    'slideshow_seljuk_2': {
        en: '...prospering in parched areas thanks to their sophisticated wells...',
        fr: `... prospèrant dans des zones arides grâce à leurs puits sophistiqués ...`,
    },

    'slideshow_seljuk_3': {
        en: '...and unique take on wind power.',
        fr: `... et leur approche unique de l'énergie éolienne.`,
    },

    'slideshow_seljuk_4': {
        en: 'Admire their elaborate carpets decorating private and public buildings...',
        fr: `Admirez leurs tapis élaborés décorant des bâtiments privés et publics ...`,
    },

    'slideshow_seljuk_5': {
        en: '...and the superb public baths at the center of the village\'s cultural and religious life.',
        fr: `... et les superbes bains publics au centre de la vie culturelle et religieuse du village.`,
    },

    'slideshow_seljuk_6': {
        en: 'Young Turks train in the barrack to become soldiers...',
        fr: `Les jeunes Turcs s'entraînent dans la caserne pour devenir soldats ...`,
    },

    'slideshow_seljuk_7': {
        en: '...while more important villages surround themselves with protective walls...',
        fr: `... tandis que les villages les plus importants s'entourent de murs de protection ...`,
    },

    'slideshow_seljuk_8': {
        en: '...to guard commercial riches...',
        fr: `... pour protéger des richesses commerciales ...`,
    },

    'slideshow_seljuk_9': {
        en: '...or holy sanctuaries.',
        fr: `... ou des sanctuaires sacrés.`,
    },







    'release8_title': {
        en:'25/10/2019: Release of Millénaire 8.0!',
        fr:'25/10/2019 : Sortie de Millénaire 8.0 !'
    },
   

    'release8_desc': {
        en: `<p>New features: village walls, reworked Byzantines, fancy panels, border posts and more!</p></p>
        `,
        fr: `<p>Nouveautés : enceintes pour les villages, refonte des byzantins, paneaux améliorés, bornes, et plus encore !</p>`,
    },

    'release8_details': {
        en: `<p>Millénaire 8.0 is one of the biggest release so far, adding:</p>
        <br/><br/>
            <ul>
            <li>Fully rebuilt Byzantine culture by Ticlon, with a more accurate medieval feel. Olive trees, new blocks, new buildings, new items, new textures! It's like a brand new culture.</li>
            <li>Village walls (Normans and Indians villages only so far) surrounding the village's centre. They start as simple palissades and get upgraded to massive stone walls.</li>
            <li>New panels replacing the vanilla signs in villages, larger, using icons, and customized for each culture.</li>
            <li>Border posts at the edge of villages, making it clear up to where the village will expend.</li>
            <li>Many, many tweaks and fixes.</li>
            <li><b>Mostly compatible with worlds from previous versions, except for Byzantine villages and a bug where buildings started in previous versions might be upgraded incorrectly.</b></li>
            </ul>
        `,
        fr: `<p> Millénaire 8.0 est l’une des plus grosses nouvelles versions à ce jour, ajoutant : </ p>
        <br/><br/>
            <ul>
            <li> La culture byzantine entièrement refondue par Ticlon, dans un style médiéval plus authentique. Oliviers, nouveaux blocs, nouveaux bâtiments, nouveaux objets, nouvelles textures ! C'est comme une toute nouvelle culture. </ Li>
            <li> Enceintes (villages des Normands et des Indiens seulement pour le moment) entourant le centre du village. Elles commencent comme de simples palissades et sont améliorées en massives murailles de pierre. </ Li>
            <li> Nouveaux panneaux remplaçant les pancartes standardes dans les villages : plus grands, utilisant des icônes et personnalisés pour chaque culture. </ li>
            <li> Bornes situées à la périphérie des villages, afin d’indiquer clairement jusqu'où le village s'étendra. </ li>
            <li> Beaucoup, de nombreux ajustements et corrections. </ li>
            <li><b>Essentiellement compatible avec les mondes des versions précédentes, à l'exception des villages byzantins et d'un bogue dans lequel les bâtiments démarrés dans les versions précédentes pourraient ne pas être mis à niveau correctement.</b></li>
            </ ul>

           `,
    },

    'slideshow_r8_1': {
        en: 'Some villages now start surrounded by simple fences...',
        fr: `Certains villages commencent maintenant entourés de simples palissades...`,
    },

    'slideshow_r8_2': {
        en: '...that get upgraded to mighty stone walls...',
        fr: `...qui sont transformées en puissants murs de pierre...`,
    },

    'slideshow_r8_3': {
        en: '...with imposing gateways.',
        fr: `...avec des portes imposantes.`,
    },

    'slideshow_r8_4': {
        en: 'Available for the Normans and the Indians for now.',
        fr: `Disponible pour les normands et les indiens pour l'instant.`,
    },

    'slideshow_r8_5': {
        en: 'A completely redone Byzantine culture now sports a more medieval feel.',
        fr: `Une culture byzantine entièrement refaite, dans un style davantage médiévale.`,
    },

    'slideshow_r8_6': {
        en: 'Complete with olive trees to produce oil.',
        fr: `Complète avec des oliviers pour produire de l'huile.`,
    },

    'slideshow_r8_7': {
        en: 'They remain imposing builders, from massive forts...',
        fr: `Ils restent un peuple de grands bâtisseurs, depuis d'énormes forts...`,
    },

    'slideshow_r8_8': {
        en: '...to sprawling commercial towns.',
        fr: `...jusqu'à des grandes villes commerçantes.`,
    },

    'slideshow_r8_9': {
        en: 'Though the highlight remains their impressive orthodox churches...',
        fr: `Même si le summum reste leurs impressionnantes églises orthodoxes...`,
    },

    'slideshow_r8_10': {
        en: '...rightly famous for their elaborate domes!',
        fr: `...à juste titre célèbres pour leurs dômes élaborés !`,
    },

    'slideshow_r8_11': {
        en: 'Signs are replaced by bigger panels, with icons, to better follow the village\'s needs.',
        fr: `Les pancartes sont remplacées par des panneaux plus grands, avec des icônes, pour mieux suivre les besoins du village.`,
    },

    'slideshow_r8_12': {
        en: 'And border posts now mark the village\'s limits, in the desert...',
        fr: `Et des bornes marquent maintenant les limites du village, dans le désert...`,
    },

    'slideshow_r8_13': {
        en: '...or the Inuit snow.',
        fr: `...comme dans la neige des Inuits.`,
    },


    'indian_rework_title': {
        en: '13/04/2019: Indian Refresh',
        fr: `13/04/2019 : Refonte des indiens`,
    },

    'indian_rework_desc': {
        en: `<p>The Indian culture has been very largely reworked, with many new blocks, features and buildings.</p>
        `,
        fr: `<p> La culture indienne a été très largement retravaillée, avec de nombreux nouveaux blocs, éléments et bâtiments.</ p>`,
    },
    

    'indian_rework_details': {
        en: `<p>The Indian culture has been very largely reworked, with many new blocks, features and buildings. In particular this new version adds:</p>
            <ul><li>Bricks in 16 colours (instead of only white as before), with different villages using different colours for their buildings</li>
            <li>Decorated versions of the bricks above, with painted designs based on traditional Indian village buildings</li>
            <li>Paint buckets that can be used to change the colours of multiple connected bricks at once</li>
            <li>New "She's like a Rainwbow" advancement for using a paint bucket</li>
            <li>Carved sandstone blocks, available in "regular", red and a new ochre variants</li>
            <li>Three new types of wooden bars, including one in a 2x2 "rosette" pattern</li>
            <li>A new Indian-style "charpoy" bed</li>
            <li>A new cotton crop that can be harvested to make wool</li>
            <li>Three new villagers (painter, craftsman and weaver)</li>
            <li>Many brand new buildings, and in particular spectacular new designs for the Fort and the Palace</li>
            <li>Rework of all the other buildings</li>
            </ul>
        `,
        fr: `<p> La culture indienne a été très largement retravaillée, avec de nombreux nouveaux blocs, éléments et bâtiments. En particulier, cette nouvelle version ajoute : </ p>
                  
        <ul> <li>Briques de 16 couleurs (au lieu de blanc uniquement). Les différents villages utilisent différentes combinaisons de couleurs pour leurs bâtiments </ li>
                    <li>Versions décorées des briques ci-dessus, avec des motifs peints inspirés des bâtiments traditionnels des villages indiens  </ li>
                    <li>Des seaux de peinture pouvant être utilisés pour changer les couleurs de plusieurs briques connectées à la fois </ li>
                    <li>Un progrès "She's like a Rainbow" pour l'utilisation d'un de ces seaux de peinture</li>
                    <li>Blocs de grès sculptés, disponibles en variantes "normale", rouge et ocre </ li>
                    <li>Trois nouveaux types de barres en bois, dont une dans un motif de "rosette" 2x2 </ li>
                    <li> Un nouveau lit "charpoy" de style indien </ li>
                    <li> Une nouvelle culture, le coton, pouvant être récoltée pour fabriquer de la laine </ li>
                    <li> Trois nouveaux villageois (peintre, artisan et tisserand) </ li>
                    <li> De nombreux nouveaux bâtiments, et en particulier des nouveaux modèles spectaculaires pour le fort et le palais </ li>
                    <li> Amélioration de tous les autres bâtiments </li>
                    </ul>`,
    },

    'slideshow_beta_1': {
        en: 'The sun rises on a new style of Indian villages!',
        fr: `Le soleil se lève sur un nouveau style de villages indiens !`,
    },

    'slideshow_beta_2': {
        en: 'Watch them cook bricks as before...',
        fr: `Regardez-les cuire des briques comme avant ...`,
    },

    'slideshow_beta_3': {
        en: '...and paint them with fancy patterns...',
        fr: `... et les peindre avec des motifs fantaisistes ...`,
    },

    'slideshow_beta_4': {
        en: 'to better brighten the village’s buildings.',
        fr: `pour mieux décorer les bâtiments du village.`,
    },

    'slideshow_beta_5': {
        en: 'They can build you a new, stylish villa.',
        fr: `Ils peuvent vous construire une élégante nouvelle villa.`,
    },

    'slideshow_beta_6': {
        en: 'Or you can pick yourself the style that fits you best.',
        fr: `Ou vous pouvez choisir vous-même le style qui vous convient le mieux.`,
    },

    'slideshow_beta_7': {
        en: 'They can now grow cotton, from which to make wool.',
        fr: `Ils peuvent maintenant cultiver du coton à partir duquel fabriquer de la laine.`,
    },

    'slideshow_beta_8': {
        en: 'And mine red sandstone, to produce carved sandstone blocs.',
        fr: `Et extraire du grès rouge pour produire des blocs de grès sculptés.`,
    },

    'slideshow_beta_9': {
        en: 'They will use it for their village temple...',
        fr: `Ils l'utiliseront pour le temple du village ...`,
    },

    'slideshow_beta_10': {
        en: 'or a massive new fortress...',
        fr: `ou une massive nouvelle forteresse ...`,
    },

    'slideshow_beta_11': {
        en: '...ready to withstand a siege.',
        fr: `... prêt à soutenir un siège.`,
    },

    'slideshow_beta_12': {
        en: 'In other villages, a luxurious palace will come up instead.',
        fr: `Dans d'autres villages, un palais de luxe sera construit à sa place.`,
    },


    'version_formc': {
        en: 'Millénaire for Minecraft',
        fr: 'Millénaire pour Minecraft',
    },

    'version_releasenotes': {
        en: 'Release Notes',
        fr: 'Changements',
    },

    'bonus_title': {
        en: 'Bonus for Millénaire contributors',
        fr: 'Bonus pour les contributeurs Millénaire',
    },

    'bonus_text': {
        en: `<p>To thanks all the people who contributed or donated to Millénaire and will do in the future, Millénaire now includes a small bonus for them: a small house available for free from the village heads of each culture (except in MP). If you had already contributed to Millénaire, send me a mail to <a href="mailto:kinniken@millenaire.org">kinniken@millenaire.org</a> with your Minecraft login and I'll enable the feature for you.</p>

			<p>And if you want to contribute but don't know how to, here is a small list of areas where I often need help:</p>
			   <ul><li>Translations: many are incomplete.</li>
			   <li>Textures: I am always looking for good artists for Millénaire.</li>
			   <li>Dialogs: the new dialogs feature requires many. New ones would be welcome.</li>
			   <li>Buildings: new models for existing buildings, or new ideas for lone buildings for example.</li>
			   <li>For ambitious people: new cultures! The Mayas, Japanese and Byzantines were primarily made by other people.</li>
			   <li>Wiki: the wiki lacks content, and many sections are out of date.</li>
			  </ul>
			  <p>Before starting, send me a mail to <a href="mailto:kinniken@millenaire.org">kinniken@millenaire.org</a> to tell me about it! (Except for adding content to the wiki - you can do that directly).</p>
			<p>
			And icing on the cake, bonus houses indeed come with a free cake!</p>`,
        fr: `<p>
							En remerciement à tous ceux qui ont contribué ou donné à
							Millénaire et qui le feront à l'avenir, Millénaire inclut
							désormais un petit bonus pour eux: une petite maison disponible
							gratuitement auprès des chefs de village de chaque culture (hors
							mondes multijoueurs). Si vous avez déjà contribué à Millénaire,
							envoyez-moi un mail à <a href="mailto:kinniken@millenaire.org">kinniken@millenaire.org</a>
							avec votre login Minecraft pour que je l'active pour vous.
						</p>

						<p>Et si vous voulez contribuer mais ne savez pas quoi faire,
							voici une petite liste des sujets où j'ai souvent besoin d'aide:</p>
						<ul>
							<li>Traductions: beaucoup sont incomplètes.</li>
							<li>Textures: je suis toujours à la recherche de bons
								artistes pour Millénaire.</li>
							<li>Dialogues: la nouvelle fonctionnalité de dialogues en
								nécessite beaucoup. Des nouveaux sont les bienvenus.</li>
							<li>Bâtiments: des nouveaux modèles pour les bâtiments
								existants, ou de nouvelles idées de bâtiments isolés par exemple.</li>
							<li>Pour les ambitieux: de nouvelles cultures! Les mayas,
								japonais et byzantins ont été essentiellement faites par d'autres
								personnes.</li>
							<li>Wiki: la wiki manque de contenu, et beaucoup de sections
								sont obsolètes.</li>
						</ul>
						<p>
							Avant de commencer, envoyez-moi un mail à <a
								href="mailto:kinniken@millenaire.org">kinniken@millenaire.org</a>
							pour m'en parler! (Sauf pour ajouter du contenu à la wiki - vous
							pouvez faire ça directement).
						</p>
						<p>Et cerise sur le gâteau, les maisons bonus viennent
							justement avec un gâteau gratuit:</p>`,
    },

    'license_title': {
        en: 'Millénaire License',
        fr: 'Licence Millénaire',
    },

    'license_text': {
        en: `<p>Millénaire as a whole, and all parts specifically developped by Kinniken, are copyrighted to Kinniken (kinniken@millenaire.org), all rights reserved, 2011-2018.</p>
		
		<p>Usage of the mod is allowed as well as non-commercial repackaging within modpacks as long as credit is given, including a link to millenaire.org.</p>

		<p>Usage of elements of the mod in other mods or other projects is forbidden without authorization.</p>

		<p><b>Contributions to Millénaire</b></p>

		<p>Elements contributed to Millénaire, whether code, buildings, textures or any other assets, remain the intellectual property of their creator.</p>

		<p>Contributing them to Millénaire grants Kinniken a non-rescindable license to use that content in Millénaire and associated projects, including the right to modify it as needed.</p>`,

        fr: `<p>Millénaire dans son ensemble, et toutes les parties spécifiquement développées par Kinniken, sont la propriété de Kinniken (kinniken@millenaire.org), tous droits réservés, 2011-2018.</p>

		<p>L'utilisation du mod est autorisée, de même que l'inclusion dans des modpacks non commerciaux, tant que les crédits sont préservés, y compris un lien vers millenaire.org.</p>

		<p>L'utilisation d'éléments du mod dans d'autres mods ou d'autres projets est interdite sans autorisation spécifique.</p>

		<p><b>Contributions à Millénaire</b></p>

		<p>Les éléments contribués à Millénaire, qu'il s'agisse de code, de bâtiments, de textures ou de tout autre resource, restent la propriété intellectuelle de leur créateur.</p>

		<p>Leur contribution à Millénaire donne à Kinniken une licence non résiliable pour utiliser ce contenu dans Millénaire et les projets associés, y compris le droit de le modifier si nécessaire.</p>`,
    },


    'sig_title': {
        en: 'Millénaire Signature',
        fr: 'Signature Millénaire',
    },

    'sig_text': {
        en: 'If you want to show your support for Millénaire on a forum or a website, you can use this signature picture that is automatically updated when a new version comes out.',
        fr: `Si vous souhaitez apporter votre soutien à Millénaire sur un
							forum ou un site web, vous pouvez utiliser cette image en
							signature. Elle est automatiquement mise à jour quand une nouvelle
							version sort.`,
    },


    'translation_help_title': {
        en: 'Helping with Translations',
        fr: 'Aider à traduire Millénaire',
    },


    'translation_status_title': {
        en: 'Current Translation Status',
        fr: 'Statut des traductions',
    },

    'translation_help_text': {
        en: `<p>Millénaire is available in different languages​​. The French and English versions are updated directly by me, others by volunteer translators. Orange1861 is in charge of coordinating the efforts. You can check the status of the translations here and <a href="mailto:Orange1861.65@gmail.com">email him</a> if you want to help update an existing translation or provide a new one.</p>
        <p>You can now work on updating translations by using the new <a href="/translationcentre">Translation Centre</a>, an online collaborative editing tool! More information is available in the Translation Centre.
            </p>`,
        fr: `<p>
						Millénaire est disponible dans différentes langues. Les versions
						françaises et anglaises sont mises à jour directement par moi, les
						autres par des traducteurs bénévoles, coordonnés par Orange1861. Vous pouvez consulter le
						statut des traductions ici et <a
							href="mailto:Orange1861.65@gmail.com">lui envoyer un email</a> si
						vous voulez aider à mettre à jour une traduction existante ou en
						fournir une nouvelle.
					</p>
					<p>Vous pouvez maintenant travailler aux traductions sur le nouveau <a href="/translationcentre">Centre de Traduction</a>, un outil collaboratif en ligne ! Davantage d'informations sont disponibles directement sur ce site.
					</p>`,
    },


    'hof_text': {
        en: `<p>People who contributed to Millénaire are listed in-game in the rare Panthéon lone building. Their names are also present here.</p>
										<p><em>If you had contributed but your avatar is not present below, you can send it to me by mail for inclusion.</em></p>`,
        fr: `<p>Les gens ayant contribué à Millénaire sont listés dans Minecraft dans le rarement aperçu Panthéon. Leurs noms sont aussi présents ici.</p>
										<p><em>Si vous avez contribué mais que votre avatar ne s'affiche pas ici, vous pouvez me l'envoyer par mail.</em></p>`,
    },


    'mainadvancements_text': {
        en: '<p>The list below shows all advancements currently present in Millénaire as well as the percentage of players who have completed them, based on anonymous data from players.</p>',
        fr: '<p>La liste ci-dessous recense tous les progrès présents dans Millénaire ainsi que le pourcentage de joueurs les ayant complétés, basé sur des statistiques anonymes.</p>',
    },

    'advancementsupdated_text': {
        en: '<p><em>Statistics last updated on: *advancements_date* (Paris Time / CET).</em></p>',
        fr: '<p><em>Données mises à jour le : *advancements_date* (Heure de Paris / CET).</em></p>',
    },

    'advancementsenterlogin_text': {
        en: `Check a player's advancements:`,
        fr: `Regarder les progrès d'un joueur :`,
    },

    'advancementsenterloginbutton_text': {
        en: 'Go',
        fr: 'Go',
    },

    'advancements_survival': {
        en: 'Display only advancements earned in survival',
        fr: 'Afficher uniquement les progrès obtenus en mode survie',
    },

    'advancements_all': {
        en: 'Display all advancements earned',
        fr: 'Afficher tous les progrès obtenus',
    },

    'advancements_loginnotfound': {
        en: `<p>No advancement data is available for <b>*login*</b>. They will only show if the user has activated public advancement data in the Millénaire options ('M' menu).</p>`,
        fr: `<p>Aucune donnée sur les progrès disponibles pour <b>*login*</b>. Elles ne sont disponibles que si l'utilisateur a activé la remonté de données dans les options Millénaire (menu 'M').</p>`,
    },

    'advancements_loginfound': {
        en: '<p>Advancements completed by <b>*login*</b> in Minecraft survival. Note that the use of commands or mods to make completing them easier is not blocked.</p>',
        fr: `<p>Progrès accomplis par <b>*login*</b> dans Minecraft en mode survie. Notez que l'utilisation de commandes ou de mods pour les terminer plus facilement n'est pas bloquée.</p>`,
    },



    'advancements_addedin': {
        en: 'Added in',
        fr: 'Ajouté en',
    },

    'dl_download': {
        en: 'Download',
        fr: 'Téléchargement',
    },

    'dl_youaredownloading': {
        en: 'You are downloading:',
        fr: 'Vous téléchargez :',
    },

    'dl_haveyoutried': {
        en: `<ul>
        <li>Hiring a villager to fight for you</li>
        <li>Defending a village against a raid</li>
        <li>Buying a Wand of Summoning and generating a village with it</li>
        <li>Becoming leader in a culture and starting a controlled village</li>
        <li>Meeting the Sadhu or the Alchemist to start the Creation Quest</li>
        <li>Buying the Amulet of Sköll and Hati and using it to turn the night into day</li>
        <li>Getting villages of different cultures to trade with each-other by boosting their relation</li>
        </ul>`,
        fr: `<ul>
        <li>Embaucher un villageois pour combattre pour vous</li>
        <li>Défendre un village contre un raid</li>
        <li>Acheter une baguette d'invocation et générer un village avec</li>
        <li>Devenir chef d'une culture et fonder un village contrôlé</li>
        <li>Rencontrer le Sadhu ou l'Alchimiste et commencer la Quête de la Création</li>
        <li>Acheter l'amulette de Sköll et Hati et l'utiliser pour transformer la nuit en jour</li>
        <li>Faire commercer des villages de cultures différentes en améliorant leurs relations</li>
        </ul>`,
    },

    'dl_haveyoutried_title': {
        en: 'Have you tried this in Millénaire?',
        fr: 'Avez-vous essayé ça dans Millénaire ?',
    },

    'dl_download1': {
        en: 'Please wait 1 second for your download...',
        fr: 'Veuillez attendre votre téléchargement 1 seconde...',
    },

    'dl_download2': {
        en: 'Please wait 2 seconds for your download...',
        fr: 'Veuillez attendre votre téléchargement 2 secondes...',
    },

    'dl_download3': {
        en: 'Please wait 3 seconds for your download...',
        fr: 'Veuillez attendre votre téléchargement 3 secondes...',
    },

    'dl_download4': {
        en: 'Please wait 4 seconds for your download...',
        fr: 'Veuillez attendre votre téléchargement 4 secondes...',
    },

    'dl_download5': {
        en: 'Please wait 5 seconds for your download...',
        fr: 'Veuillez attendre votre téléchargement 5 secondes...',
    },

    'dl_mostrecentversion': {
        en: 'Most recent version',
        fr: 'Version la plus récente',
    },

    'expand': {
        en: 'Expand',
        fr: 'Etendre',
    },

    'shrink': {
        en: 'Shrink',
        fr: 'Reduire',
    },

    'website_announcement.title': {
        en: '06/03/2019: Millénaire Website Redesign',
        fr: '06/03/2019 : Refonte du site Millénaire'
    },

    'website_announcement.desc': {
        en: `The Millénaire website has been rework with many upgrades over the old one. The main changes are:
        <ul>
        <li>New host that should be more reliable than the old one</li>
        <li>Completely reworked Library, with a complete redesign and some new features</li>
        <li>Updated the wiki to the latest MediaWiki release, with a new more modern look</li>
        <li>Updated the forum to the latest phpBB release, also with a more modern look</li>
        <li>The Wiki now uses the Millénaire accounts (the ones for the Library and Encyclopedia) instead of separate ones</li>
        <li>The Encyclopedia now includes a search engine</li>
        <li>Reworked, faster-loading homepage</li>`,
        fr: `Le site web de Millénaire a été remanié avec de nombreuses mises à jour par rapport à l’ancien. Les principales modifications sont les suivantes :
                 <ul>
                 <li> Nouvel hébergeur qui devrait être plus fiable que l'ancien </ li>
                 <li> Refonte complète de la librairie, avec une nouvelle interface et quelques nouvelles fonctionnalités </ li>
                 <li> Mise à jour du wiki vers la dernière version de MediaWiki, avec un nouveau look plus moderne </ li>
                 <li> Mise à jour du forum vers la dernière version de phpBB, également avec un look plus moderne </ li>
                 <li> Le wiki utilise désormais les comptes Millénaire (ceux de la bibliothèque et de l’encyclopédie) au lieu de comptes séparés </ li>
        <li>L'encyclopédie inclue maintenant un moteur de recherche</li>         
        <li> Page d'accueil retravaillée et se chargeant plus rapidement </ li>`,
    },
}