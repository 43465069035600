import React from 'react';
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { Translate } from 'react-translated'

import { LanguageContext } from '../../Common/LanguageContext';
import { ContentZone, ContentPage, MessageZone } from '../../Common/CommonComponents';
import EncyclopediaService from '../EncyclopediaService';
import BookDisplay from "./BookDisplay";
import AuthService from '../../Users/AuthService';
import EncyclopediaSearch from '../EncyclopediaSearch';

export default class ItemDetails extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            item: null,
            lastcall: null
        };
    }

    refreshDetails() {

        const culture = this.props.match.params.culture;
        const type = this.props.match.params.type;
        const itemkey = this.props.match.params.itemkey;

        const callkey = culture + type + itemkey + this.context.language;

        if (callkey === this.state.lastcall)
            return;

        EncyclopediaService.getItemDetail(culture, type, itemkey, this.context.language).then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    item: result,
                    lastcall: callkey,
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    lastcall: callkey,
                    error
                });
            }
        )
    }


    componentDidMount() {
        this.refreshDetails()
    }

    componentDidUpdate() {
        this.refreshDetails()
    }

    render() {

        const { error, isLoaded, item } = this.state;

        const culture = this.props.match.params.culture;
        const type = this.props.match.params.type;
        const itemkey = this.props.match.params.itemkey;

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else if (!isLoaded) {
            return <MessageZone message={"Loading..."} />
        } else {

            let villagerPict = null;

            if (type === "villagers") {
                villagerPict = (
                    <img alt=""
                        style={{ float: 'right', height: 300 }}
                        src={process.env.REACT_APP_ENCYLOPEDIA_IMG_ROOT + "villagers/" + culture + "_" + itemkey + '.png'} />
                )
            }

            return (
                <ContentPage pageTitle={item.title}>
                    <EncyclopediaSearch location={this.props.location} />
                    <ContentZone>
                        <div style={{ float: "right", marginTop: "0", marginRight: "0" }}>
                            {AuthService.loggedIn() &&
                                <div>
                                    <Link to={this.props.location.pathname + "/edit"} style={{ float: "right", marginBottom: "2em" }} >
                                        <button className="btn btn-primary mr-2"  >
                                            <FaEdit className="buttonicon" />
                                            <Translate text="button.editcontent" />
                                        </button>
                                    </Link>
                                </div>
                            }
                            {villagerPict}
                        </div>
                        <BookDisplay travelbookdata={item.travelbookdata} extradata={item.extradata} images={item.images}
                            location={this.props.location} />
                    </ContentZone>
                </ContentPage>
            )
        }
    }
}

