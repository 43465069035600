import React from 'react';
import { Translate } from 'react-translated'
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';

import './encyclopedia.css'

import * as CONST from './CultureConstant'
import { LanguageContext } from '../Common/LanguageContext';
import { MessageZone, ContentPage, ContentZone } from '../Common/CommonComponents';
import { ReferenceLink } from './EncyclopediaComponents';
import EncyclopediaService from './EncyclopediaService';
import BookDisplay from "./ItemDisplay/BookDisplay";
import AuthService from '../Users/AuthService';
import { AdsHorizontal } from '../Common/AdsDFP';
import EncyclopediaSearch from './EncyclopediaSearch';

export default class Culture extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            item: null,
            lastcall: null
        };
    }

    refreshDetails() {
        const culture = this.props.match.params.culture;
        this.cultureVillager = CONST.getCultureVillager(culture);

        const callkey = culture + "/" + this.context.language;

        if (callkey === this.state.lastcall)
            return;

        EncyclopediaService.getCultureDetail(culture, this.context.language).then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    item: result,
                    lastcall: callkey
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    lastcall: callkey,
                    error
                });
            }
        )
    }

    componentWillMount() {
        const culture = this.props.match.params.culture;
        this.cultureVillager = CONST.getCultureVillager(culture);
    }

    componentDidMount() {
        this.refreshDetails()
    }

    componentDidUpdate() {
        this.refreshDetails()
    }

    render() {
        const culture = this.props.match.params.culture;

        const { error, isLoaded, item } = this.state;

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else if (!isLoaded) {
            return <MessageZone message={"Loading..."} />
        } else {

            return (
                <ContentPage pageTitle={item.title}>
                    <EncyclopediaSearch location={this.props.location} />
                    <ContentZone>
                        <div style={{ float: "right", marginTop: "0", marginRight: "0" }}>
                            {AuthService.loggedIn() &&
                                <div>
                                    <Link to={this.props.location.pathname + "/edit"} style={{ float: "right", marginBottom: "2em" }} >
                                        <button className="btn btn-primary mr-2" >
                                            <FaEdit className="buttonicon" />
                                            <Translate text="button.editcontent" />
                                        </button>
                                    </Link>
                                </div>
                            }
                            <img alt="" className="d-none d-md-block"
                                style={{ float: "right", height: 400 }}
                                src={process.env.REACT_APP_ENCYLOPEDIA_IMG_ROOT + "villagers/" + this.cultureVillager + '.png'} />
                        </div>

                        <BookDisplay travelbookdata={item.travelbookdata} extradata={item.extradata} images={item.images} location={this.props.location} />
                        <div>
                            <span className="millenaire-header"> <Translate text="culture.villagelist" /></span>
                            <ItemList type="villages" culture={culture} handleItemChange={this.props.handleItemChange} />
                        </div>

                        <div style={{ width: "100%", marginBottom: "1em" }}>
                            <AdsHorizontal />
                        </div>

                        <div>
                            <span className="millenaire-header"> <Translate text="culture.villagerlist" /></span>
                            <ItemList type="villagers" culture={culture} handleItemChange={this.props.handleItemChange} />
                        </div>
                        <div>
                            <span className="millenaire-header"> <Translate text="culture.buildinglist" /></span>
                            <ItemList type="buildings" culture={culture} handleItemChange={this.props.handleItemChange} />
                        </div>
                        <div>
                            <span className="millenaire-header"> <Translate text="culture.tradegoodlist" /></span>
                            <ItemList type="tradegoods" culture={culture} handleItemChange={this.props.handleItemChange} />
                        </div>
                    </ContentZone>
                </ContentPage>
            );
        }
    }
}

class ItemList extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            lastcall: null
        };
    }

    refreshList() {

        const callkey = this.props.culture + this.props.type + this.context.language;

        if (callkey === this.state.lastcall)
            return;

        EncyclopediaService.getList(this.props.culture, this.props.type, this.context.language)
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result.records,
                        lastcall: this.props.culture + this.props.type + this.context.language
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }


    componentDidMount() {
        this.refreshList()
    }

    componentDidUpdate() {
        this.refreshList()
    }

    render() {

        const { error, isLoaded, items } = this.state;

        if (error) {
            return <div>Error: {error.message} culture: {this.props.culture}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {

            const rows = [];
            let catRows = [];
            let category = null;

            items.forEach((item) => {
                if (category !== item.category) {
                    if (category) {
                        rows.push(
                            <div style={{ width: "100%" }} key={category}><span className="millenaire-subheader">{category}</span></div>
                        )
                        rows.push(
                            <div className="row" key={category + "_rows"}>
                                {catRows}
                            </div>
                        )
                    }
                    category = item.category;
                    catRows = [];
                }
                catRows.push(
                    <VillageListItem item={item} type={this.props.type} key={this.props.type + "_" + item.itemkey} />
                )
            });

            rows.push(
                <div style={{ width: "100%" }} key={category}><span className="millenaire-subheader">{category}</span></div>
            )
            rows.push(
                <div className="row" key={category + "_rows"}>
                    {catRows}
                </div>
            )

            return (

                <div className="container">
                    <div className="row">
                        {rows}
                    </div>
                </div>
            );
        }
    }
}

class VillageListItem extends React.Component {
    render() {
        return (
            <div className="mb-2 mt-2 mr-2">
                <ReferenceLink culture={this.props.item.culture} type={this.props.type} itemkey={this.props.item.itemkey}
                    text={this.props.item.label} iconkey={this.props.item.iconkey} />
            </div>
        );
    }
}