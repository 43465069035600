import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FaEdit, FaPlus, FaPlusSquare, FaMinus, FaMinusSquare } from 'react-icons/fa';

import { MessageZone, ContentPage } from '../Common/CommonComponents';
import { LanguageContext } from '../Common/LanguageContext';
import TranslationService from './TranslationService';
import * as Icons from './TranslationIcons';

import './translation.css';

export default class TranslationHistory extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            fileHistory: null,
            lastcall: null,
            activePage: 1,
        };
        this.pageSize = 20;
        this.refreshHistory = this.refreshHistory.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        this.refreshHistory();
    }

    componentDidUpdate() {
        this.refreshHistory();
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
    }

    refreshHistory() {
        const filekey = this.props.match.params.filekey;
        const language = this.props.match.params.language;

        const callkey = "language/" + filekey + "/" + language;

        if (callkey === this.state.lastcall)
            return;

        TranslationService.getFileHistory(filekey, language)
            .then(
                (result) => {
                    this.setState({
                        fileHistory: result,
                        lastcall: callkey,
                    });
                    ReactTooltip.rebuild();
                },
                (error) => {
                    this.setState({
                        lastcall: callkey,
                        error
                    });
                }
            )
    }

    render() {

        const filekey = this.props.match.params.filekey;
        const language = this.props.match.params.language;

        const { error, fileHistory } = this.state;

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else {

            const rows = [];

            if (fileHistory !== null) {

                let nextVersion = null;

                fileHistory.forEach(version => {
                    if (nextVersion !== null)
                        nextVersion.prevVersion = version;
                    nextVersion = version;
                });

                fileHistory.forEach(version => {
                    rows.push(
                        <FileVersion language={language} version={version} prevVersion={version.prevVersion} />
                    )
                });
            }

            return (
                <ContentPage>
                    <div className="row">
                        <div className="col-12 mb-4 d-block">
                            <div className="bg-faded p-5 rounded">
                                <ReactTooltip place="top" type="light" effect="solid" />
                                <div className="millenaire-header mb-4">
                                    History for {language} / {filekey}
                                </div>
                                {rows}
                            </div>
                        </div>
                    </div>
                </ContentPage >
            );
        }
    }
}

class FileVersion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expended: false,
        };
        this.toggleExpension = this.toggleExpension.bind(this);
    }

    toggleExpension() {
        this.setState({
            expended: !this.state.expended
        })
    }

    render() {
        const { version, prevVersion, style } = this.props;

        const expended = this.state.expended;

        const diffRows = [];

        if (prevVersion !== undefined && expended) {
            for (let key in version.splitcontent.elements) {
                if (prevVersion.splitcontent.elements[key] === undefined) {
                    diffRows.push(
                        <DiffDisplay type="new_element" element={key} newvalue={version.splitcontent.elements[key][0]} />
                    );
                } else {
                    for (let i = 0; i < version.splitcontent.elements[key].length; i++) {
                        if (prevVersion.splitcontent.elements[key][i] === undefined) {
                            diffRows.push(
                                <DiffDisplay type="new_line" element={key} newvalue={version.splitcontent.elements[key][i]} />
                            );
                        } else if (prevVersion.splitcontent.elements[key][i] !== version.splitcontent.elements[key][i]) {
                            diffRows.push(
                                <DiffDisplay type="change" element={key} oldvalue={prevVersion.splitcontent.elements[key][i]} newvalue={version.splitcontent.elements[key][i]} />
                            );
                        }
                    }
                }
            }
            for (let key in prevVersion.splitcontent.elements) {
                if (version.splitcontent.elements[key] === undefined) {
                    diffRows.push(
                        <DiffDisplay type="removed_element" element={key} oldvalue={prevVersion.splitcontent.elements[key][0]} />
                    );
                } else {
                    for (let i = 0; i < prevVersion.splitcontent.elements[key].length; i++) {
                        if (version.splitcontent.elements[key][i] === undefined) {
                            diffRows.push(
                                <DiffDisplay type="removed_line" element={key} oldvalue={prevVersion.splitcontent.elements[key][i]} />
                            );
                        }
                    }
                }
            }
        }

        return (
            <div className="lightbeigebg rounded p-2 m-2" style={style}>
                {prevVersion !== undefined &&
                    <div style={{ float: "right", height: "100", display: "flex", justifyItems: "center" }} >
                        <button className="btn btn-primary" onClick={this.toggleExpension}>{expended ? "Hide" : "Show"}</button>
                    </div>
                }
                <div style={{ display: "flex", justifyContent: "left" }}>
                    {version.author !== null &&
                        <div style={{ margin: "10px" }}><Icons.Author className="cardicon" />{version.author}</div>
                    }
                    {version.version !== null &&
                        <div style={{ margin: "10px" }}><Icons.Version className="cardicon" />
                            {version.version}
                        </div>
                    }
                    <div style={{ margin: "10px" }}><Icons.Published className="cardicon" />{version.createdon}</div>
                    <div style={{ margin: "10px" }}><Icons.LineNumber className="cardicon" />{version.elementcount}</div>

                </div>

                {diffRows.length > 0 && expended &&
                    diffRows
                }
            </div>
        )
    }
}

class DiffDisplay extends React.Component {
    render() {
        const { type, element, oldvalue, newvalue } = this.props;

        return (
            <div>
                <b>
                    {type === "new_element" &&
                        <FaPlus data-tip="New element" />
                    }
                    {type === "new_line" &&
                        <FaPlusSquare data-tip="New line" />
                    }
                    {type === "removed_element" &&
                        <FaMinus data-tip="Removed element" />
                    }
                    {type === "removed_line" &&
                        <FaMinusSquare data-tip="Removed line" />
                    }
                    {type === "change" &&
                        <FaEdit data-tip="Change" />
                    }
                    &nbsp;
                    {element}</b>
                <p>
                    <span style={{ color: "red" }}>
                        {oldvalue}&nbsp;
                    </span>
                    <span style={{ color: "green" }}>
                        {newvalue}
                    </span>
                </p>
            </div >
        )
    }
}