import React from 'react';
import { Translate } from 'react-translated'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import renderHTML from 'react-render-html';
import { ReactGhLikeDiff } from 'react-gh-like-diff';
import 'react-gh-like-diff/lib/diff2html.min.css';

export default class ItemHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showHistory: false,
        };
        this.toggleDisplayHistory = this.toggleDisplayHistory.bind(this);
    }

    toggleDisplayHistory() {
        this.setState({
            showHistory: !this.state.showHistory,
        })
    }

    render() {

        const { history } = this.props;

        const historyJSX = [];

        let previousHistory = null;

        history.records.slice().reverse().forEach((item) => {
            item.previous = previousHistory;
            previousHistory = item;
        })

        history.records.forEach((item) => {
            historyJSX.push(
                <ItemHistoryVersion item={item} restoreHistory={this.props.restoreHistory} key={item.createdon} previousHistory={item.previous} />
            )
        })

        return (
            <div className="container" style={{ width: "100%", marginTop: "2em" }}>
                <button type="button" className="btn btn-primary" style={{ float: "right" }} onClick={() => { this.toggleDisplayHistory() }}>
                    {!this.state.showHistory &&
                        <Translate text="contentedit.show" />
                    }
                    {this.state.showHistory &&
                        <Translate text="contentedit.hide" />
                    }
                </button>
                <h2><Translate text={"contentedit.history"} /></h2>

                {this.state.showHistory &&
                    <div className="row">{historyJSX}
                    </div>
                }
            </div>
        )
    }
}


class ItemHistoryVersion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            descShown: false,
        };
        this.toggleDisplayDesc = this.toggleDisplayDesc.bind(this);
    }

    toggleDisplayDesc() {
        this.setState({
            descShown: !this.state.descShown,
        })
    }

    render() {
        return (

            <div className="p-2" style={{ width: "100%" }}>
                <div className="bg-faded p-1 rounded">
                    <div style={{ height: '3em', width: '100%', display: 'flex' }}>

                        <em style={{ marginBottom: 'auto', marginTop: 'auto' }}>{this.props.item.createdon} - {this.props.item.author}</em>

                        <div style={{ marginBottom: 'auto', marginTop: 'auto', marginLeft: 'auto', marginRight: '5px' }}>
                            <button type="button" className="btn btn-primary" onClick={() => { this.toggleDisplayDesc() }}>
                                {!this.state.descShown &&
                                    <Translate text="contentedit.show" />
                                }
                                {this.state.descShown &&
                                    <Translate text="contentedit.hide" />
                                }
                            </button>
                            <button style={{ marginLeft: '5px' }} type="button" className="btn btn-primary" onClick={() => { this.props.restoreHistory(this.props.item) }}>
                                <Translate text="contentedit.restore" /></button>
                        </div>

                    </div>
                    {this.state.descShown && this.props.item.desc !== "" && renderHTML(this.props.item.desc)}

                    {this.state.descShown && this.props.item.desc !== "" && this.props.previousHistory != null &&
                        <ReactGhLikeDiff
                            past={this.props.previousHistory.desc}
                            current={this.props.item.desc}
                            showFiles={false}
                            options={{
                                outputFormat: "line-by-line",
                                originalFileName: "desc",
                                updatedFileName: "desc"
                            }}
                        />

                    }

                    {this.state.descShown && this.props.item.desc === "" &&
                        <em><Translate text="contentedit.nocontent" /></em>
                    }
                </div>
            </div>
        )
    }
}