import React from 'react';
import { Redirect } from 'react-router-dom'
import ImageMapper from 'react-image-mapper';
import { Translate } from 'react-translated'

import * as CONST from './CultureConstant'
import { MessageZone, ContentPage } from '../Common/CommonComponents';
import { MillenaireIcon } from './EncyclopediaComponents';
import EncyclopediaService from './EncyclopediaService';
import { LanguageContext } from '../Common/LanguageContext';

import worldmap from './img/worldmap.png';
import { AdsHorizontal } from '../Common/AdsDFP';

import * as URLs from "../URLS"
import EncyclopediaSearch from './EncyclopediaSearch';

export default class MainScreen extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            cultures: [],
            lastcall: null
        };
        this.cultureNames = {};
    }

    componentDidMount() {
        this.refreshList()
    }

    componentDidUpdate() {
        this.refreshList()
    }

    refreshList() {
        const callkey = "cultures/" + this.context.language;

        if (callkey === this.state.lastcall)
            return;

        EncyclopediaService.getCultureList(this.context.language)
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        cultures: result.records,
                        lastcall: callkey,
                    });

                    // Storing the received names for use in the world map tool tips
                    result.records.forEach((culture) => {
                        this.cultureNames[culture.itemkey] = culture.label;
                    });

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        lastcall: callkey,
                        error
                    });
                }
            )
    }

    render() {

        const { error, isLoaded, cultures } = this.state;

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else if (!isLoaded) {
            return <MessageZone message={"Loading..."} />
        } else {

            const rows = [];

            cultures.forEach((culture) => {
                rows.push(
                    <CultureCard culture={culture} key={culture.itemkey} />
                )
            });
            return (
                <ContentPage>
                    <div className="row">
                        <div className="col-12 mb-4 d-block">
                            <div className="bg-faded p-5 rounded">
                                <div className="millenaire-header mb-4">
                                    <Translate text="header.encyclopedia" />
                                </div>
                                <p><Translate text="encyclopedia.desc" /></p>
                            </div>
                        </div>
                    </div>
                    <EncyclopediaSearch location={this.props.location} />
                    <div className="row">
                        <div className="col-12 mb-4 d-none d-md-block">
                            <div className="bg-faded p-5 rounded">
                                <WorldMap cultureNames={this.cultureNames} />
                            </div>
                        </div>

                        <div style={{ width: "100%", marginBottom: "1em", display: "flex", justifyContent: "center" }}>
                            <AdsHorizontal />
                        </div>

                        {rows}
                    </div>
                </ContentPage>
            );
        }
    }
}

class WorldMap extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        };
        this.holdingDiv = React.createRef();
        this.updateDimensions = this.updateDimensions.bind(this);
        this.enterArea = this.enterArea.bind(this);
        this.leaveArea = this.leaveArea.bind(this);
        this.getTipPosition = this.getTipPosition.bind(this);

        this.IMAGE_WIDTH = 1285;
    }

    clicked(area) {
        this.setState({ redirectculture: area.name, redirect: true });
    }

    updateDimensions() {
        if (this.holdingDiv !== undefined) {
            this.setState({ targetMapWidth: this.holdingDiv.current.offsetWidth });
        }
    }

    enterArea(area) {
        this.setState({ hoveredArea: area });
    }

    leaveArea(area) {
        this.setState({ hoveredArea: null });
    }

    getTipPosition(area) {
        if (!area) return { top: 0, left: 0 };
        // Calculate centroid
        const n = area.coords.length / 2;
        let { y, x } = area.coords.reduce(({ y, x }, val, idx) => {
            return !(idx % 2) ? { y, x: x + (val / n) } : { y: y + (val / n), x };
        }, { y: 0, x: 0 });

        y = y / this.IMAGE_WIDTH * this.holdingDiv.current.offsetWidth;
        x = x / this.IMAGE_WIDTH * this.holdingDiv.current.offsetWidth;

        y = y + 50;

        return { top: `${y}px`, left: `${x}px` };
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {

        if (this.state.redirect) {
            return <Redirect push to={URLs.encyclopedia_culture + "/" + this.state.redirectculture} />;
        }

        const ZONE_WIDTH = 60;
        const ZONE_HEIGHT = 100;

        const MAP = {
            name: "my-map",
            areas: [
                { name: "inuits", shape: "rect", coords: [59, 29, 59 + ZONE_WIDTH, 29 + ZONE_HEIGHT] },
                { name: "norman", shape: "rect", coords: [546, 89, 546 + ZONE_WIDTH, 89 + ZONE_HEIGHT] },
                { name: "indian", shape: "rect", coords: [866, 207, 866 + ZONE_WIDTH, 207 + ZONE_HEIGHT] },
                { name: "japanese", shape: "rect", coords: [1093, 149, 1093 + ZONE_WIDTH, 149 + ZONE_HEIGHT] },
                { name: "mayan", shape: "rect", coords: [171, 231, 171 + ZONE_WIDTH, 231 + ZONE_HEIGHT] },
                { name: "byzantines", shape: "rect", coords: [650, 121, 650 + ZONE_WIDTH, 121 + ZONE_HEIGHT] },
                { name: "seljuk", shape: "rect", coords: [723, 154, 723 + ZONE_WIDTH, 154 + ZONE_HEIGHT] },
            ]
        }
        return (
            <div>
                <div className="millenaire-header mb-4">
                    <Translate text="encyclopedia.worldmap" />
                </div>
                <div style={{ display: "flex" }}>
                    <div ref={this.holdingDiv} style={{ width: "100%", position: "relative" }}>
                        <ImageMapper src={worldmap} map={MAP} width={this.state.targetMapWidth} imgWidth={this.IMAGE_WIDTH}
                            lineWidth={0} strokeColor={"rgba(0,0,0,0)"} fillColor={"rgba(0,0,0,0)"}
                            onClick={area => this.clicked(area)}
                            onMouseEnter={area => this.enterArea(area)}
                            onMouseLeave={area => this.leaveArea(area)}
                        />
                        {
                            this.state.hoveredArea &&
                            <span className="worldmaptooltip" style={{ ...this.getTipPosition(this.state.hoveredArea) }}>
                                {this.state.hoveredArea && this.props.cultureNames[this.state.hoveredArea.name]}
                            </span>
                        }
                    </div>
                </div>
            </div>
        );
    }
}


class CultureCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false
        };
    }

    handleCultureChange() {
        this.setState({ redirect: true });
    }

    render() {
        const culture = this.props.culture;

        if (this.state.redirect) {
            return <Redirect push to={URLs.encyclopedia_culture + "/" + culture.itemkey} />;
        }
        return (
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                <div className="bg-faded p-3 rounded" align="center">
                    <div className="mb-4" style={{ margin: "auto", display: "flex" }}>
                        <MillenaireIcon iconkey={culture.iconkey} />
                        <span className="millenaire-subheader-responsive vertical-align">{culture.label}</span>
                    </div>

                    <img alt="" className="img-fluid" src={process.env.REACT_APP_ENCYLOPEDIA_IMG_ROOT + "villagers/" + CONST.getCultureVillager(culture.itemkey) + '_main.png'} />

                    <button type="button" style={{ margin: "auto", display: "flex" }} className="btn btn-primary" onClick={() => { this.handleCultureChange() }}>
                        <Translate text="culture.view" /></button>
                </div>
            </div>
        );
    }
}