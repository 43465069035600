import React from 'react';

import './library.css';
import './pagination.css';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import renderHTML from 'react-render-html';

import { FaEdit, FaStar, FaRegStar } from 'react-icons/fa';

import AuthService from '../Users/AuthService';

import * as Icons from './ContentIcons';
import * as URLs from '../URLS';

export default class ContentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.pageSize = 12;
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
    }

    render() {
        const start = (this.state.activePage - 1) * this.pageSize;
        const end = (this.state.activePage) * this.pageSize;
        const rows = [];
        this.props.contents.slice(start, end).forEach((content) => {
            rows.push(
                <ContentCard content={content} key={content.contentid} location={this.props.location} listActions={this.props.listActions} />
            )
        });

        return (
            <div className="row">
                <ListPagination itemnumber={this.props.contents.length} activePage={this.state.activePage}
                    handlePageChange={this.handlePageChange} pageSize={this.pageSize} />
                {rows}
                <ListPagination itemnumber={this.props.contents.length} activePage={this.state.activePage}
                    handlePageChange={this.handlePageChange} pageSize={this.pageSize} />
            </div>
        )
    }
}

class ListPagination extends React.Component {
    render() {
        return (
            <div className="col-12 mb-4">
                <div className="bg-faded p-3 rounded" align="center" style={{ display: "flex", justifyContent: "space-around" }}>
                    <Pagination
                        activePage={this.props.activePage}
                        itemsCountPerPage={this.props.pageSize}
                        totalItemsCount={this.props.itemnumber}
                        pageRangeDisplayed={20}
                        onChange={this.props.handlePageChange}
                    />
                </div>
            </div>
        )
    }
}

class ContentCard extends React.Component {

    constructor(props) {
        super(props);
        this.starContent = this.starContent.bind(this);
        this.unstarContent = this.unstarContent.bind(this);
    }


    starContent() {
        this.props.listActions.starContent(this.props.content.contentid);
    }

    unstarContent() {
        this.props.listActions.unstarContent(this.props.content.contentid);
    }

    render() {

        const content = this.props.content;

        let description = content.description.replace(/\[[^\]]+\]/g, '');

        if (description.length > 100) {
            description = description.substring(0, 100) + "...";
        }

        const lastUpdated = new Date(content.lastmodified);

        let showEdit = false;

        if (AuthService.isUserAdmin() || AuthService.getLogin() === content.author) {
            showEdit = true;
        }

        return (
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                <div className={"bg-faded p-3 rounded " + (content.confirmed === "0" ? "lightbeigebg" : "")} align="center">
                    {showEdit &&
                        <div style={{ float: "right", marginTop: "0", marginRight: "0", marginLeft: "1em" }}>
                            <Link to={URLs.library_detail + "/" + content.contentid + "/edit"} >
                                <FaEdit className="activeicon" />
                            </Link>
                        </div>
                    }
                    <div style={{ margin: "auto", display: "flex" }}>

                        <span className="millenaire-subheader-responsive vertical-align">
                            {AuthService.loggedIn() && content.starred === "1" &&
                                <FaStar style={{ color: "#ffc107", width: "24px" }} data-for="iconsToolTip" data-tip="You starred this content."
                                    onClick={this.unstarContent} />
                            }
                            {AuthService.loggedIn() && content.starred !== "1" &&
                                <FaRegStar style={{ color: "grey", width: "24px" }} data-for="iconsToolTip" data-tip="You can star this content."
                                    onClick={this.starContent} />
                            }
                            <Link to={URLs.library_detail + "/" + content.contentid} >
                                {content.title}
                                {content.confirmed === "0" &&
                                    <b>(unconfirmed)</b>
                                }
                            </Link>
                        </span>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <div className="cardattribute"><Icons.Author />{content.author}</div>
                        <div className="cardattribute"><Icons.Version />{content.version}</div>
                        <div className="cardattribute"><Icons.LastUpdated />{lastUpdated.toLocaleDateString()}</div>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <div className="cardattribute"><Icons.Category />{content.catlabel}</div>
                        <div className="cardattribute"><Icons.Culture />{content.cultlabel}</div>
                    </div>
                    <div style={{ width: "100%", textAlign: "left" }}>
                        {renderHTML(description)}
                    </div>
                    {content.previewname !== null &&
                        <img alt="" className="img-fluid m-1" src={process.env.REACT_APP_LIBRARY_RES_ROOT + "img/" + content.previewname} />
                    }
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <div className="cardattribute"><Icons.Views />{content.nbview}</div>
                        <div className="cardattribute"><Icons.Downloads />{content.nbdl}</div>
                        <div className="cardattribute"><Icons.Stars />{content.nbstars}</div>
                        <div className="cardattribute"><Icons.Comments />{content.nbcomments}</div>
                    </div>
                </div>
            </div>
        )
    }
}