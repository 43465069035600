import React from 'react';
import { Translate } from 'react-translated';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { FaEdit, FaRedo } from 'react-icons/fa';

import { LanguageContext } from '../../Common/LanguageContext';
import LanguageConfig from '../../Common/LanguageConfig';
import EncyclopediaService from '../EncyclopediaService';
import { ContentPage, ContentZone, MessageZone } from '../../Common/CommonComponents';

import * as URLs from "../../URLS";

export default class NewImagesList extends React.Component {

    static contextType = LanguageContext;

    constructor() {
        super();
        this.state = {
            error: null,
            isLoaded: false,
            changes: null,
            cultures: [],
            types: []
        };
        this.forceRefresh = this.foreceRefresh.bind(this);
    }

    foreceRefresh() {
        this.setState({
            lastcall: null,
        });
        EncyclopediaService.invalidateCacheImagesList();
        this.refreshInfos();
    }

    refreshInfos() {

        const callkey = this.context.language;

        if (callkey === this.state.lastcall)
            return;

        EncyclopediaService.getImagesList().then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    images: result.items,
                    cultures: result.cultures,
                    types: result.types,
                    lastcall: callkey,
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    componentDidMount() {
        this.refreshInfos()
    }

    componentDidUpdate() {
        this.refreshInfos()
    }

    render() {

        const { error, isLoaded, images } = this.state;

        const cultureHeader = [];
        this.state.cultures.forEach(culture => (
            cultureHeader.push(
                <option value={culture}>{culture}</option>
            )
        ))

        const typeHeader = [];
        this.state.types.forEach(culture => (
            typeHeader.push(
                <option value={culture}>{culture}</option>
            )
        ))

        const columns = [{
            Header: 'Culture',
            accessor: 'culture',
            Filter: ({ filter, onChange }) =>
                (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">Show All</option>
                        {cultureHeader}
                    </select>
                ),
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                } else {
                    return row[filter.id] === filter.value;
                }
            },
        }, {
            Header: 'Type',
            accessor: 'type',
            Filter: ({ filter, onChange }) =>
                (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">Show All</option>
                        {typeHeader}
                    </select>
                ),
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                } else {
                    return row[filter.id] === filter.value;
                }
            },
        }, {
            Header: 'Item',
            accessor: 'itemkey',
            filterMethod: (filter, row) =>
                row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
        }, {
            Header: 'Legend (EN)',
            accessor: 'legend_en',
            filterMethod: (filter, row) =>
                row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
        }, {
            Header: 'Legend (FR)',
            accessor: 'legend_fr',
            filterMethod: (filter, row) =>
                row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
        }, {
            Header: 'Image',
            accessor: 'previewname',
            filterable: false,
            Cell: row => (
                <ImageCell previewname={row.value}
                    culture={row.original.culture} type={row.original.type} />
            ),
        }, {
            Header: 'Author',
            accessor: 'author',
            filterMethod: (filter, row) =>
                row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
        }, {
            Header: 'Created On',
            accessor: 'createdon',
            Cell: row => (
                <span>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(Date.parse(row.value))}</span>
            ),
            filterable: false,
        }, {
            Header: '',
            accessor: null,
            Cell: row => (
                <ActionsCell location={this.props.location}
                    culture={row.original.culture} type={row.original.type} itemkey={row.original.itemkey} />
            ),
            filterable: false,
            maxWidth: 45
        },
        ]

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else if (!isLoaded) {
            return <MessageZone message={"Loading..."} />
        } else {
            return (
                <ContentPage pageTitle={LanguageConfig.getText("newimageslist.title")}>
                    <ContentZone>
                        <div style={{ display: "flex" }}>
                            <span className="millenaire-header">
                                <Translate text="newimageslist.title" />
                            </span>
                            <button style={{ marginRight: 0, marginLeft: "auto" }} className="btn btn-primary mb-2" onClick={() => { this.forceRefresh() }}>
                                <FaRedo className="buttonicon" />
                                <Translate text="data.refresh" />
                            </button>
                        </div>
                        <ReactTooltip place="top" type="light" effect="solid" id="dataTableToolTip"
                            html="true" className="changetooltip"
                            getContent={(dataTip) => `${dataTip}`} />
                        <ReactTable className="-striped -highlight"
                            data={images}
                            columns={columns}
                            filterable={true}
                        />
                    </ContentZone>
                </ContentPage>
            )
        }
    }
}

class ActionsCell extends React.Component {
    render() {
        if (this.props.type === "cultures") {
            return (
                <Link to={URLs.encyclopedia_culture + "/" + this.props.culture + "/edit"}>
                    <FaEdit className="activeicon" />
                </Link>
            )
        } else {
            return (
                <Link to={URLs.encyclopedia_culture + "/" + this.props.culture + "/" + this.props.type + "/" + this.props.itemkey + "/edit"}>
                    <FaEdit className="activeicon" />
                </Link>
            )
        }
    }
}

class ImageCell extends React.Component {
    render() {

        const imgUrl = process.env.REACT_APP_ENCYLOPEDIA_IMG_ROOT + this.props.culture + "/" + this.props.type + "/" + this.props.previewname;

        return (
            <img alt=""
                data-for="dataTableToolTip" data-tip={"<img src=\""+imgUrl+"\" />"}
                style={{ maxWidth: 32, maxHeight: 32 }}
                src={imgUrl}
            />
        )
    }
}