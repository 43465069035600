import React from 'react';
import { Translate, Translator } from 'react-translated'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';

import { FaBook } from 'react-icons/fa';

import { LanguageContext } from '../../Common/LanguageContext';
import LanguageConfig from '../../Common/LanguageConfig';
import EncyclopediaService from '../EncyclopediaService';
import EncyclopediaAutoSuggest from '../EncyclopediaAutoSuggest';

import * as URLs from "../../URLS"

export default class DescEditor extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            noCustomDesc: false
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggeRemoveDesc = this.toggeRemoveDesc.bind(this);
        this.refreshEditor = this.refreshEditor.bind(this);
        this.previousCurrentDesc = null;
    }

    handleChange(editorState) {
        this.setState({
            editorState,
        });
    }

    toggeRemoveDesc() {
        this.setState({
            noCustomDesc: !this.state.noCustomDesc,
        });
    }

    handleFormSubmit(e) {
        e.preventDefault();

        const culture = this.props.culture;
        const type = this.props.type;
        const itemkey = this.props.itemkey;

        let desc;

        if (this.state.noCustomDesc)
            desc = null;
        else
            desc = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));

        if (itemkey !== undefined) {
            EncyclopediaService.postItemDetail(culture, type, itemkey, this.context.language, desc).then(() => {
                this.props.forceRefresh();
            });
        } else {
            EncyclopediaService.postItemDetail(culture, "cultures", culture, this.context.language, desc).then(() => {
                this.props.forceRefresh();
            });
        }
    }

    componentDidMount() {
        this.refreshEditor()
    }

    componentDidUpdate() {
        this.refreshEditor()
    }

    refreshEditor() {
        if (this.previousCurrentDesc !== this.props.currentDesc) {
            const contentBlock = htmlToDraft(this.props.currentDesc);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({
                    editorState: editorState,
                });
                this.previousCurrentDesc = this.props.currentDesc;
            }
        }
    }

    render() {

        const { editorState } = this.state;

        return (
            <div>
                <h2><Translate text={"contentedit.title"} /></h2>
                <div style={{ width: "100%", height: "2em", marginTop: "1em" }}>
                    <label>
                        <input
                            type="checkbox"
                            checked={this.state.noCustomDesc}
                            onChange={this.toggeRemoveDesc} style={{ marginRight: "5px" }} />
                        <Translate text={"contentedit.removedesc"} />
                    </label>
                </div>

                <form>
                    <div style={{ width: "100%" }}>
                        {!this.state.noCustomDesc &&
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName "
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
                                    image: {
                                        urlEnabled: false,
                                        uploadEnabled: false,
                                    },
                                }}
                                toolbarCustomButtons={[<EncyclopediaLinkTool />]}

                                onEditorStateChange={this.handleChange}
                            />
                        }
                        {
                            this.state.useTravelBookDesc &&
                            <em>{this.props.travelBookDesc}</em>
                        }
                    </div>
                    <Translator>
                        {({ translate }) => (
                            <div style={{ width: "100%", height: "2em", marginTop: "1em" }}>
                                <input
                                    className="btn btn-primary"
                                    style={{ float: "right" }}
                                    value={translate({
                                        text: 'contentedit.submit'
                                    })}
                                    onClick={this.handleFormSubmit}
                                    type="submit"
                                />
                            </div>
                        )}
                    </Translator>
                </form>
            </div>
        )
    }
}

class EncyclopediaLinkTool extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        editorState: PropTypes.object.isRequired,
        modalHandler: PropTypes.object,
        config: PropTypes.object,
        translations: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
        this.doExpand = this.doExpand.bind(this);
        this.toggleExpand = this.toggleExpand.bind(this);
        this.addMillLink = this.addMillLink.bind(this);
    }

    componentWillMount() {
        const { modalHandler } = this.props;
        modalHandler.registerCallBack(this.expandCollapse);
    }

    componentWillUnmount() {
        const { modalHandler } = this.props;
        modalHandler.deregisterCallBack(this.expandCollapse);
    }

    onExpandEvent = () => {
        this.signalExpanded = !this.state.expanded;
    };

    toggleExpand() {
        this.setState({
            expanded: !this.state.expanded,
        });
    };

    doExpand() {
        this.setState({
            expanded: true,
        });
    };

    expandCollapse = () => {
        this.setState({
            expanded: this.signalExpanded,
        });
        this.signalExpanded = false;
    }

    doCollapse = () => {
        this.setState({
            expanded: false,
        });
    };

    renderModal = () => {
        return (
            <div className="rdw-colorpicker-modal" style={{ width: "300px", height: "60px", left: "-270px" }}        >
                <EncyclopediaAutoSuggest onSuggestionSelected={this.onSuggestionSelected} autoFocus />
            </div>
        );
    };

    onSuggestionSelected = (e, { suggestion }) => {
        let url;

        if (suggestion.category.type === "cultures") {
            url = URLs.encyclopedia_culture + "/" + suggestion.category.culture;
        } else {
            url = URLs.encyclopedia_culture + "/" + suggestion.category.culture + "/" + suggestion.category.type + "/" + suggestion.itemkey;
        }

        this.addMillLink(suggestion.label, url);
        this.doCollapse();
    }

    addMillLink = (label, link) => {
        const { editorState, onChange } = this.props;

        const entityKeyLink = editorState
            .getCurrentContent()
            .createEntity("LINK", "MUTABLE", {
                url: link,
                targetOption: null
            })
            .getLastCreatedEntityKey();

        let contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            label,
            editorState.getCurrentInlineStyle(),
            entityKeyLink
        );

        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    };

    render() {
        const { expanded } = this.state;
        return (
            <div className="rdw-remove-wrapper">
                <div className="rdw-option-wrapper" onClick={this.onExpandEvent} title={LanguageConfig.getText("contentedit.encyclopedialink")} >
                    <FaBook style={{ color: "#925c17" }} />
                </div>
                {expanded ? this.renderModal() : undefined}
            </div>
        );
    }
}