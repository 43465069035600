import React, { Component } from 'react';
import { AdSlot } from 'react-dfp';
import { withRouter } from 'react-router'

import * as URLs from '../URLS';

class AdsVerticalRaw extends Component {
    render() {
        const current_url = this.props.location.pathname;

        let code = 'MillenaireHome';
        if (current_url.startsWith(URLs.encyclopedia_home)) {
            code = 'MillEncyclopedia';
        } else if (current_url.startsWith(URLs.library_home)) {
            code = 'MillLibrary';
        }

        return <div className="ads" style={{maxWidth:"160px",maxHeight:"600px"}}><AdSlot sizes={[[160, 600], [120, 600]]} adUnit={code} /></div>
    }
}

class AdsHorizontalRaw extends Component {
    render() {
        const current_url = this.props.location.pathname;

        let code = 'MillenaireHome';
        if (current_url.startsWith(URLs.encyclopedia_home)) {
            code = 'MillEncyclopedia';
        } else if (current_url.startsWith(URLs.library_home)) {
            code = 'MillLibrary';
        }

        return <div className="ads" style={{maxWidth:"970px",maxHeight:"90px"}}><AdSlot sizes={[[970, 90], [728, 90], [468, 60]]}
            sizeMapping={[{ viewport: [1024, 768], sizes: [[970, 90], [728, 90]] },
            { viewport: [400, 768], sizes: [[468, 60]] }]} adUnit={code} /></div>
    }
}

class AdsInArticleRaw extends Component {
    render() {
        return <AdSlot sizeMapping={[{ viewport: [1024, 768], sizes: [[970, 90], [728, 90]] }, { viewport: [400, 768], sizes: [[468, 60]] }]} adUnit='MillENcyclopediaF' />
    }
}

var AdsVertical = withRouter(AdsVerticalRaw);
var AdsHorizontal = withRouter(AdsHorizontalRaw);
var AdsInArticle = withRouter(AdsInArticleRaw);

export { AdsHorizontal, AdsInArticle, AdsVertical }