import React from 'react';
import { Translate } from 'react-translated';
import { Link } from 'react-router-dom';

import * as URLs from '../URLS'

import logo_wiki from './img/wiki_small.png'
import logo_encyclopedia from './img/encyclopedia_small.png'
import logo_library from './img/library_small.png'
import logo_forum from './img/forum_small.png'
import logo_discord from './img/discord_small.png'

export default class MiddleHeader extends React.Component {
    render() {
        return (
            <section className="external-items clearfix d-none d-lg-block">
                <div className="container">
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-2 mx-auto rounded lightbeigebg vertical-align">

                            <a href="wiki/Millénaire_Wiki"><img className="img-fluid about-heading-img mb-3 mb-lg-0" src={logo_wiki}
                                alt="" /></a><br />
                            <a href="wiki/Millénaire_Wiki"><Translate text="ind_wiki" /></a>
                        </div>
                        <div className="col-2 mx-auto rounded lightbeigebg vertical-align">
                            <Link to={URLs.encyclopedia_home} >
                                <img className="img-fluid about-heading-img mb-3 mb-lg-0" src={logo_encyclopedia}
                                    alt="" />
                            </Link>
                        </div>
                        <div className="col-2 mx-auto rounded lightbeigebg vertical-align">
                            <Link to={URLs.library_home} >
                                <img className="img-fluid about-heading-img mb-3 mb-lg-0" src={logo_library} alt="" /><br />
                                <Translate text="ind_library" />
                            </Link>
                        </div>
                        <div className="col-2 mx-auto rounded lightbeigebg vertical-align">
                            <a href="forum">
                                <img className="img-fluid about-heading-img mb-3 mb-lg-0" src={logo_forum} alt="" /></a><br />
                            <a href="forum"><Translate text="ind_forum" /></a>
                        </div>
                        <div className="col-2 mx-auto rounded lightbeigebg vertical-align">
                            <a href="https://discord.gg/nCXjd4s">
                                <img className="img-fluid about-heading-img mb-3 mb-lg-0" src={logo_discord} alt="" /></a><br />
                            <a href="https://discord.gg/nCXjd4s"><Translate text="ind_discord" /></a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}