import React from 'react';
import { Translate } from 'react-translated'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import { FaHome, FaChevronLeft } from 'react-icons/fa';
import { withRouter } from 'react-router'
import LanguageConfig from './LanguageConfig';
import * as URLs from '../URLS';

class HomeButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false
    };
  }

  goHome() {
    this.setState({ redirect: true });
  }

  render() {

    let destURL = URLs.home;
    let buttonLabel = "button.home";

    if (this.props.location.pathname.startsWith(URLs.encyclopedia_home)) {
      destURL = URLs.encyclopedia_home;
      buttonLabel = "button.encyclopedia_home";
    } else if (this.props.location.pathname.startsWith(URLs.library_home)) {
      destURL = URLs.library_home;
      buttonLabel = "button.library_home";
    } else if (this.props.location.pathname.startsWith(URLs.translationcentre_home)) {
      destURL = URLs.translationcentre_home;
      buttonLabel = "Translation Centre Home";
    }

    if (this.state.redirect) {
      return <Redirect push to={destURL} />;
    }

    return (
      <button type="button" className="btn btn-primary mb-2 mr-2" onClick={() => { this.goHome() }}>
        <FaHome className="buttonicon" />
        <Translate text={buttonLabel} />
      </button>
    );
  }
}

class BackButton extends React.Component {
  goBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <button type="button" className="btn btn-primary mb-2 mr-2" onClick={() => { this.goBack() }}>
        <FaChevronLeft className="buttonicon" />
        <Translate text="button.return" />
      </button>
    );
  }
}

class MessageZone extends React.Component {
  render() {
    return (
      <section className="page-section clearfix">
        <div className="container">
          <div className="row maincontentzone">
            <div className="bg-faded p-5 rounded" style={{ width: "100%" }}>
              {this.props.message}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

class ContentPageRaw extends React.Component {
  static propTypes = {
    maxWidth: PropTypes.string
  }

  static defaultProps = {
    maxWidth: "100%"
  };

  componentDidMount() {

    let baseTitle = LanguageConfig.getText('site.title');

    const current_url = this.props.location.pathname;

    if (current_url.startsWith(URLs.encyclopedia_home)) {
      baseTitle = LanguageConfig.getText('header.encyclopedia');
    } else if (current_url.startsWith(URLs.library_home)) {
      baseTitle = LanguageConfig.getText('header.library');
    }


    if (this.props.pageTitle !== undefined && this.props.pageTitle !== null) {
      document.title = baseTitle + " > " + this.props.pageTitle;
    } else {
      document.title = baseTitle;
    }
  }

  render() {
    return (
      <div>
        <section className="page-section clearfix d-flex justify-content-center">
          <div className="maincontent">
            <div className="d-flex justify-content-center">
              <div style={{ maxWidth: this.props.maxWidth, width: "100%" }}>
                <div style={{ display: "flex", justifyItems: "flex-start" }}>
                  {this.props.history !== undefined &&
                    <BackButton history={this.props.history} />
                  }
                  {this.props.location !== undefined && this.props.location.pathname !== URLs.encyclopedia_home &&
                    this.props.location.pathname !== URLs.library_home &&
                    <HomeButton location={this.props.location} />
                  }
                </div>
                {this.props.children}
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

class ContentZone extends React.Component {
  render() {
    return (
      <div className="row maincontentzone">
        <div className="bg-faded p-3 p-md-5 rounded" style={{ width: "100%" }}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

var ContentPage = withRouter(ContentPageRaw);

export { BackButton, HomeButton, MessageZone, ContentPage, ContentZone }