import React from 'react';
import { Translate } from 'react-translated';

import { ContentPage, ContentZone } from '../Common/CommonComponents';
import AuthService from './AuthService';
import LanguageConfig from '../Common/LanguageConfig';

import * as URLs from '../URLS'

export default class LogoutScreen extends React.Component {
    constructor() {
        super();
        this.logout = this.logout.bind(this);
    }

    componentWillMount() {
        if (!AuthService.loggedIn())
            this.props.history.replace(URLs.home);
    }

    render() {

        return (
            <ContentPage pageTitle={LanguageConfig.getText("logout.title")} maxWidth="500px">
                <ContentZone>
                    <h1><Translate text={"logout.title"} /></h1>
                    <p><Translate text={"logout.desc"} /></p>

                    <div style={{ width: "100%", height: "2em", marginTop: "1em" }}>
                        <button
                            className="btn btn-primary"
                            style={{ float: "right", width: "150px" }}
                            onClick={this.logout}
                        >
                            <Translate text="logout.logout" />
                        </button>
                    </div>
                </ContentZone>
            </ContentPage>
        )
    }

    logout() {
        AuthService.logout();
        this.props.history.goBack();
    }
}