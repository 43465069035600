export const home = process.env.REACT_APP_WEB_ROOT;

export const home_history = process.env.REACT_APP_WEB_ROOT + "history";
export const home_translations = process.env.REACT_APP_WEB_ROOT + "translations";
export const home_contributing = process.env.REACT_APP_WEB_ROOT + "contributing";
export const home_advancements = process.env.REACT_APP_WEB_ROOT + "advancements";
export const home_hof = process.env.REACT_APP_WEB_ROOT + "halloffame";

export const user_root = process.env.REACT_APP_WEB_ROOT + "user";
export const user_login = process.env.REACT_APP_WEB_ROOT + "user/login";
export const user_logout = process.env.REACT_APP_WEB_ROOT + "user/logout";
export const user_passwordchange = process.env.REACT_APP_WEB_ROOT + "user/passwordchange";
export const user_requestnewpassword = process.env.REACT_APP_WEB_ROOT + "user/requestnewpassword";
export const user_passwordreset = process.env.REACT_APP_WEB_ROOT + "user/passwordreset";
export const user_createaccount = process.env.REACT_APP_WEB_ROOT + "user/createaccount";
export const user_admin = process.env.REACT_APP_WEB_ROOT + "user/admin";


export const encyclopedia_home = process.env.REACT_APP_WEB_ROOT + "encyclopedia";
export const encyclopedia_newcontents = process.env.REACT_APP_WEB_ROOT + "encyclopedia/newcontents";
export const encyclopedia_newimages = process.env.REACT_APP_WEB_ROOT + "encyclopedia/newimages";
export const encyclopedia_culture = process.env.REACT_APP_WEB_ROOT + "encyclopedia/culture";

export const library_home = process.env.REACT_APP_WEB_ROOT + "library";
export const library_detail = process.env.REACT_APP_WEB_ROOT + "library/contents";
export const library_mycontents = process.env.REACT_APP_WEB_ROOT + "library/mycontents";
export const library_starredcontents = process.env.REACT_APP_WEB_ROOT + "library/starredcontents";
export const library_createcontent = process.env.REACT_APP_WEB_ROOT + "library/create";

export const translationcentre_home = process.env.REACT_APP_WEB_ROOT + "translationcentre";
export const translationcentre_files = process.env.REACT_APP_WEB_ROOT + "translationcentre/files";
export const translationcentre_history = process.env.REACT_APP_WEB_ROOT + "translationcentre/history";
export const translationcentre_recentchanges = process.env.REACT_APP_WEB_ROOT + "translationcentre/recentchanges";