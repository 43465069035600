import Translations from '../translation';
import renderHTML from 'react-render-html';

class LanguageConfig {
    // Initializing important variables
    constructor() {
        this.language = "en";
    }

    getText(key) {
        if (Translations[key] !== undefined)
            return Translations[key][this.language];
        else
            return key;
    }

    getHTML(key, params = []) {
        if (Translations[key] !== undefined) {

            let text = Translations[key][this.language];

            params.forEach(param => {
                text = text.replace(param.key, param.value)
            });

            return renderHTML(text);
        } else
            return key;
    }
}

const instance = new LanguageConfig();

export default instance;