import React from 'react';
import { Translate } from 'react-translated'
import Modal from 'react-responsive-modal';

import { FaTrashAlt } from 'react-icons/fa';

import './itemedit.css';
import EncyclopediaService from '../EncyclopediaService';

export default class ItemImages extends React.Component {

    render() {

        const { culture, type, itemkey, images } = this.props;

        const imagesJSX = [];

        images.records.forEach((item) => {
            imagesJSX.push(
                <DisplayImageForm forceRefresh={this.props.forceRefresh} culture={culture} type={type} 
                itemkey={itemkey} pos={item.pos} image={item} key={item.filename} />
            )
        })

        return (
            <div className="container" style={{ width: "100%", marginTop: "2em" }}>
                <h2><Translate text={"contentedit.images"} /></h2>
                <br />
                <div className="d-flex flex-wrap justify-content-around">
                    {imagesJSX}
                </div>
                <br />
                <ImageUpload forceRefresh={this.props.forceRefresh} culture={culture} type={type} itemkey={itemkey} />
            </div>
        )
    }
}

class ImageUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            loaded: 0,
            legend_en: "",
            legend_fr: ""
        };
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSelectedFile(event) {
        if (event.target.files[0] !== undefined) {
            this.setState({
                selectedFile: event.target.files[0],
                selectedFileURL: URL.createObjectURL(event.target.files[0]),
                loaded: 0,
            })
        } else {
            this.setState({
                selectedFile: null,
                selectedFileURL: null,
                loaded: 0,
            })
        }
    }

    handleUpload() {
        const { culture, type, itemkey } = this.props;
        EncyclopediaService.postImageForItem(culture, type, itemkey, this.state.legend_en, this.state.legend_fr, this.state.selectedFile).then((res) => {
            this.props.forceRefresh()
        });
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    render() {
        return (
            <div>
                <h2><Translate text={"contentedit.addimage"} /></h2>
                <div className="rounded lightbeigebg p-2 m-2" >
                    <div className="d-flex justify-content-between">
                        <input type="file" onChange={this.handleSelectedFile} />
                        <div style={{ maxWidth: 400, maxHeight: 300 }}>
                            <img className="img-fluid" src={this.state.selectedFileURL} alt="" />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-5">
                        <Translate text={"contentedit.legend_en"} />
                        <input
                            className="form-control form-control-sm"
                            style={{ width: "70%" }}
                            name="legend_en"
                            type="text"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        <Translate text={"contentedit.legend_fr"} />
                        <input
                            className="form-control form-control-sm" style={{ width: "70%" }}
                            name="legend_fr"
                            type="text"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="d-flex flex-row mt-2">
                        <button disabled={this.state.selectedFile === null} className="btn btn-primary" style={{ marginRight: 5, marginLeft: "auto" }} onClick={this.handleUpload}>
                            <Translate text={"contentedit.upload"} />
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

class DisplayImageForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            legend_en: this.props.image.legend_en,
            legend_fr: this.props.image.legend_fr,
            enabled: this.props.image.enabled === '1',
            openModal: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggleEnabled = this.toggleEnabled.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    toggleEnabled(e) {
        this.setState(
            {
                enabled: !this.state.enabled
            }
        )
    }

    handleUpdate() {
        const { culture, type, itemkey, pos } = this.props;
        const { legend_en, legend_fr, enabled } = this.state;

        EncyclopediaService.updateImageForItem(culture, type, itemkey, pos, enabled, legend_en, legend_fr).then((res) => {
            this.props.forceRefresh()
        });;
    }

    handleDelete() {
        const { culture, type, itemkey, pos } = this.props;

        EncyclopediaService.deleteImageForItem(culture, type, itemkey, pos).then((res) => {
            this.props.forceRefresh()
        });
    }

    onOpenModal = () => {
        this.setState({ openModal: true });
    };

    onCloseModal = () => {
        this.setState({ openModal: false });
    };

    render() {

        let modalStyles = {
            modal: {
                maxWidth: "100%"
            }
        };

        const { culture, type } = this.props;
        const { filename, previewname } = this.props.image;

        let divClassName = "lightbeigebg";

        if (!this.state.enabled) {
            divClassName = "lightgray";
        }

        return (
            <div className={"rounded "+divClassName+" p-2 m-auto"} >
                <div className="d-flex">
                    <img alt="" style={{ margin: "auto" }}
                        onClick={this.onOpenModal}
                        src={process.env.REACT_APP_ENCYLOPEDIA_IMG_ROOT + culture + "/" + type + "/" + previewname} />
                    <Modal open={this.state.openModal} onClose={this.onCloseModal} center styles={modalStyles}>
                        <img alt="" className="img-fluid"
                            src={process.env.REACT_APP_ENCYLOPEDIA_IMG_ROOT + culture + "/" + type + "/" + filename} />
                        {this.context.language === "en" && this.state.legend_en !== "" &&
                            <em><br />{this.state.legend_en}</em>
                        }
                        {this.context.language === "fr" && this.state.legend_fr !== "" &&
                            <em><br />{this.state.legend_fr}</em>
                        }
                    </Modal>
                </div>
                <div className="d-flex justify-content-between mt-5">
                    <Translate text={"contentedit.legend_en"} />
                    <input
                        className="form-control form-control-sm"
                        style={{ width: "70%" }}
                        name="legend_en"
                        type="text"
                        value={this.state.legend_en}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <Translate text={"contentedit.legend_fr"} />
                    <input
                        className="form-control form-control-sm" style={{ width: "70%" }}
                        name="legend_fr"
                        type="text"
                        value={this.state.legend_fr}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <Translate text={"contentedit.enabled"} />
                    <input
                        className="form-control form-control-sm" style={{ width: "70%" }}
                        name="enabled"
                        type="checkbox"
                        checked={this.state.enabled}
                        onChange={this.toggleEnabled}
                    />
                </div>
                <div className="d-flex flex-row" style={{marginTop: "auto", marginBottom: 2}}>
                    <button className="btn btn-delete" style={{ marginLeft: 5, marginRight: "auto" }} onClick={this.handleDelete}>
                        <FaTrashAlt className="deleteicon" />
                    </button>
                    <button className="btn btn-primary" style={{ marginRight: 5, marginLeft: "auto" }} onClick={this.handleUpdate}>
                        <Translate text={"contentedit.submit"} />
                    </button>
                </div>
            </div>
        )
    }
}