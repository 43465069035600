import React from 'react';
import { Translate, Translator } from 'react-translated';
import ReactPasswordStrength from 'react-password-strength';
import QueryString from 'query-string';

import { ContentZone, ContentPage } from '../Common/CommonComponents';
import AuthService from './AuthService';
import UserService from './UserService';
import LanguageConfig from '../Common/LanguageConfig';

import * as URLs from '../URLS'

export default class PasswordReset extends React.Component {
    constructor() {
        super();
        this.state = {};
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.isFormComplete = this.isFormComplete.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    componentWillMount() {
        if (AuthService.loggedIn())
            this.props.history.replace(URLs.home);

        const parsedQuery = QueryString.parse(this.props.location.search);

        this.setState({
            email: parsedQuery.email,
            key: parsedQuery.key
        })
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleFormSubmit(e) {
        e.preventDefault();

        UserService.resetPassword(this.state.email, this.state.key, this.state.password)
            .then(res => {
                // Try and login with the reset password
                AuthService.login(res.login, this.state.password).then(res => {
                    this.props.history.replace(URLs.home);
                })
                    .catch(err => {
                        console.log(err);
                    });
            })
            .catch(err => {
                console.log(err);
            })
    }

    handlePasswordChange(passwordState, feedback) {
        if (passwordState.isValid === true)
            this.setState(
                {
                    password: passwordState.password
                }
            )
        else
            this.setState(
                {
                    password: undefined
                }
            )
    }

    isFormComplete() {
        const { password, passwordrepeat } = this.state;

        if (password === undefined || password === "") {
            return false;
        }

        if (passwordrepeat === undefined || passwordrepeat === "") {
            return false;
        }

        if (passwordrepeat !== password) {
            return false;
        }

        return true;
    }

    render() {

        return (
            <ContentPage pageTitle={LanguageConfig.getText("passwordreset.title")} maxWidth="800px">
                <ContentZone>
                    <h1><Translate text={"passwordreset.title"} /></h1>
                    <form onSubmit={this.handleFormSubmit}>
                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <Translate text={"passwordchange.newpassword"} />
                            <Translator>
                                {({ translate }) => (
                                    <ReactPasswordStrength
                                        minLength={5}
                                        minScore={2}
                                        scoreWords={[
                                            translate({ text: 'password.weak' }),
                                            translate({ text: 'password.okay' }),
                                            translate({ text: 'password.good' }),
                                            translate({ text: 'password.strong' }),
                                            translate({ text: 'password.stronger' })]}
                                        tooShortWord={translate({ text: 'password.tooshort' })}
                                        changeCallback={this.handlePasswordChange}
                                        inputProps={{ name: "password", autoComplete: "off", className: "form-control form-control-sm userformelement" }}
                                    />
                                )}
                            </Translator>
                        </div>
                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <Translate text={"createaccount.passwordrepeat"} />
                            <input
                                className="form-control form-control-sm userformelement"
                                name="passwordrepeat"
                                type="password"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div style={{ width: "100%", height: "2em", marginTop: "1em" }}>
                            <Translator>
                                {({ translate }) => (
                                    <input
                                        disabled={!this.isFormComplete()}
                                        className="btn btn-primary"
                                        style={{ float: "right", width: "150px" }}
                                        value={translate({
                                            text: 'passwordchange.change'
                                        })}
                                        type="submit"
                                    />
                                )}
                            </Translator>
                        </div>
                    </form>
                </ContentZone>
            </ContentPage>
        )
    }
}