import React from 'react';
import { Translate, Translator } from 'react-translated';
import ReCAPTCHA from "react-google-recaptcha";
import ReactPasswordStrength from 'react-password-strength';

import './users.css';
import { ContentPage, ContentZone } from '../Common/CommonComponents';
import LanguageConfig from '../Common/LanguageConfig';
import AuthService from './AuthService';
import UserService from './UserService';

import * as URLs from '../URLS'

export default class CreateAccount extends React.Component {
    constructor() {
        super();
        this.state = {};
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.captchaChange = this.captchaChange.bind(this);
        this.isFormComplete = this.isFormComplete.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    componentWillMount() {
        if (AuthService.loggedIn())
            this.props.history.replace(URLs.home);
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handlePasswordChange(passwordState, feedback) {
        if (passwordState.isValid === true)
            this.setState(
                {
                    password: passwordState.password
                }
            )
        else
            this.setState(
                {
                    password: undefined
                }
            )
    }

    captchaChange(value) {
        this.setState(
            {
                captcha: value
            }
        )
    }

    handleFormSubmit(e) {
        e.preventDefault();

        UserService.postCreateAccount(this.state.username, this.state.password, this.state.email, this.state.captcha)
            .then(res => {
                this.props.history.replace(URLs.home);
            })
            .catch(err => {
                console.log(err);
            })
    }

    isFormComplete() {
        const { username, password, passwordrepeat, captcha } = this.state;

        if (username === undefined || username === "") {
            return false;
        }

        if (password === undefined || password === "") {
            return false;
        }

        if (passwordrepeat === undefined || passwordrepeat === "") {
            return false;
        }

        if (passwordrepeat !== password) {
            return false;
        }

        if (captcha === undefined || captcha === "") {
            return false;
        }

        return true;
    }

    render() {

        return (
            <ContentPage pageTitle={LanguageConfig.getText("createaccount.title")} maxWidth="800px">
                <ContentZone>
                    <h1><Translate text={"createaccount.title"} /></h1>
                    <p><Translate text={"createaccount.desc"} /></p>

                    <form onSubmit={this.handleFormSubmit}>
                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <Translate text={"login.username"} />
                            <input
                                className="form-control form-control-sm userformelement"
                                name="username"
                                type="text"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <Translate text={"login.password"} />
                            <Translator>
                                {({ translate }) => (
                                    <ReactPasswordStrength
                                        minLength={5}
                                        minScore={2}
                                        scoreWords={[
                                            translate({ text: 'password.weak' }),
                                            translate({ text: 'password.okay' }),
                                            translate({ text: 'password.good' }),
                                            translate({ text: 'password.strong' }),
                                            translate({ text: 'password.stronger' })]}
                                        tooShortWord={translate({ text: 'password.tooshort' })}
                                        changeCallback={this.handlePasswordChange}
                                        inputProps={{ name: "password", autoComplete: "off", className: "form-control form-control-sm userformelement" }}
                                    />
                                )}
                            </Translator>
                        </div>
                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <Translate text={"createaccount.passwordrepeat"} />
                            <input
                                className="form-control form-control-sm userformelement"
                                name="passwordrepeat"
                                type="password"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <Translate text={"createaccount.email"} />
                            <input
                                className="form-control form-control-sm userformelement"
                                name="email"
                                type="text"
                                onChange={this.handleChange}
                            />
                        </div><div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <em><Translate text={"createaccount.emaildesc"} /></em>
                        </div>
                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <ReCAPTCHA style={{ marginLeft: "auto", marginRight: 0 }}
                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                onChange={this.captchaChange}
                            />
                        </div>
                        <div style={{ width: "100%", height: "2em", marginTop: "1em" }}>
                            <Translator>
                                {({ translate }) => (
                                    <input
                                        disabled={!this.isFormComplete()}
                                        className="btn btn-primary"
                                        style={{ float: "right", width: "150px" }}
                                        value={translate({
                                            text: 'createaccount.create'
                                        })}
                                        type="submit"
                                    />
                                )}
                            </Translator>
                        </div>

                    </form>
                </ContentZone>
            </ContentPage>
        )
    }
}