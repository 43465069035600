import React from 'react';
import { Translate } from 'react-translated';

import "./homepage.css"
import HomepageService from './HomepageService';
import { LanguageContext } from '../Common/LanguageContext';
import LanguageConfig from '../Common/LanguageConfig';

import langok from './img/langok.png'
import langmedium from './img/langmedium.png'
import langbad from './img/langbad.png'

export default class Translations extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            translations: [],
            lastcall: null
        };
    }

    componentDidMount() {
        this.refreshList();
        document.title = LanguageConfig.getText('site.shorttitle') + " > " + LanguageConfig.getText('menu_translations');
    }

    componentDidUpdate() {
        this.refreshList();
        document.title = LanguageConfig.getText('site.shorttitle') + " > " + LanguageConfig.getText('menu_translations');
    }

    refreshList() {
        const callkey = "translations/" + this.context.language;

        if (callkey === this.state.lastcall)
            return;

        HomepageService.getTranslationList(this.context.language)
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        translations: result,
                        lastcall: callkey,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        lastcall: callkey,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <TranslationsDisplay translations={this.state.translations} />
        )
    }
}

class TranslationsDisplay extends React.Component {
    render() {
        const translationsComponents = [];

        this.props.translations.forEach(translation => {
            translationsComponents.push(
                <Translation translation={translation} />
            )
        });

        return (
            <div>                
                <section class="page-section clearfix">
                    <div class="container">
                        <div class="row">
                            <div class="col mx-auto">
                                <div class="singleitem bg-faded rounded" id="versionhistory">
                                    <h2 class="section-heading mb-4">
                                        <span class="section-heading-lower"><Translate text="translation_help_title" /></span>
                                    </h2>
                                    {LanguageConfig.getHTML("translation_help_text")}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="page-section clearfix">
                    <div class="container">
                        <div class="row">
                            <div class="col mx-auto">
                                <div class="singleitem bg-yellow rounded">
                                    <h2 class="section-heading mb-4">
                                        <span class="section-heading-lower"><Translate text="translation_status_title" /></span>
                                    </h2>
                                    <table style={{ width: "100%" }}>
                                        {translationsComponents}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

class Translation extends React.Component {
    render() {
        const translation = this.props.translation;

        let img = null;
        if (translation.percentage >= 75) {
            img = langok;
        } else if (translation.percentage >= 50) {
            img = langmedium;
        } else {
            img = langbad;
        }

        const barWidth = translation.percentage * 4;

        return (
            <tr>
                <td height='25'>
                    <img src={process.env.REACT_APP_HOME_IMG_ROOT + "lang/" + translation.lang + ".png"} alt="" />
                </td>
                <td><b>{translation.lang}</b></td><td>{translation.name_native + " (" + translation.name + ")"}</td>
                <td><img src={img} width={barWidth} height='15' alt="" /></td>
                <td>{translation.percentage + "%"}</td>
            </tr>

        )
    }
}