
export function getCultureVillager(culture) {
    if (culture === "norman") {
        return "norman_knight";
    } else if (culture === "indian") {
        return "indian_indian_rajputgeneral";
    } else if (culture === "japanese") {
        return "japanese_japanese_armysmith";
    } else if (culture === "mayan") {
        return "mayan_lonemayanshamanwife";
    } else if (culture === "byzantines") {
        return "byzantines_richwife_byzantine";
    } else if (culture === "inuits") {
        return "inuits_inuit_oldlady";
    } else if (culture === "seljuk") {
        return "seljuk_turk_localmerchant";
    }
}