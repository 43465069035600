import React from 'react';
import { Translate, Translator } from 'react-translated';

import { ContentZone, ContentPage } from '../Common/CommonComponents';
import AuthService from './AuthService';
import UserService from './UserService';
import LanguageConfig from '../Common/LanguageConfig';

import * as URLs from '../URLS'

export default class RequestPasswordReset extends React.Component {
    constructor() {
        super();
        this.state = {};
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.isFormComplete = this.isFormComplete.bind(this);
    }

    componentWillMount() {
        if (AuthService.loggedIn())
            this.props.history.replace(URLs.home);
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleFormSubmit(e) {
        e.preventDefault();

        UserService.requestNewPassword(this.state.email)
            .then(res => {
                this.props.history.replace(URLs.home);
            })
            .catch(err => {
                console.log(err);
            })
    }

    isFormComplete() {
        const { email } = this.state;

        if (email === undefined || email === "") {
            return false;
        }

        return true;
    }

    render() {

        return (
            <ContentPage pageTitle={LanguageConfig.getText("passwordrequest.title")} maxWidth="500px">
                <ContentZone>
                    <h1><Translate text={"passwordrequest.title"} /></h1>
                    <p><Translate text={"passwordrequest.desc"} /></p>
                    <form onSubmit={this.handleFormSubmit}>
                        <div style={{ width: "100%" }} className="d-flex justify-content-between mt-2">
                            <Translate text={"createaccount.email"} />
                            <input
                                className="form-control form-control-sm userformelement"
                                name="email"
                                type="text"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div style={{ width: "100%", height: "2em", marginTop: "1em" }}>
                            <Translator>
                                {({ translate }) => (
                                    <input
                                        disabled={!this.isFormComplete()}
                                        className="btn btn-primary"
                                        style={{ float: "right", width: "150px" }}
                                        value={translate({
                                            text: 'passwordrequest.request'
                                        })}
                                        type="submit"
                                    />
                                )}
                            </Translator>
                        </div>
                    </form>
                </ContentZone>
            </ContentPage>
        )
    }
}