import React from 'react';
import { Redirect } from 'react-router-dom'
import { LanguageContext } from '../Common/LanguageContext';

import * as URLs from "../URLS"

import './autosuggest.css';
import EncyclopediaAutoSuggest from './EncyclopediaAutoSuggest';

export default class EncyclopediaSearch extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            selectedUrL: null
        };
    }


    onSuggestionSelected = (e, { suggestion }) => {
        if (suggestion.category.type === "cultures") {
            this.setState({
                selectedUrL: URLs.encyclopedia_culture + "/" + suggestion.category.culture
            });
        } else {
            this.setState({
                selectedUrL: URLs.encyclopedia_culture + "/" + suggestion.category.culture + "/" + suggestion.category.type + "/" + suggestion.itemkey
            });
        }
    }

    render() {

        const current_url = this.props.location.pathname;

        if (this.state.selectedUrL !== null && this.state.selectedUrL !== current_url) {
            return <Redirect push to={this.state.selectedUrL} />;
        }

        return (
            <div className="row">
                <div className="col-12 mb-4">
                    <div className="bg-faded p-3 rounded" align="center">
                        <div style={{ margin: "auto", display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                            <EncyclopediaAutoSuggest onSuggestionSelected={this.onSuggestionSelected} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}