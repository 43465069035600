import React from 'react';
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import * as URLs from "../URLS"

class MillenaireIcon extends React.Component {
    render() {
  
      const toolTipId = this.props.iconkey+"_"+Math.random();
  
      return (
        <div style={{ float: 'left', marginRight: 5 }} className="vertical-align">
          <img alt={this.props.label}
            style={{ width: 32, height: 32 }}
            src={process.env.REACT_APP_ENCYLOPEDIA_IMG_ROOT + "icons/" + this.props.iconkey + '.png'}
            data-for={toolTipId} data-tip={this.props.label}
          />
          <ReactTooltip place="top" type="light" effect="solid" id={toolTipId} getContent={(dataTip) => `${dataTip}`} />
        </div>
      );
    }
  }

  class ReferenceLink extends React.Component {
    render() {
      return (
        <Link to={URLs.encyclopedia_culture + "/" + this.props.culture + "/" + this.props.type + "/" + this.props.itemkey} >
          <div style={{display: "flex"}}>
            <MillenaireIcon iconkey={this.props.iconkey} label={this.props.label} />
            <span style={{marginTop: "auto",marginBottom: "auto"}}>{this.props.text}</span>
          </div>
        </Link>
      );
    }
  }

  export { MillenaireIcon, ReferenceLink }