import React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { LanguageContext } from '../../Common/LanguageContext';
import { ContentPage, ContentZone, MessageZone } from '../../Common/CommonComponents';
import TextLine from "../ItemDisplay/TextLine";
import EncyclopediaService from '../EncyclopediaService';
import AuthService from '../../Users/AuthService';
import DescEditor from "./DescEditor";
import ItemHistory from "./ItemHistory";
import ItemImages from "./ItemImages";

import * as URLs from '../../URLS'

export default class ItemEdit extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            history: null,
            images: null,
            lastcall: null,
            currentDesc: ""
        };
        this.restoreHistory = this.restoreHistory.bind(this);
        this.forceRefresh = this.forceRefresh.bind(this);
        this.travelBookDesc = "";
    }

    refreshDetails() {
        const culture = this.props.match.params.culture;
        const type = this.props.match.params.type;
        const itemkey = this.props.match.params.itemkey;

        const callkey = culture + type + itemkey + this.context.language;

        if (callkey === this.state.lastcall)
            return;

        EncyclopediaService.getItemHistory(culture, type, itemkey, this.context.language, true).then(
            (result) => {
                //We need to iterate through the book to find the travel book desc
                result.travelbookdata.pages.forEach((page) => {
                    page.lines.forEach((line) => {
                        if (line.specialTag === "MAIN_DESC") {
                            this.travelBookDesc = line.text;
                        }
                    })
                });

                //We also need the current description
                let currentDesc = "";
                if (result.history.records[0] !== undefined) {
                    currentDesc = result.history.records[0].desc;
                }

                this.setState({
                    isLoaded: true,
                    history: result.history,
                    travelbookdata: result.travelbookdata,
                    images: result.images,
                    title: result.title,
                    lastcall: callkey,
                    currentDesc: currentDesc
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    lastcall: callkey,
                    error
                });
            }
        )
    }

    componentDidMount() {
        this.refreshDetails()
    }

    componentDidUpdate() {
        this.refreshDetails()
    }

    componentWillMount() {
        if (!AuthService.loggedIn())
            this.props.history.replace(URLs.home);
    }

    forceRefresh() {
        this.setState({
            lastcall: null,
        })
    }

    restoreHistory(item) {
        if (item !== null) {
            this.setState({
                currentDesc: item.desc
            })
        }
    }

    render() {

        const { error, isLoaded, travelbookdata, history, images, title } = this.state;

        const culture = this.props.match.params.culture;
        let type = this.props.match.params.type;
        let itemkey = this.props.match.params.itemkey;

        if (type === undefined) {
            type = "cultures";
            itemkey = culture;
        }

        if (error) {
            return <MessageZone message={"Error: " + error.message + " url: " + this.url} />
        } else if (!isLoaded) {
            return <MessageZone message={"Loading..."} />
        } else {
            return (
                <ContentPage pageTitle={title}>
                    <ContentZone>
                        <TextLine line={travelbookdata.pages[0].lines[0]} />
                        <br />
                        <DescEditor culture={culture} type={type} itemkey={itemkey}
                            currentDesc={this.state.currentDesc} travelBookDesc={this.travelBookDesc}
                            forceRefresh={this.forceRefresh} />

                        <ItemHistory history={history} restoreHistory={this.restoreHistory} />

                        <ItemImages culture={culture} type={type} itemkey={itemkey} images={images}
                            forceRefresh={this.forceRefresh} />
                    </ContentZone>
                </ContentPage>
            )
        }
    }
}
