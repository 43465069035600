import 'babel-polyfill';
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Provider } from 'react-translated';
import { LanguageProvider } from './Common/LanguageContext';
import { CookiesProvider } from 'react-cookie';
import { DFPManager, DFPSlotsProvider } from 'react-dfp';
import { withRouter } from 'react-router'

import translation from './translation';
import { LanguageContext } from './Common/LanguageContext';

import * as Headers from './Headers/SectionHeaders';
import * as URLs from './URLS';

import Homepage from './Home/Homepage'
import VersionHistory from './Home/VersionHistory';
import Translations from './Home/Translations';


import CultureMainScreen from './EncyclopediaContent/MainScreen';
import Culture from './EncyclopediaContent/Culture';
import CultureItemDetail from './EncyclopediaContent/ItemDisplay/ItemDetail';
import CultureItemEdit from "./EncyclopediaContent/ItemEdit/ItemEdit";
import CultureNewContentsList from "./EncyclopediaContent/Admin/NewContentsList";
import CultureNewImagesList from "./EncyclopediaContent/Admin/NewImagesList";

import LoginScreen from "./Users/LoginScreen";
import CreateAccount from "./Users/CreateAccount";
import UserAdmin from "./Users/UserAdmin";
import PasswordChange from './Users/PasswordChange';
import PasswordReset from './Users/PasswordReset';
import RequestPasswordReset from './Users/RequestPasswordReset';

import { AdsVertical, AdsHorizontal } from './Common/AdsDFP';
import Contributing from './Home/Contributing';
import Footer from './Headers/Footer';
import Advancements from './Home/Advancements';
import HOF from './Home/HOF';

import ContentDetails from './Library/ContentDetails';
import ContentSearch from './Library/ContentSearch';
import ContentEdit from './Library/ContentEdit';
import ContentCreate from './Library/ContentCreate';

import ReactGA from 'react-ga';
import LogoutScreen from './Users/LogoutScreen';
import TranslationHome from './TranslationCentre/TranslationHome';
import TranslationCompare from './TranslationCentre/TranslationCompare';
import TranslationHistory from './TranslationCentre/TranslationHistory';
import TranslationRecentChanges from './TranslationCentre/TranslationRecentChanges';


ReactGA.initialize('UA-25494969-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class App extends Component {
  render() {
    return (
      <CookiesProvider>
        <LanguageProvider>
          <MainRouting />
        </LanguageProvider>
      </CookiesProvider>
    );
  }
}

class MainRouting extends Component {
  static contextType = LanguageContext;

  render() {
    return (
      <Provider language={this.context.language} translation={translation}>
        <NotificationContainer />
        <div className="App">
          <BrowserRouter>
            <DFPSlotsProvider dfpNetworkId='21791986882'>
              <AdReload />
              <div style={{ marginBottom: "1rem" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ marginTop: "400px", width: "170px" }} className="ads sideads">
                    <AdsVertical />
                  </div>

                  <div className="maincontent">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <AdsHorizontal />
                    </div>

                    <Switch>
                      <Route path={URLs.encyclopedia_home} component={Headers.EncyclopediaHeader} />
                      <Route path={URLs.library_home} component={Headers.LibraryHeader} />
                      <Route exact path={URLs.home} component={Headers.HomepageHeader} />
                      <Route path={URLs.user_root} component={Headers.TechnicalPageHeader} />
                      <Route path={URLs.translationcentre_home} component={Headers.TranslationHeader} />

                      <Route component={Headers.SubHomepageHeader} />
                    </Switch>

                    <Switch>
                      <Route exact path={URLs.home} component={Homepage} />
                      <Route exact path={URLs.home_history} component={VersionHistory} />
                      <Route exact path={URLs.home_translations} component={Translations} />
                      <Route exact path={URLs.home_contributing} component={Contributing} />
                      <Route exact path={URLs.home_advancements} component={Advancements} />
                      <Route exact path={URLs.home_advancements + "/:player"} component={Advancements} />
                      <Route exact path={URLs.home_hof} component={HOF} />

                      <Route exact path={URLs.user_login} component={LoginScreen} />
                      <Route exact path={URLs.user_logout} component={LogoutScreen} />
                      <Route exact path={URLs.user_passwordchange} component={PasswordChange} />
                      <Route exact path={URLs.user_requestnewpassword} component={RequestPasswordReset} />
                      <Route exact path={URLs.user_passwordreset} component={PasswordReset} />
                      <Route exact path={URLs.user_createaccount} component={CreateAccount} />

                      <Route exact path={URLs.user_admin} component={UserAdmin} />

                      <Route exact path={URLs.encyclopedia_home} component={CultureMainScreen} />
                      <Route exact path={URLs.encyclopedia_newcontents} component={CultureNewContentsList} />
                      <Route exact path={URLs.encyclopedia_newimages} component={CultureNewImagesList} />
                      <Route exact path={URLs.encyclopedia_culture + "/:culture"} component={Culture} />
                      <Route exact path={URLs.encyclopedia_culture + "/:culture/edit"} component={CultureItemEdit} />
                      <Route exact path={URLs.encyclopedia_culture + "/:culture/:type/:itemkey"} component={CultureItemDetail} />
                      <Route exact path={URLs.encyclopedia_culture + "/:culture/:type/:itemkey/edit"} component={CultureItemEdit} />

                      <Route exact path={URLs.library_home} component={ContentSearch} />
                      <Route exact path={URLs.library_mycontents} render={(props) => <ContentSearch {...props} pageType={"usercontents"} />} />
                      <Route exact path={URLs.library_starredcontents} render={(props) => <ContentSearch {...props} pageType={"starred"} />} />
                      <Route exact path={URLs.library_createcontent} component={ContentCreate} />
                      <Route exact path={URLs.library_detail + "/:content"} component={ContentDetails} />
                      <Route exact path={URLs.library_detail + "/:content/edit"} render={(props) => <ContentEdit {...props} pictureEdit={false} />} />
                      <Route exact path={URLs.library_detail + "/:content/editimages"} render={(props) => <ContentEdit {...props} pictureEdit={true} />} />

                      <Route exact path={URLs.translationcentre_home} component={TranslationHome} />
                      <Route exact path={URLs.translationcentre_files + "/:filekey/:referenceLanguage/:translationLanguage"} component={TranslationCompare} />
                      <Route exact path={URLs.translationcentre_history + "/:filekey/:language"} component={TranslationHistory} />
                      <Route exact path={URLs.translationcentre_recentchanges } component={TranslationRecentChanges} />

                    </Switch>
                    <Footer />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <AdsHorizontal />
                    </div>
                  </div>
                  <div style={{ marginTop: "400px", width: "170px" }} className="ads sideads">
                    <AdsVertical />
                  </div>
                </div>
              </div>
            </DFPSlotsProvider>
          </BrowserRouter>
        </div>
      </Provider>
    )
  }
}

class AdReloadRaw extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    DFPManager.refresh();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return <div />
  }
}

var AdReload = withRouter(AdReloadRaw);