import React from 'react';

import { Link } from 'react-router-dom';
import { FaHistory } from 'react-icons/fa';

import * as Icons from './TranslationIcons';
import './translation.css';
import * as URLs from "../URLS";

export default class TranslationFileInfo extends React.Component {
    render() {
        const { file, language, style, className } = this.props;

        return (
            <div className={"lightbeigebg rounded p-2 " + className} style={style}>
                {file !== null &&
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", width: "100%" }}>
                        <div style={{ margin: "4px" }}>
                            <img src={process.env.REACT_APP_HOME_IMG_ROOT + "lang/" + language + ".png"} alt="" data-tip={"Current file for: " + language} />
                        </div>
                        {file.author !== null &&
                            <div style={{ margin: "4px" }}><Icons.Author className="cardicon" />{file.author}</div>
                        }
                        {file.version !== null &&
                            <div style={{ margin: "4px" }}><Icons.Version className="cardicon" />
                                {file.version}
                            </div>
                        }
                        <div style={{ margin: "4px" }}><Icons.Published className="cardicon" />{file.createdon}</div>
                        <div style={{ margin: "4px" }}><Icons.LineNumber className="cardicon" />{file.elementcount}</div>
                        <Link to={URLs.translationcentre_history + "/" + language + "/" + file.filekey} >
                            <button className="btn btn-primary">
                                <FaHistory className="buttonicon" data-tip="View file history" />
                            </button>
                        </Link>
                    </div>
                }
                {file === null &&
                    <div>
                        <img src={process.env.REACT_APP_HOME_IMG_ROOT + "lang/" + language + ".png"} alt="" /> No translation available
                    </div>
                }
            </div>
        )
    }
}