export default {
  'login.title': {
    en: 'Login',
    fr: 'Se connecter'
  },
  'login.desc': {
    en: 'Login with your user name and password. This connection is valid for the Millénaire Library, Encyclopedia and the Wiki. The Forum uses separate accounts.',
    fr: 'Connectez vous avec votre pseudo et votre mot de passe. Cette connexion est valide pour la libraire, l\'encylcopédie et la wiki. Le forum utilise des comptes séparés.'
  },
  'login.username': {
    en: "Username:",
    fr: "Pseudo :"
  },
  'login.password': {
    en: "Password:",
    fr: "Mot de passe :"
  },
  'login.login': {
    en: 'Login',
    fr: 'Se connecter',
  },
  'login.passwordreset': {
    en: 'Request a password reset by email.',
    fr: 'Demandez un nouveau mot de passe par mail.'
  },
  'logout.logout': {
    en: 'Logout',
    fr: 'Se déconnecter',
  },
  'logout.title': {
    en: 'Logout from Millénaire',
    fr: 'Se déconnecter de Millénaire',
  },
  'logout.desc': {
    en: 'Logout from Millénaire (including the Library, the Encyclopedia and the Wiki).',
    fr: 'Se déconnecter de Millénaire (y compris la librairie, l\'encyclopédie et la wiki).',
  },

  'createaccount.title': {
    en: 'Create an account',
    fr: 'Créer un compte',
  },
  'createaccount.desc': {
    en: 'Create an account valid for the Library, the Encyclopedia and the Wiki.',
    fr: 'Créer un compte valide pour la librairie, l\'encyclopédie et la wiki.',
  },
  'createaccount.passwordrepeat': {
    en: 'Repeat password:',
    fr: 'Resaisir le mot de passe :',
  },
  'createaccount.email': {
    en: 'Email:',
    fr: 'Mail :',
  },
  'createaccount.emaildesc': {
    en: 'An email is not compulsory, it is only used for password recovery.',
    fr: 'Un mail n\'est pas obligatoire, il sert uniquement à la récuppération de mots de passe.',
  },
  'createaccount.create': {
    en: 'Create',
    fr: 'Créer',
  },
  'useradmin.title': {
    en: 'User Administration',
    fr: 'Administration utilisateurs'
  },
  'password.weak': {
    en: 'very weak',
    fr: 'très faible'
  },
  'password.okay': {
    en: 'weak',
    fr: 'faible'
  },
  'password.good': {
    en: 'good',
    fr: 'bien'
  },
  'password.strong': {
    en: 'strong',
    fr: 'fort'
  },
  'password.stronger': {
    en: 'very strong',
    fr: 'très fort'
  },
  'password.tooshort': {
    en: 'too short',
    fr: 'trop court'
  },
  'passwordchange.title': {
    en: 'Password Change',
    fr: 'Changement de mot de passe'
  },
  'passwordchange.oldpassword': {
    en: 'Old Password:',
    fr: 'Ancien mot de passe :'
  },
  'passwordchange.newpassword': {
    en: 'New Password:',
    fr: 'Nouveau mot de passe :'
  },
  'passwordchange.change': {
    en: 'Change Password',
    fr: 'Changer'
  },
  'passwordrequest.title': {
    en: 'Request a password change',
    fr: 'Demander un changement de mot de passe'
  },
  'passwordrequest.desc': {
    en: 'A email will be sent to the provided email with instructions.',
    fr: 'Un email sera envoyé à l\'adresse fournie avec des instructions.' 
  },
  'passwordrequest.request': {
    en: 'Submit Request',
    fr: 'Soumettre' 
  },
  'passwordreset.title': {
    en: 'Reset your password',
    fr: 'Changer votre mot de passe' 
  },
  'notification.userupdated': {
    en: 'User updated.',
    fr: 'Utilisateur mise à jour.'
  },
  'notification.passwordchanged': {
    en: 'Your password has been modified.',
    fr: 'Votre mot de passe a été modifié.'
  },
  'notification.passwordrequestsent': {
    en: 'A password reset request has been sent to the provided email.',
    fr: 'Une demande de changement de mot de passe a été envoyée à l\'email fourni.'
  },
  'LOGIN_TAKEN': {
    en: 'This login is already taken.',
    fr: 'Ce login est déjà pris.'
  },
  'INVALID_CAPTCHA': {
    en: 'Invalid CAPTCHA.',
    fr: 'CAPTCHA invalide.'
  },
}