import React from 'react';
import { MillenaireIcon, ReferenceLink } from '../EncyclopediaComponents';

export default class TextLine extends React.Component {
    render() {
        const line = this.props.line;

        if (line.columns) {
            if (line.exportTwoColumns) {
                return (
                    <div className="container" style={{ width: "100%" }}>
                        <div className="row">
                            <div className="col-6 p-0">
                                <TextLine line={line.columns[0]} />
                            </div>
                            <div className="col-6 p-0">
                                <TextLine line={line.columns[1]} />
                            </div>
                        </div>
                    </div>
                )
            } else {
                const colJSX = [];

                let lineCount = 0;

                line.columns.forEach((col) => {
                    colJSX.push(
                        <div className="p-2" key={lineCount}><TextLine line={col} /></div>
                    )
                    lineCount++;
                })
                return (
                    <div className="container" style={{ width: "100%" }}>
                        <div className="row">{colJSX}
                        </div>
                    </div>
                )
            }

        }

        let text;

        let iconDisplayed =false;

        if (line.style === "header") {
            iconDisplayed=true;
            text = (
                <div className="millenaire-header" style={{display: 'flex', lineHeight: '40px'}}>
                    {line.iconKey &&
                        <MillenaireIcon iconkey={line.iconKey} label={line.iconLabel} />
                    }
                    {this.props.line.text}
                </div>
            )
        } else if (line.style === "subheader") {
            iconDisplayed=true;
            text = (
                <div className="millenaire-subheader" style={{display: 'flex', lineHeight: '35px'}}>
                    {line.iconKey &&
                        <MillenaireIcon iconkey={line.iconKey} label={line.iconLabel} />
                    }
                    {this.props.line.text}
                </div>
            )
        } else {
            text = <span>{this.props.line.text}</span>;
        }

        return (
            <div className="mb-2 mt-2">
                {line.iconKey && !iconDisplayed &&
                        <MillenaireIcon iconkey={line.iconKey} label={line.iconLabel} />
                    }
                {line.referenceButtonKey &&
                    <ReferenceLink iconkey={line.referenceButtonIconKey} label={line.referenceButtonLabel} text={text}
                        type={line.referenceButtonType} culture={line.referenceButtonCulture} itemkey={line.referenceButtonKey} />
                }
                {!line.referenceButtonKey && text}
            </div>
        );
    }
}