import React from 'react';
import { Translate } from 'react-translated';
import renderHTML from 'react-render-html';

import { FaFileDownload } from 'react-icons/fa';

import "./homepage.css"
import HomepageService from './HomepageService';
import { LanguageContext } from '../Common/LanguageContext';
import LanguageConfig from '../Common/LanguageConfig';

export default class VersionHistory extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            minecraftVersions: [],
            lastcall: null
        };
    }

    componentDidMount() {
        this.refreshList();
        document.title = LanguageConfig.getText('site.shorttitle') + " > " + LanguageConfig.getText('menu_versionhistory');
    }

    componentDidUpdate() {
        this.refreshList();
        document.title = LanguageConfig.getText('site.shorttitle') + " > " + LanguageConfig.getText('menu_versionhistory');
    }

    refreshList() {
        const callkey = "versions/" + this.context.language;

        if (callkey === this.state.lastcall)
            return;

        HomepageService.getVersionList(this.context.language)
            .then(
                (result) => {

                    const minecraftVersions = this.splitVersionsByMCVersion(result);

                    this.setState({
                        isLoaded: true,
                        minecraftVersions: minecraftVersions,
                        lastcall: callkey,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        lastcall: callkey,
                        error
                    });
                }
            )
    }

    splitVersionsByMCVersion(versions) {
        const splitVersions = [];

        let currentMCVersion = null;

        versions.forEach((version) => {
            if (currentMCVersion !== null && currentMCVersion.mcversion !== version.mcversion) {
                splitVersions.push(currentMCVersion);
                currentMCVersion = null;
            }

            if (currentMCVersion === null) {
                currentMCVersion = {};
                currentMCVersion.versions = [];
                currentMCVersion.mcversion = version.mcversion;
            }

            currentMCVersion.versions.push(version);
        })

        return splitVersions;
    }

    render() {
        return (
            <VersionHistoryDisplay minecraftVersions={this.state.minecraftVersions} />
        )
    }
}

class VersionHistoryDisplay extends React.Component {
    render() {
        const minecraftVersions = this.props.minecraftVersions;
        const minecraftVersionComponents = [];

        let firstVersion = true;

        minecraftVersions.forEach((minecraftVersion) => {
            minecraftVersionComponents.push(
                <MinecraftVersionList minecraftVersion={minecraftVersion} initialState={firstVersion} />
            )
            firstVersion = false;
        })

        return (
            <section class="page-section clearfix">
                <div class="container">
                    <div class="row">
                        <div class="col mx-auto">
                            <div class="singleitem bg-faded rounded">
                                <h2 className="section-heading mb-4">
                                    <span className="section-heading-lower"><Translate text="menu_versionhistory" /></span>
                                </h2>
                                {minecraftVersionComponents}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

class MinecraftVersionList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expended: this.props.initialState
        };
        this.toggleExpand = this.toggleExpand.bind(this);
    }

    toggleExpand() {
        this.setState({
            expended: !this.state.expended
        })
    }

    render() {
        const minecraftVersion = this.props.minecraftVersion;

        const versionComponents = [];

        minecraftVersion.versions.forEach((version) => {
            versionComponents.push(
                <VersionDisplay version={version} />
            )
        })

        return (
            <div>
                <button type="button" className="btn btn-primary" onClick={this.toggleExpand}
                    style={{ float: "right", marginRight: "10px", marginTop: "5px" }}>
                    <Translate text={this.state.expended ? "shrink" : "expand"} />
                </button>
                <div className="card-header">
                        <span className="section-heading-lower">
                            <Translate text="version_formc" />&nbsp;{minecraftVersion.mcversion}
                        </span>
                    </div>
                <div className={this.state.expended ? "collapse show" : "collapse"}>
                    <div className="card-body">
                        <table className="table table-hover table-striped ">
                            <thead className="thead-light">
                                <tr>
                                    <th>Version</th>
                                    <th><Translate text="version_releasenotes" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {versionComponents}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        )
    }
}

class VersionDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expended: false
        };
        this.toggleExpand = this.toggleExpand.bind(this);
    }

    toggleExpand() {
        this.setState({
            expended: !this.state.expended
        })
    }

    render() {
        const version = this.props.version;

        let dldiv = null;
        if (version.serverurl === "") {
            dldiv = (
                <a href={version.url}>
                    <span title={LanguageConfig.getText("ind_mod_client")}><FaFileDownload /></span>
                </a>
            )
        } else if (version.serverurl !== version.url) {
            dldiv = (
                <span>
                    <a href={version.url}>
                        <span title={LanguageConfig.getText("ind_mod_client")}><FaFileDownload /></span></a>
                    <a href={version.serverurl}>
                        <span title={LanguageConfig.getText("ind_mod_server")}><FaFileDownload /></span></a>
                </span>
            )
        } else {
            dldiv = (
                <a href={version.url}>
                    <span title={LanguageConfig.getText("ind_mod_client_server")}><FaFileDownload /></span></a>
            )
        }

        return (
            <tr>
                <td>
                    {dldiv}
                    {version.version}
                    {version.devversion === "1" &&
                        <b>(dev)</b>
                    }
                    <br />
                    <em>{version.date}</em>
                    <br />

                </td>
                <td>
                    {version.title}
                    <button type="button" className="btn btn-primary" onClick={this.toggleExpand} style={{ float: "right", marginRight: "10px" }}>
                        <Translate text={this.state.expended ? "shrink" : "expand"} />
                    </button>

                    <div id="version'.$version['version'].'" className={this.state.expended ? "collapse show" : "collapse"}>
                        {renderHTML(version.description)}
                    </div>
                </td>
            </tr>
        )
    }
}