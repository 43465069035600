import React from 'react';

import TopHeader from './TopHeader'
import imgencyclopedia from './img/header_encyclopedia.png';
import imghomepage from './img/header_homepage.png';
import imglibrary from './img/header_library.png';
import LanguageConfig from '../Common/LanguageConfig'
import MiddleHeader from './MiddleHeader';
import HomepageSubMenu from './HomepageSubMenu';
import LibrarySubMenu from './LibrarySubMenu';
import AuthService from '../Users/AuthService';

class EncyclopediaHeader extends React.Component {
    render() {
        return (
            <TopHeader logo={imgencyclopedia} location={this.props.location} />
        )
    }
}

class LibraryHeader extends React.Component {
    render() {
        return (
            <div>
                <TopHeader logo={imglibrary} location={this.props.location} />
                {AuthService.loggedIn() &&
                    <LibrarySubMenu />
                }
            </div>
        )
    }
}

class HomepageHeader extends React.Component {
    render() {
        return (
            <div>
                <TopHeader logo={imghomepage} subheader={LanguageConfig.getText("ind_subtitle")} location={this.props.location} />
                <MiddleHeader location={this.props.location} />
                <HomepageSubMenu location={this.props.location} />
            </div>
        )
    }
}

class SubHomepageHeader extends React.Component {
    render() {
        return (
            <div>
                <TopHeader logo={imghomepage} subheader={LanguageConfig.getText("ind_subtitle")} location={this.props.location} />
                <HomepageSubMenu location={this.props.location} />
            </div>
        )
    }
}

class TechnicalPageHeader extends React.Component {
    render() {
        return (
            <div>
                <TopHeader logo={imghomepage} subheader={LanguageConfig.getText("ind_subtitle")} location={this.props.location} />
            </div>
        )
    }
}

class TranslationHeader extends React.Component {
    render() {
        return (
            <div>
                <TopHeader logo={imghomepage} subheader="Translation Centre" location={this.props.location} />
            </div>
        )
    }
}

export { EncyclopediaHeader, HomepageHeader, LibraryHeader, SubHomepageHeader, TechnicalPageHeader, TranslationHeader }