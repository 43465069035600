import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import LanguageConfig from '../Common/LanguageConfig';

import slideshow1 from './img/slideshow/slideshow_1.png';
import slideshow2 from './img/slideshow/slideshow_2.png';
import slideshow3 from './img/slideshow/slideshow_3.png';
import slideshow4 from './img/slideshow/slideshow_4.png';
import slideshow5 from './img/slideshow/slideshow_5.png';
import slideshow6 from './img/slideshow/slideshow_6.png';
import slideshow7 from './img/slideshow/slideshow_7.png';
import slideshow8 from './img/slideshow/slideshow_8.png';
import slideshow9 from './img/slideshow/slideshow_9.png';
import slideshow10 from './img/slideshow/slideshow_10.png';
import slideshow11 from './img/slideshow/slideshow_11.png';
import slideshow12 from './img/slideshow/slideshow_12.png';
import slideshow13 from './img/slideshow/slideshow_13.png';
import slideshow14 from './img/slideshow/slideshow_14.png';

import slideshow1_thumb from './img/slideshow100/slideshow_1.png';
import slideshow2_thumb from './img/slideshow100/slideshow_2.png';
import slideshow3_thumb from './img/slideshow100/slideshow_3.png';
import slideshow4_thumb from './img/slideshow100/slideshow_4.png';
import slideshow5_thumb from './img/slideshow100/slideshow_5.png';
import slideshow6_thumb from './img/slideshow100/slideshow_6.png';
import slideshow7_thumb from './img/slideshow100/slideshow_7.png';
import slideshow8_thumb from './img/slideshow100/slideshow_8.png';
import slideshow9_thumb from './img/slideshow100/slideshow_9.png';
import slideshow10_thumb from './img/slideshow100/slideshow_10.png';
import slideshow11_thumb from './img/slideshow100/slideshow_11.png';
import slideshow12_thumb from './img/slideshow100/slideshow_12.png';
import slideshow13_thumb from './img/slideshow100/slideshow_13.png';
import slideshow14_thumb from './img/slideshow100/slideshow_14.png';

const slideshow = [slideshow1, slideshow2, slideshow3, slideshow4, slideshow5, slideshow6, slideshow7, slideshow8, slideshow9
    , slideshow10, slideshow11, slideshow12, slideshow13, slideshow14]
const slideshow_thumbnail = [slideshow1_thumb, slideshow2_thumb, slideshow3_thumb, slideshow4_thumb, slideshow5_thumb, slideshow6_thumb,
    slideshow7_thumb, slideshow8_thumb, slideshow9_thumb, slideshow10_thumb, slideshow11_thumb, slideshow12_thumb,
    slideshow13_thumb, slideshow14_thumb]

export default class HomepageSlideshow extends React.Component {
    render() {

        const images = [];

        for (let i = 0; i < slideshow.length; i++) {
            images[i] = {
                original: slideshow[i],
                thumbnail: slideshow_thumbnail[i],
                description: LanguageConfig.getText("slideshow_" + (i + 1)),
            }
        }

        return (
            <section className="page-section clearfix">
                <div className="container">
                    <div className="slideshow">
                        <div className="slideshow-text left-0 bg-faded p-5 rounded">
                            <ImageGallery items={images} />
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}