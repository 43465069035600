import React from 'react';
import { Translate } from 'react-translated';
import { Link } from 'react-router-dom';
import * as URLs from '../URLS'

export default class HomepageSubMenu extends React.Component {
    render() {
        return (
            <div className="lightbeigebg py-0 py-lg-2" style={{ width: "100%", display: "flex", justifyContent: "center", flexWrap:"wrap" }}>
                <div className="pagenavitem px-lg-4 py-2">
                    <Link className="text-uppercase" to={URLs.home}>
                        <Translate text="menu_home" /></Link>
                </div>
                <div className="pagenavitem px-lg-4 py-2">
                    <Link className="text-uppercase" to={URLs.home_history}>
                        <Translate text="menu_versionhistory" /></Link>
                </div>
                <div className="pagenavitem px-lg-4 py-2">
                    <Link className="text-uppercase" to={URLs.home_translations}>
                        <Translate text="menu_translations" /></Link>
                </div>
                <div className="pagenavitem px-lg-4 py-2">
                    <Link className="text-uppercase" to={URLs.home_contributing}>
                        <Translate text="menu_resources" /></Link>
                </div>
                <div className="pagenavitem px-lg-4 py-2">
                    <Link className="text-uppercase" to={URLs.home_advancements}>
                        <Translate text="menu_advancements" /></Link>
                </div>
                <div className="pagenavitem px-lg-4 py-2">
                    <Link className="text-uppercase" to={URLs.home_hof}>
                        <Translate text="menu_hof" /></Link>
                </div>
            </div>
        )
    }
}