import React from 'react';

import './header.css';

import * as URLs from '../URLS';

import UserMenu from '../Users/UserMenu';
import EncyclopediaAdminMenu from './EncyclopediaAdminMenu';
import MillenaireMenu from './MillenaireMenu';

import flag_france from './img/flag_france.png';
import flag_uk from './img/flag_uk.png';
import { LanguageContext } from '../Common/LanguageContext';
import AuthService from '../Users/AuthService';

export default class TopHeader extends React.Component {

  static contextType = LanguageContext;

  render() {

    const current_url = this.props.location.pathname;

    return (
      <div className="text-center" style={{ marginTop: "1em", marginBottom: "2em" }}>
        <div className="container">
          <div style={{ float: 'right', right: 0, top: 0, display: "block" }}>

            <div style={{ float: 'right' }}>
              <UserMenu />
            </div>
            {AuthService.isUserAdmin() && current_url.startsWith(URLs.encyclopedia_home) &&
              <div style={{ float: 'right' }}>
                <EncyclopediaAdminMenu />
              </div>
            }
            <div style={{ float: 'right' }}>
              {this.context.language !== "fr" &&
                <img className="topheaderbutton" alt="fr" src={flag_france} onClick={() => { this.context.handleLanguageChange('fr') }} />
              }
              {this.context.language !== "en" &&
                <img className="topheaderbutton" alt="en" src={flag_uk} onClick={() => { this.context.handleLanguageChange('en') }} />
              }
            </div>
          </div>
          <div style={{ float: 'left', left: 0, top: 0 }}>
            <MillenaireMenu />
          </div>
          <div className="rounded lightbeigebg mainlogo site-heading" style={{ margin: "auto" }}>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyItems: "center" }}>
              <img className="img-fluid" src={this.props.logo} style={{ margin: "auto" }} alt="" />
            </div>
            {this.props.subheader !== undefined &&
              <div className='site-heading-lower' style={{ width: "100%", textAlign: "center" }}>
                {this.props.subheader}
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
