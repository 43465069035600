import AuthService from "../Users/AuthService";

class HomepageService {

    constructor() {
        this.cache = {}
    }

    invalidateCache(urlStartsWith) {
        const self = this;
        Object.keys(this.cache).forEach(function (key) {
            if (key.startsWith(urlStartsWith)) {
                delete self.cache[key];
            }
        });
    }

    getCurrentVersion(language) {
        const url = process.env.REACT_APP_API_ROOT + "versions/current?language=" + language;

        if (this.cache[url])
            return Promise.resolve(this.cache[url]);

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getVersionList(language) {
        const url = process.env.REACT_APP_API_ROOT + "versions/?language=" + language;

        if (this.cache[url])
            return Promise.resolve(this.cache[url]);

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getTranslationList(language) {
        const url = process.env.REACT_APP_API_ROOT + "translations/?language=" + language;

        if (this.cache[url])
            return Promise.resolve(this.cache[url]);

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getAdvancementsList(language) {
        const url = process.env.REACT_APP_API_ROOT + "advancements/?language=" + language;

        if (this.cache[url])
            return Promise.resolve(this.cache[url]);

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getPlayerAdvancementsStat(playerlogin) {
        const url = process.env.REACT_APP_API_ROOT + "advancements/players/" + playerlogin;

        if (this.cache[url])
            return Promise.resolve(this.cache[url]);

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }

    getHofData(language) {
        const url = process.env.REACT_APP_API_ROOT + "hof/?language=" + language;

        if (this.cache[url])
            return Promise.resolve(this.cache[url]);

        return AuthService.fetch(url).then(res => {
            this.cache[url] = res;
            return res
        });
    }
}

const instance = new HomepageService();
Object.freeze(instance);

export default instance;