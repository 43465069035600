import React from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { Translate } from 'react-translated'
import ImageGallery from 'react-image-gallery';
import { Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';

import { LanguageContext } from '../../Common/LanguageContext';
import TextLine from "./TextLine";
import ExtraDataDisplay from "./ExtraDataDisplay";
import AuthService from '../../Users/AuthService';

import * as URLs from '../../URLS';

export default class BookDisplay extends React.Component {

    static contextType = LanguageContext;

    render() {

        const { travelbookdata, extradata, images } = this.props;

        const rows = [];

        let lineCount = 0;

        travelbookdata.pages.forEach((page) => {
            page.lines.forEach((line) => {

                if (line.specialTag === "MAIN_DESC") {
                    rows.push(
                        <TravelBookQuote text={line.text} key={lineCount} />
                    )
                    if (this.props.extradata !== null && this.props.extradata.desc !== "") {
                        rows.push(
                            <ExtraDataDisplay extradata={extradata} key={lineCount} />
                        )
                    } else {
                        if (AuthService.loggedIn()) {
                            rows.push(
                                <div key="addcontentdesc">
                                    <br />
                                    <p><em><Translate text="encyclopedia.addcontentdesc" /></em></p>
                                </div>
                            )
                        } else {
                            rows.push(
                                <div key="createaccount">
                                    <br />
                                    <div style={{ float: "left", marginRight: "1em" }}>
                                        <Link to={URLs.user_createaccount} >
                                            <button className="btn btn-primary mb-2 mr-2">
                                                <FaUser className="buttonicon" />
                                                <Translate text="button.createaccount" />
                                            </button>
                                        </Link>
                                    </div>
                                    <p><em><Translate text="encyclopedia.createaccountdesc" /></em></p>
                                    <br />
                                </div>
                            )
                        }
                    }
                    if (images.records !== undefined && images.records.length > 0) {
                        rows.push(
                            <ContentSlideshow images={images.records} key={lineCount + "images"} />
                        )
                    }
                } else {
                    rows.push(
                        <TextLine line={line} key={lineCount} />
                    )
                }

                lineCount++;
            });
        });

        return (
            <div>{rows}</div>
        )
    }
}

class TravelBookQuote extends React.Component {
    render() {
        const text = this.props.text;

        return (
            <div style={{ justifyItems: "center", width: "100%" }}>
                <div style={{ maxWidth: "400px", margin: "auto" }} className="lightbeigebg rounded p-2">
                    <FaQuoteLeft className="activeicon" style={{ paddingRight: 5 }} />
                    {text}
                    <FaQuoteRight className="activeicon" style={{ paddingLeft: 5 }} />
                    <br />
                    <div style={{ textAlign: "right", width: "100%" }}>
                        <em>
                            <Translate text="travelbook.quote" />
                        </em>
                    </div>
                </div>
            </div>
        )
    }
}

class ContentSlideshow extends React.Component {
    static contextType = LanguageContext;

    render() {

        const { images } = this.props;

        const slideshowImages = [];

        if (images !== undefined && images !== null) {
            images.forEach((image) => {

                const { culture, type, filename, previewname, legend_en, legend_fr } = image;

                let legend = legend_en;

                if (this.context.language === "fr")
                    legend = legend_fr;

                slideshowImages.push(
                    {
                        original: process.env.REACT_APP_ENCYLOPEDIA_IMG_ROOT + culture + "/" + type + "/" + filename,
                        thumbnail: process.env.REACT_APP_ENCYLOPEDIA_IMG_ROOT + culture + "/" + type + "/" + previewname,
                        description: legend,
                    }
                )
            })
        }

        return (
            <div className="container" style={{ marginTop: "2em" }}>
                <div className="slideshow" style={{ maxWidth: "600px", margin: "auto" }}>
                    <div className="slideshow-text left-0 p-5 rounded">
                        <ImageGallery items={slideshowImages} />
                    </div>
                </div>
            </div>
        )
    }
}
